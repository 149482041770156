import { Box, Divider } from "@mui/material";
import copyToClipboard from "copy-to-clipboard";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { RootState } from "../../../store";
import { generateUniqueBookingLink } from "../../../store/actions/booking";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import { host } from "../../../store/utils/utils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { ButtonVariant } from "../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../core-ui/components/Button/FixedMinWidthButton";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { PriceField } from "../../elements/PriceField/PriceField";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import {
  ProfileScreenTabs,
  profileTabsMap,
} from "../../screens/ProfileScreen/utils";
import { HighlightedTextContainer } from "../AddRecordingService/AddRecordingService.styles";
import { ServiceFormInputContainer } from "../ManageEngineeringService/ManageEngineeringService.styles";
import "./GenerateBookingLinkModal.css";

// eslint-disable-next-line import/no-unused-modules
export interface GenerateBookingLinkModalProps {
  showModal?: boolean;
  serviceType: ProjectType;
  maxServicePrice: number;
  closeModal: () => void;
}

const MIN_DISCOUNT_PRICE = 50;
const MIN_DISCOUNT_PRICE_GOLD_PLATINUM = 10;

export const GenerateBookingLinkModal = ({
  showModal = false,
  serviceType,
  maxServicePrice,
  closeModal,
}: GenerateBookingLinkModalProps) => {
  const { isMobile } = useMediaQueryBreakpoint();
  const [discount, setDiscount] = useState<number>(maxServicePrice);
  const [loading, setLoading] = useState(false);
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const user = useAppSelector((state) => state.accountInfo.user);
  const [minPrice, setMinPrice] = useState<number>(MIN_DISCOUNT_PRICE);

  const currentUser = useAppSelector(
    (state: RootState) => state.accountInfo.user,
  );
  const engUniqueBookingLink = useAppSelector(
    (state: RootState) => state.bookingStore.engUniqueBookingLink,
  );
  const subscription = useAppSelector((state) => state.subscriptionStore);

  useEffect(() => {
    if (
      subscription.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.LEGACY_GOLD_PLAN ||
      subscription.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.LEGACY_GOLD_QUARTERLY_PLAN ||
      subscription.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.LEGACY_GOLD_YEARLY_PLAN ||
      subscription.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.LEGACY_PLATINUM_PLAN ||
      subscription.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.LEGACY_PLATINUM_YEARLY_PLAN
    ) {
      setMinPrice(MIN_DISCOUNT_PRICE_GOLD_PLATINUM);
    }
  }, [subscription, subscription.subscription_plan_choice]);

  const dispatch = useAppDispatch();

  const getBookingLink = useCallback(() => {
    if (loading) return;
    setLoading(true);

    emitAnalyticsTrackingEvent(
      "generate_unique_booking_link",
      { service_type: `${serviceType}` },
      user?.id,
    );
    dispatch(
      generateUniqueBookingLink({
        service_type: serviceType,
        discounted_price: discount,
        bulk_discounts_disabled: toggleSwitch,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res !== undefined) {
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  }, [discount, dispatch, loading, serviceType, toggleSwitch, user?.id]);

  const bookingLink = useMemo(() => {
    if (engUniqueBookingLink?.service_type === serviceType) {
      const queryStr = queryString.stringify({
        selectedTab:
          profileTabsMap[ProfileScreenTabs.Services].queryParamsValue,
        code: engUniqueBookingLink.code,
      });
      return `${host}/${currentUser?.username}?${queryStr}`;
    }
  }, [currentUser?.username, engUniqueBookingLink, serviceType]);

  const handleCopyToClipboard = useCallback(async () => {
    emitAnalyticsTrackingEvent(
      "clicked_copy_booking_link_to_clipboard",
      {},
      user?.id,
    );

    let success = false;

    if (bookingLink) {
      if (navigator.share && isMobile) {
        try {
          await navigator.share({ url: bookingLink });
          success = true;
        } catch (error) {
          // There is nothing that we want to do here
        }
      } else {
        success = copyToClipboard(bookingLink);
      }

      if (success) {
        toast.info("Link has been copied to clipboard");
        closeModal();
      }
    }
  }, [bookingLink, closeModal, isMobile, user?.id]);

  const renderActionButtons = () => {
    if (!bookingLink) {
      return (
        <>
          <FixedMinWidthButton
            variant={ButtonVariant.OUTLINED}
            disabled={loading}
            onClick={closeModal}
          >
            Cancel
          </FixedMinWidthButton>
          <FixedMinWidthButton
            onClick={getBookingLink}
            loading={loading}
            disabled={loading}
          >
            Generate booking link
          </FixedMinWidthButton>
        </>
      );
    }

    return (
      <FixedMinWidthButton onClick={handleCopyToClipboard}>
        Copy link and close
      </FixedMinWidthButton>
    );
  };

  return (
    <BaseModal
      open={showModal}
      setOpen={closeModal}
      header="Generate Booking Link"
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          rowGap: "24px",
          textAlign: "center",
          width: "100%",

          ".textfield": {
            background: "transparent",
            color: theme.palette.text.primary,
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: "32px",
          }}
        >
          <Text variant={TextStyleVariant.P1}>
            {bookingLink
              ? "Copy the unique URL or click the button below to copy the link and close this window."
              : "Generate a unique booking link with a discounted price for an artist you would like to work with using the form below."}
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "16px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                component="label"
                htmlFor="discounted-price"
                sx={(theme) => ({
                  fontSize: "16px",
                  fontWeight: theme.typography.fontWeightBold,
                  marginBottom: "16px",
                  display: "block",
                })}
              >
                {bookingLink ? "Generated booking link:" : "Discounted price"}
              </Box>
              {bookingLink ? (
                <input
                  className="textfield"
                  defaultValue={bookingLink}
                  readOnly
                />
              ) : (
                <PriceField
                  value={discount}
                  min={minPrice}
                  max={maxServicePrice}
                  placeholder="Enter discounted rate"
                  onChange={(value) => {
                    setDiscount(value);
                  }}
                  id="discounted-price"
                  style={{ width: "200px" }}
                />
              )}
            </Box>

            {!bookingLink && (
              <Box
                sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}
              >
                <ServiceFormInputContainer sx={{ width: "unset !important" }}>
                  <ToggleSwitch
                    id="disable-bulk-discounts-toggle"
                    checked={toggleSwitch}
                    onChange={() => setToggleSwitch(!toggleSwitch)}
                  />
                </ServiceFormInputContainer>
                <Box
                  component="label"
                  sx={{ fontSize: "14px" }}
                  htmlFor="disable-bulk-discounts-toggle"
                >
                  Disable bulk discounts
                </Box>
              </Box>
            )}
          </Box>
          <HighlightedTextContainer sx={{ width: "100%" }}>
            <Text
              variant={TextStyleVariant.P1}
              bold
              color={TextColor.PRIMARY_NO_DARK_THEME}
            >
              *Note: this link can only be used once.
            </Text>
          </HighlightedTextContainer>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            columnGap: "16px",
          }}
        >
          {renderActionButtons()}
        </Box>
      </Box>
    </BaseModal>
  );
};
