import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, styled } from "@mui/material";
import { Button } from "../../../core-ui/components/Button/Button";

const SERVICE_CARDS_1_COLUMN_BREAKPOINTS = 631;

export const ServiceCardV2Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isEmpty",
})<{ $isEmpty?: boolean }>(({ theme, $isEmpty = false }) => ({
  padding: "16px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  borderRadius: theme.border.radius.md,
  background: theme.palette.background.paper,
  boxShadow: $isEmpty ? undefined : "0px 2px 30px 0px rgba(0, 0, 0, 0.15)",
  rowGap: "12px",
  textAlign: "center",
}));

export const AddServiceCardAddButton = styled(Button)(({ theme }) => ({
  width: "140px",

  [theme.breakpoints.down(SERVICE_CARDS_1_COLUMN_BREAKPOINTS)]: {
    width: "100%",
  },
}));

export const ServiceCardImg = styled("img")(({ theme }) => ({
  borderRadius: theme.border.radius.md,
  width: "100%",
  aspectRatio: "16/9",
  alignSelf: "stretch",
  objectFit: "cover",
}));

export const ServiceCardColumnContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "12px",

  // We want the CTA button to be full width when the cards are displayed as 1 column, which is at this exactly screen size
  [theme.breakpoints.down(SERVICE_CARDS_1_COLUMN_BREAKPOINTS)]: {
    ".button-black": {
      width: "100%",
    },
  },
}));

export const ServiceCardRowContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: "auto",
});

export const ServiceCardQuestionMarkIcon = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    color: theme.palette.grey[200],
  }),
);

export const ServiceCardInfoContainer = styled(ServiceCardColumnContainer)({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  alignItems: "flex-start",
});

export const ServiceCardBenefitsContainer = styled(Box)(({ theme }) => ({
  gap: "8px",
  display: "flex",
  flexDirection: "column",
  alignSelf: "stretch",
  [theme.breakpoints.down("sm")]: {
    gap: "20px",
  },
}));

export const ServiceCardBaseModalContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
});

export const DefaultServicePhotoContainer = styled(Box)(({ theme }) => ({
  aspectRatio: "16/9",
  width: "100%",
  backgroundColor: theme.palette.standardColor.Gray[50],
  borderRadius: theme.border.radius.md,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
}));

export const FeatureVideoBlankContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  aspectRatio: "16/9",
  backgroundColor: theme.palette.customColor.midgroundColor,
  border: theme.border.radius.md,
  flexDirection: "column",
  rowGap: "8px",
  padding: "16px",
  textAlign: "center",
  boxSizing: "border-box",
}));
