import { Box } from "@mui/material";
import { InputHTMLAttributes, useEffect } from "react";
import { USDollarNarrowFormatter } from "../../../store/utils/formatUtils";

interface PriceFIeldProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "min" | "max" | "value"
  > {
  value?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
}

export const PriceField = ({
  value,
  min = 0,
  max = 100,
  onChange,
  style = { width: 80 },
  disabled,
  className,
  placeholder,
  ...props
}: PriceFIeldProps) => {
  // Whenever the value is changed to be > max, set as max
  useEffect(() => {
    if (value && value > max) {
      onChange?.(max);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max, value]);

  // On first mount, if the value is < min, we set it the min
  useEffect(() => {
    if (!value || Number.isNaN(value) || value < min) {
      onChange?.(min);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValue = () => {
    if (!value) {
      return "$";
    }

    return USDollarNarrowFormatter().format(Math.round(value));
  };

  return (
    <Box>
      <input
        style={style}
        disabled={disabled}
        className={className ?? "textfield"}
        name="text"
        type="text"
        value={getValue()}
        {...props}
        placeholder={placeholder}
        onBlur={() => {
          if (!value || Number.isNaN(value) || value < min) {
            onChange?.(min);
          }
        }}
        onChange={(e) => {
          const newValue = e.target.value;

          if (!newValue || newValue === "$") {
            onChange?.(0);
            return;
          }

          const cleanedString = newValue.replace(/[$,]/g, "");
          const numberValue = parseInt(cleanedString);
          if (!Number.isNaN(numberValue)) {
            onChange?.(numberValue);
          }
        }}
      />
    </Box>
  );
};
