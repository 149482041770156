import { faFileEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtomValue } from "jotai";
import { HTMLAttributes, useState } from "react";
import { useFetchReleaseQuery } from "../../../api/releases/hooks/useFetchReleaseQuery";
import { editMode } from "../../../atoms/profileScreenEdit";
import { activeUserAtom } from "../../../atoms/user/activeUserAtom";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { EditReleaseModal } from "../ProjectOverviewHeaderReleaseButton/EditReleaseModal";

interface EditReleaseButtonProps extends HTMLAttributes<HTMLDivElement> {
  releaseId?: number;
  overrideStyles?: HTMLAttributes<HTMLDivElement>["style"];
}

export const EditReleaseButton = ({
  releaseId,
  children,
  overrideStyles = {},
}: EditReleaseButtonProps) => {
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const slimUser = useAtomValue(activeUserAtom);
  const { data: release } = useFetchReleaseQuery(releaseId);
  const userIsArtist = release && release.artist_user_id === slimUser?.id;
  const isEditMode = useAtomValue(editMode);

  return (
    <div
      onClick={(e) => {
        if (userIsArtist && isEditMode) {
          // prevents navigating to release detail page on user profile
          e.stopPropagation();
        }
      }}
    >
      <Button
        onClick={() => {
          if (userIsArtist && isEditMode) setShowReleaseModal(true);
        }}
        variant={
          userIsArtist && isEditMode
            ? ButtonVariant.GHOST
            : ButtonVariant.UNSTYLED
        }
        reverseIconOrder
        labelIcon={
          userIsArtist && isEditMode ? (
            <FontAwesomeIcon icon={faFileEdit} />
          ) : null
        }
        style={{
          padding: 0,
          minHeight: "unset",
          ...overrideStyles,
        }}
      >
        {children}
      </Button>
      {release && showReleaseModal && userIsArtist && isEditMode && (
        <EditReleaseModal
          release={release}
          open={showReleaseModal}
          setOpen={setShowReleaseModal}
        />
      )}
    </div>
  );
};
