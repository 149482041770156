import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import { assertEngineEarsErrorType, displayEngineEarsError } from "../helpers";
import {
  ProductTransactionCreateArgs,
  ProductTransactionResponse,
} from "./models";
import { createProductTransaction } from "./productTransactionsAPI";

export const useTransactionForProductMutation = () => {
  return useMutation({
    mutationFn: async (args: ProductTransactionCreateArgs) => {
      const response = await createProductTransaction(args);
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as ProductTransactionResponse;
    },
    mutationKey: [QUERY_KEYS.CREATE_TRANSACTION_FOR_PRODUCTS],
    onError: (error: EngineEarsError) => {
      if (assertEngineEarsErrorType(error)) {
        displayEngineEarsError(error);
      }
    },
  });
};
