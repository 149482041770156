import { atom, Getter, Setter } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { ShoppingCartItem } from "../../api/productTransactions/models";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";

const _productCartAtom = atomWithStorage<ShoppingCartItem[]>(
  LOCAL_STORAGE_KEYS.PRODUCT_SHOPPING_CART,
  [],
);

const productCartGetter = (get: Getter) => {
  return get(_productCartAtom);
};

const productCartSetter = (
  _: Getter,
  set: Setter,
  selectedCartItem: ShoppingCartItem[],
) => {
  set(_productCartAtom, selectedCartItem);
};

export const productCartAtom = atom(productCartGetter, productCartSetter);

productCartAtom.debugLabel = "Product Shopping Cart";
