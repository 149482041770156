import { Box, styled } from "@mui/material";

export const ArtistReleaseTableWrapper = styled("table")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const ArtistReleaseTableHeader = styled("th")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const ReleaseTableHeaderView = styled(Box)(() => ({
  minWidth: "16px",
  display: "flex",
  width: "100%",
}));
