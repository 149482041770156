import { GUEST_LOGIN } from "../../store/utils/routes";
import { AuthResponse } from "../../store/actions/accountInfo";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { Error as EngineEarsError } from "../../store/actions/errorStore";

export interface GuestLoginParams {
  email?: string;
  phone_number?: string;
  verification_code: string;
}

export const postGuestLogin = async (
  params: GuestLoginParams,
): Promise<AuthResponse | EngineEarsError> => {
  return makeBackendPostCallWithJsonResponse<AuthResponse>(
    GUEST_LOGIN,
    params,
  ).then((res) => {
    if (res.success) {
      return res.resultJson;
    }
    return res.resultJson;
  });
};
