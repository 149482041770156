import { Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { ReleaseType, ReleaseTypeOptions } from "../../../store/models/release";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { ExpandableTextArea } from "../../elements/ExpandableTextArea/ExpandableTextArea";
import { ReleaseTypeLabel } from "../ArtistReleases/ArtistReleases.styles";
import { ExclusiveIconContainer } from "../ProductCardsList/ProductCardsList.styles";
import { EditReleaseButton } from "./EditReleaseButton";
import { FeaturedReleaseCollaborators } from "./FeaturedReleaseCollaborators";
import { FeatureReleasePill } from "./FeaturedReleasePill";
import { MadeOnEngineers } from "./MadeOnEngineers";

interface FeaturedAristReleaseHeaderProps {
  title: string;
  artistUsername: string;
  releaseType: ReleaseType;
  createdAt: string;
  featured: boolean;
  releaseId?: number;
  description?: string;
  isOnReleaseDetailsView?: boolean;
}

const textStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "8px",
  minWidth: "130px",
};

export const FeaturedAristReleaseHeaderDetails = ({
  title,
  artistUsername,
  releaseType,
  createdAt,
  featured,
  releaseId,
  description,
  isOnReleaseDetailsView = false,
}: FeaturedAristReleaseHeaderProps) => {
  const year = format(new Date(createdAt), "yyyy");
  const releaseOption = ReleaseTypeOptions.find(
    (option) => option.value === releaseType,
  );
  const { isMobile } = useMediaQueryBreakpoint();
  const releaseText = releaseOption?.label || "";
  const featuredReleaseTitle = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    // We need setTimeout so that we can wait for all the synchronous state update to be completed
    // It helps us scroll to the expected and consistent location
    setTimeout(() => {
      if (isOnReleaseDetailsView && featuredReleaseTitle.current && isMobile) {
        featuredReleaseTitle.current.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
        // This serves as a fallback for Android dervices. Smooth behavior is not supported
        featuredReleaseTitle.current.scrollIntoView({
          block: "center",
        });
      }
    }, 0);
  }, [isMobile, isOnReleaseDetailsView]);

  return (
    <Grid direction={"column"} container gap={1}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "col-reverse" : "space-between",
          gap: 1,
          flexWrap: "wrap",
          alignItems: isMobile ? "center" : "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: isMobile ? "center" : "flex-start",
          }}
        >
          <EditReleaseButton
            releaseId={releaseId}
            overrideStyles={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile ? "center" : "flex-start",
              gap: "8px",
            }}
          >
            <Typography
              textAlign={"left"}
              lineHeight={"20px"}
              fontStyle={"normal"}
              fontSize={"18px"}
              fontWeight={"600"}
              flexWrap={"wrap"}
              ref={featuredReleaseTitle}
            >
              {title}
            </Typography>
            {/* <ExclusiveIconContainer>E</ExclusiveIconContainer> */}
          </EditReleaseButton>
          <Box
            sx={{
              ...textStyle,
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            <Text variant={TextStyleVariant.P1} bold>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
                to={getProfileScreenRoute(artistUsername)}
              >{`@${artistUsername}`}</Link>
            </Text>
          </Box>
          <Box
            sx={{
              ...textStyle,
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            <ReleaseTypeLabel>
              <Text color={TextColor.SECONDARY} variant={TextStyleVariant.P2}>
                {/*TODO: Clean this up after the smino livestream.*/}
                {artistUsername === "smino" ? "Event" : releaseText}
              </Text>
            </ReleaseTypeLabel>
            <Text variant={TextStyleVariant.P1}>{year}</Text>
            {/* TODO: Clean this up by pulling genres from database. */}
            {artistUsername === "noahguy" && <Text>Soul/R&B/Pop</Text>}
            {artistUsername === "officialthomasaustin" && (
              <Text>Hip Hop/R&B</Text>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          {featured && <FeatureReleasePill />}
        </Box>
      </Box>
      {(!isMobile || isOnReleaseDetailsView) && (
        <>
          <ExpandableTextArea
            isGreyVariant
            minHeight={10}
            body={description ?? ""}
          />

          <Box sx={{ alignSelf: "flex-start" }}>
            <MadeOnEngineers />
          </Box>
          <FeaturedReleaseCollaborators releaseId={releaseId} />
        </>
      )}
    </Grid>
  );
};
