import { atom } from "jotai";

export enum ReleasesTabView {
  overview,
  detail,
}

export const artistReleaseTabViewAtom = atom(ReleasesTabView.overview);
export const selectedReleaseIdAtom = atom<number | null>();
export const selectedFeaturedBundleIndexAtom = atom<number | "">("");
