import { useMemo } from "react";
import {
  ATMOS_UPLOAD_STEP,
  AtmosProjectSteps,
  MasteringProjectSteps,
  MixingProjectSteps,
  Project,
  ProjectType,
  UPLOAD_MASTER_STEPS,
  UPLOAD_MIXING_STEPS,
} from "../store/models/project";

const IsUploadStep = (
  step: number,
  projectType: null | ProjectType,
): boolean => {
  if (!projectType) return false;
  switch (projectType) {
    case ProjectType.MASTERING:
      return UPLOAD_MASTER_STEPS.includes(step);
    case ProjectType.ATMOS_MIXING:
      return ATMOS_UPLOAD_STEP.includes(step);
    case ProjectType.TWO_TRACK_MIXING:
    case ProjectType.MIXING:
      return UPLOAD_MIXING_STEPS.includes(step);
    default:
      return false;
  }
};

export const useIsUploadStep = (
  step: number,
  projectType: null | ProjectType,
) => {
  return useMemo(() => {
    return IsUploadStep(step, projectType);
  }, [step, projectType]);
};

export const IsArtistUploadStep = (project?: Project): boolean => {
  if (!project) return false;
  const { service_type, step } = project;
  if (service_type === ProjectType.MASTERING) {
    return (
      step === MasteringProjectSteps.MASTERING_BOOKING_REQUESTED ||
      step === MasteringProjectSteps.MASTERING_FILE_NEEDS_REUPLOAD ||
      step === MasteringProjectSteps.MASTERING_BOOKING_REQUESTED
    );
  }
  return (
    step === MixingProjectSteps.FILES_NEED_REUPLOAD ||
    step === MixingProjectSteps.PROJECT_ACCEPTED ||
    step === MixingProjectSteps.BOOKING_REQUESTED
  );
};

export const IsListenStep = (project?: Project): boolean => {
  if (!project) return false;
  const { service_type, step } = project;
  if (service_type === ProjectType.MASTERING) {
    return step === MasteringProjectSteps.MASTER_REVIEW;
  }
  return (
    step === MixingProjectSteps.MIX_REVIEW ||
    step === AtmosProjectSteps.ATMOS_MIX_REVIEW
  );
};

export const useIsArtistUploadStep = (project?: Project) => {
  return useMemo(() => {
    return IsArtistUploadStep(project);
  }, [project]);
};
