import { matchIsValidTel, MuiTelInputCountry } from "mui-tel-input";
import { useState } from "react";
import { useGeolocationGoogleAppEngine } from "../../../hooks/useGeolocationCountryCode";
import { useAppSelector } from "../../../store/hooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { PhoneInput } from "../../core-ui/components/PhoneNumber/PhoneInput";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { FieldContainer } from "../SignInForm/SignInForm.styles";
import { Link } from "react-router-dom";
import { getDocUrl } from "../../../constants/googleStorage";
import { Box, useTheme } from "@mui/material";

interface AddPhoneInputProps {
  handleAddPhoneNumber: (phoneNumber: string) => void;
  guestAuth: boolean;
  phoneLogin: boolean;
  isLoading?: boolean;
}

export const AddPhoneInput = ({
  guestAuth = false,
  phoneLogin = false,
  isLoading = false,
  handleAddPhoneNumber,
}: AddPhoneInputProps) => {
  const theme = useTheme();
  const { countryCode, loading } = useGeolocationGoogleAppEngine();
  const { isUpdatingProfile } = useAppSelector((state) => state.accountInfo);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  const titleCopy = phoneLogin
    ? `Enter your phone number ${guestAuth ? "to checkout" : ""}`
    : "Add your phone number";
  const guestCheckoutWaiverCopy =
    "We'll text you to confirm your number. Standard message and data rates apply.";

  const guestCheckoutWaiver = (
    <span>
      {guestCheckoutWaiverCopy}{" "}
      <a
        style={{
          color: theme.palette.text.secondary,
          textDecoration: "underline",
        }}
        href={getDocUrl("EngineEarsPrivacyPolicy.pdf")}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
    </span>
  );

  return (
    <div>
      <Text variant={TextStyleVariant.H6}>{titleCopy}</Text>
      {!guestAuth && (
        <>
          <Text variant={TextStyleVariant.P1}>
            Receive real-time notifications through SMS text message.
          </Text>
          <Text variant={TextStyleVariant.P1}>
            *Message and data rates may apply.
          </Text>
        </>
      )}
      <FieldContainer>
        <Text bold>Phone number</Text>
        <PhoneInput
          disabled={isUpdatingProfile || loading}
          isValidPhoneNumber={isValidPhoneNumber}
          defaultCountry={(countryCode as MuiTelInputCountry) || "US"}
          value={phoneNumber}
          onChange={(value: string) => {
            setPhoneNumber(value);
            setIsValidPhoneNumber(matchIsValidTel(value));
          }}
        />
      </FieldContainer>
      {guestAuth && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "8px",
          }}
        >
          <Text variant={TextStyleVariant.P4}>{guestCheckoutWaiver}</Text>
        </Box>
      )}
      <Button
        fullWidth={true}
        loading={isUpdatingProfile || isLoading}
        variant={ButtonVariant.PRIMARY}
        disabled={
          !phoneNumber ||
          !isValidPhoneNumber ||
          isUpdatingProfile ||
          loading ||
          isLoading
        }
        onClick={() => handleAddPhoneNumber(phoneNumber)}
      >
        {phoneLogin ? "Continue" : "Add phone number"}
      </Button>
    </div>
  );
};
