import { createWriteStream } from "streamsaver";

export const downloadFilesFromUrls = (urls: string[]) => {
  let count = 0;
  urls.forEach((url: string) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = url;
    setTimeout(
      function (a) {
        a.click();
      },
      200 + count * 1000,
      a,
    );
    count++;
  });
};

export const downloadImagesFromUrls = async (
  urls: string[],
  filename: string,
) => {
  let count = 0;
  urls.forEach(async (url: string, index: number) => {
    const image = await fetch(url);
    const imageBlob = await image.blob();
    const imageURL = URL.createObjectURL(imageBlob);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = `${filename}-${index + 1}.jpg`;
    document.body.appendChild(link);
    setTimeout(
      function (link) {
        link.click();
        document.body.removeChild(link);
      },
      200 + count * 1000,
      link,
    );
    count++;
  });
};

function extractFilenameFromURL(url: string) {
  // Split the URL by the '/' character
  const parts = url.split("/");
  // Get the last part of the URL, which should be the filename
  const filenameWithQuery = parts[parts.length - 1];
  // Split the filename by '?' to remove any query parameters
  const filename = filenameWithQuery.split("?")[0];
  return decodeURIComponent(filename);
}

export const downloadFileViaStreamSaver = (url: string, fileName: string) => {
  return fetch(url).then((res) => {
    const fileStream = createWriteStream(fileName);
    const writer = fileStream.getWriter();
    if (!res.body) return;
    if (res.body.pipeTo) {
      writer.releaseLock();
      return res.body.pipeTo(fileStream);
    }

    const reader = res.body.getReader();
    const pump: any = () =>
      reader
        .read()
        .then(({ value, done }) =>
          done ? writer.close() : writer.write(value).then(pump),
        );

    return pump();
  });
};

const downloadFileThenTriggerNextDownload = (
  fileUrl: string,
  nextUrls: string[],
) => {
  const request = new XMLHttpRequest();
  request.responseType = "blob";
  request.open("GET", fileUrl, true);
  downloadFileViaStreamSaver(fileUrl, extractFilenameFromURL(fileUrl)).then(
    () => {
      const nextUrl = nextUrls.shift();
      // Recursively download the next file.
      if (nextUrl) {
        downloadFileThenTriggerNextDownload(nextUrl, nextUrls);
      }
    },
  );
};

export const downloadFilesFromUrlsWithDelay = (urls: string[]) => {
  const maxConcurrentDownloads = 5;
  const urlBatches: Array<string[]> = [];
  for (let i = 0; i < maxConcurrentDownloads; i++) {
    urlBatches.push([]);
  }
  urls.forEach((url, index) => {
    urlBatches[index % maxConcurrentDownloads].push(url);
  });
  urlBatches.forEach((urlBatch) => {
    if (urlBatch.length === 0) return;
    downloadFileThenTriggerNextDownload(urlBatch.shift() as string, urlBatch);
  });
};
