import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  AccountDetailsFieldContainer,
  FieldInput,
} from "./AccountSettingsModal.styles";
import { Button } from "../../core-ui/components/Button/Button";
import { usePasswordCheck } from "../../../hooks/userPasswordCheck";
import {
  PasswordChecksContainer,
  PasswordRequirement,
  ShowHideIcon,
} from "../PasswordField/PasswordField.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  resetPassword,
  resetPasswordLoggedIn,
} from "../../../store/actions/accountInfo";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import { Theme, useMediaQuery, useTheme } from "@mui/material";
import useGetResetCodeQuery from "../../../hooks/authHooks/useGetResetCodeQuery";

export const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { user, isLoading } = useAppSelector((state) => state.accountInfo);
  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [newPasswordFieldFocused, setNewPasswordFieldFocused] = useState(false);
  const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
    usePasswordCheck(newPassword, 8);
  const hasPassword = user?.has_password;
  const isSubmitDisabled =
    (hasPassword ? currentPassword.length === 0 : false) ||
    newPassword.length === 0 ||
    confirmNewPassword.length === 0 ||
    !validLength ||
    !hasNumber ||
    !upperCase ||
    !lowerCase ||
    !specialChar ||
    isLoading;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { data: resetCode } = useGetResetCodeQuery(hasPassword);

  const passwordRequirement = (label: string, isPassing: boolean) => (
    <PasswordRequirement>
      {!isMobile && (
        <FontAwesomeIcon
          icon={isPassing ? faCheck : faClose}
          color={
            isPassing
              ? theme.palette.standardColor.Green[600]
              : theme.palette.standardColor.Red[700]
          }
        />
      )}
      <Text
        variant={TextStyleVariant.P3}
        style={{
          color: isPassing
            ? theme.palette.standardColor.Green[600]
            : theme.palette.standardColor.Red[700],
        }}
      >
        {label}
      </Text>
    </PasswordRequirement>
  );

  const passwordChecks = (
    <PasswordChecksContainer>
      {passwordRequirement("Must be 8 characters or more.", validLength)}
      {passwordRequirement(
        "Must include at least one number (0-9).",
        hasNumber,
      )}
      {passwordRequirement(
        "Must include at least one uppercase character (A-Z).",
        upperCase,
      )}
      {passwordRequirement(
        "Must include at least one lowercase character (a-z)",
        lowerCase,
      )}
      {passwordRequirement(
        "Must include at least one special character (!@#$%^&*)",
        specialChar,
      )}
    </PasswordChecksContainer>
  );

  const handleResetPassword = () => {
    if (hasPassword) {
      void dispatch(
        resetPasswordLoggedIn({
          new_password: newPassword,
          current_password: currentPassword,
          confirm_new_password: confirmNewPassword,
        }),
      )
        .unwrap()
        .then(() => {
          toast.success("Successfully changed password!");
        })
        .catch(() => {})
        .finally(() => {
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
        });
    } else if (user && resetCode) {
      void dispatch(
        resetPassword({
          user_id: user.id.toString(),
          reset_code: resetCode["reset_code"],
          new_password: newPassword,
        }),
      );
    }
  };

  return (
    <AccountDetailsFieldContainer sx={{ gap: "16px" }}>
      <Text variant={TextStyleVariant.P1} bold>
        Password
      </Text>
      {hasPassword && (
        <FieldInput
          disabled={isLoading}
          type={showCurrentPassword ? "text" : "password"}
          placeholder={"Enter current password"}
          fullWidth={true}
          size={"small"}
          value={currentPassword}
          onChange={(e) => {
            const value = e.target.value;
            setCurrentPassword(value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (!isSubmitDisabled) {
                handleResetPassword();
              }
            }
          }}
          InputProps={{
            endAdornment: (
              <ShowHideIcon>
                <FontAwesomeIcon
                  icon={showCurrentPassword ? faEye : faEyeSlash}
                  color={theme.palette.text.secondary}
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                />
              </ShowHideIcon>
            ),
          }}
        />
      )}
      <FieldInput
        disabled={isLoading}
        type={showNewPassword ? "text" : "password"}
        placeholder={"Enter new password"}
        fullWidth={true}
        size={"small"}
        value={newPassword}
        onFocus={() => setNewPasswordFieldFocused(true)}
        onBlur={() => setNewPasswordFieldFocused(false)}
        onChange={(e) => {
          const value = e.target.value;
          setNewPassword(value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (!isSubmitDisabled) {
              handleResetPassword();
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <ShowHideIcon>
              <FontAwesomeIcon
                icon={showNewPassword ? faEye : faEyeSlash}
                color={theme.palette.text.secondary}
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            </ShowHideIcon>
          ),
        }}
      />
      {newPassword.length > 0 &&
        newPasswordFieldFocused &&
        (!validLength ||
          !hasNumber ||
          !upperCase ||
          !lowerCase ||
          !specialChar) &&
        passwordChecks}
      <FieldInput
        disabled={isLoading}
        type={showConfirmNewPassword ? "text" : "password"}
        placeholder={"Re-enter new password"}
        fullWidth={true}
        size={"small"}
        value={confirmNewPassword}
        onChange={(e) => {
          const value = e.target.value;
          setConfirmNewPassword(value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (!isSubmitDisabled) {
              handleResetPassword();
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <ShowHideIcon>
              <FontAwesomeIcon
                icon={showConfirmNewPassword ? faEye : faEyeSlash}
                color={theme.palette.text.secondary}
                onClick={() =>
                  setShowConfirmNewPassword(!showConfirmNewPassword)
                }
              />
            </ShowHideIcon>
          ),
        }}
      />
      <Button
        disabled={isSubmitDisabled}
        loading={isLoading}
        onClick={handleResetPassword}
      >
        Reset Password
      </Button>
    </AccountDetailsFieldContainer>
  );
};
