import { useEffect, useState } from "react";
import * as Geocode from "react-geocode";
import { toast } from "react-toastify";
import { RecordingLocation } from "../store/models/recording";

export interface Location {
  lat: number;
  lng: number;
}

interface Geometry {
  location: Location;
}

interface Result {
  geometry: Geometry;
}

interface GeocodeResponse {
  results: Result[];
  address_components: AddressComponent[];
  formatted_address: string;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export const useGetLatLongFromRecordingLocation = (
  recordingLocation?: RecordingLocation | null,
): Location | undefined => {
  const [stateCenter, setStateCenter] = useState<Location>({
    lat: 0,
    lng: 0,
  });
  const convertAddressStringToLatLong = (location: string) => {
    Geocode.fromAddress(location)
      .then((response: GeocodeResponse) => {
        const { lat, lng } = response.results[0].geometry.location;
        setStateCenter({
          lat,
          lng,
        });
      })
      .catch(() => {
        toast.error("Error converting address to lat long");
      });
  };

  useEffect(() => {
    if (!recordingLocation) return;
    if (recordingLocation.latitude && recordingLocation.longitude) {
      setStateCenter({
        lat: recordingLocation.latitude,
        lng: recordingLocation.longitude,
      });
      return;
    }
    const address =
      recordingLocation.formatted ?? recordingLocation.city_location;
    convertAddressStringToLatLong(address);
  }, [recordingLocation]);
  return { ...stateCenter };
};
