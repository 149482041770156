import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button } from "../../core-ui/components/Button/MUIButton";
import { useCallback } from "react";
import { useAtom, useAtomValue } from "jotai";
import { assetViewerModalAssetsAtom, assetViewerModalOpenAtom } from "./atoms";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { GeneratedAssetList } from "./GeneratedAssetList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";

const GeneratedAssetViewerModal = () => {
  const [open, setOpen] = useAtom(assetViewerModalOpenAtom);
  const assets = useAtomValue(assetViewerModalAssetsAtom);
  const handleClose = useCallback(() => {
    emitAnalyticsTrackingEvent(TRACKING_EVENTS_NAME.USER_CLICK_CANCEL_ASSET, {
      assets,
    });
    setOpen(false);
  }, [setOpen]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      maxWidth={"lg"}
      fullWidth={true}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          minHeight: "80vh",
        },
      }}
    >
      <DialogTitle
        sx={{ color: theme.palette.text.primary }}
        id="asset-viewer"
        component={Box}
        display={"flex"}
        alignItems={"center"}
      >
        <Text variant={TextStyleVariant.H4}>Your Assets</Text>
        <Button sx={{ ml: "auto" }} variant={ButtonVariant.GHOST}>
          <FontAwesomeIcon
            size={"xl"}
            icon={faClose}
            color={theme.palette.text.primary}
            onClick={handleClose}
          />
        </Button>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <GeneratedAssetList images={assets} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GeneratedAssetViewerModal;
