import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import {
  FileStatus,
  PopOverIconButton,
  UploadedFileListItemContainer,
} from "../../../components/FileUploader/UploadedFileList/UploadedFileList.styles";
import { ButtonVariant } from "../../../core-ui/components/Button/Button";
import { PopConfirm } from "../../../core-ui/components/PopConfirm/PopConfirm";
import { Text } from "../../../core-ui/components/Text/Text";
import { MediaTypes, UPLOADED_PROGRESS } from "../constants";

interface FileListItemProps {
  progress: number;
  onDelete: () => void;

  mediaType: MediaTypes;
}
export const FileListItem = ({
  progress,
  mediaType,
  onDelete,
}: FileListItemProps) => {
  const uploaded = progress === UPLOADED_PROGRESS;

  const renderName = () => {
    let name = mediaTypeNameMapping[mediaType];
    if (!uploaded) {
      name = `Uploading ${name}`;
    }

    return name;
  };

  return (
    <UploadedFileListItemContainer>
      <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
        <Text>{renderName()}</Text>
        {!uploaded && (
          <PopConfirm
            title={"Canceling the upload"}
            description={"Are you sure you want to cancel this upload?"}
            onConfirm={onDelete}
            okText={"Delete"}
            side={"left"}
          >
            <PopOverIconButton
              variant={ButtonVariant.GHOST}
              style={{ padding: 0 }}
            >
              <FontAwesomeIcon icon={faXmark} width={12} height={12} />
            </PopOverIconButton>
          </PopConfirm>
        )}
      </Box>
      <FileStatus $uploaded={uploaded}>
        {uploaded ? (
          <>
            <FontAwesomeIcon icon={faCheck} />
            uploaded
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faCloudArrowUp} />
            {progress === UPLOADED_PROGRESS
              ? "uploaded"
              : `${progress.toFixed(2)}%`}
          </>
        )}
      </FileStatus>
    </UploadedFileListItemContainer>
  );
};

const mediaTypeNameMapping = {
  [MediaTypes.CoverPhoto]: "Cover Photo",
  [MediaTypes.FeaturedVideo]: "Featured Video",
};
