import { Link as NavLink, LinkProps as NavLinkProps } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import { MouseEventHandler, useCallback } from "react";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

interface LinkProps
  extends Omit<NavLinkProps<Record<string, unknown>>, "ref" | "to"> {
  disabled?: boolean;
  to: string;
  analytics?: {
    label: string;
    data?: Record<string | number | symbol, unknown>;
  };
}

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = (props: LinkProps) => {
  const { children, to, disabled, onClick, analytics, ...other } = props;

  const pathname = window?.location?.pathname || "/";

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with zero or more periods with exactly one slash, and that anything else is external.
  // const internal = /^(\.|\w)*\/|:(?!\/)/.test(to);
  const internal = /^\.*\/(?!\/)/.test(to);

  const handleClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }
      if (analytics) {
        const { label, data = {} } = analytics;
        emitAnalyticsTrackingEvent(label, data);
      }
    },
    [onClick, analytics],
  );

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <MuiLink
        component={NavLink}
        to={disabled ? pathname : to}
        onClick={handleClick}
        {...other}
      >
        {children}
      </MuiLink>
    );
  }
  return (
    <MuiLink
      component={"a"}
      href={disabled ? pathname : to}
      onClick={handleClick}
      {...other}
    >
      {children}
    </MuiLink>
  );
};

export type { LinkProps };
export default Link;
