import { useCallback, useEffect, useState } from "react";
import {
  FooterFileTrackType,
  getPlaylist,
  resetAbPlayStore,
  setIsRehydrating,
  setLocalPlayer,
  setMainPlayer,
  setPlaylist,
  setRefPlayer,
} from "../../store/actions/abPlayerStore";
import {
  downloadGeneratedMP3Track,
  downloadTrack,
  downloadTrackSnippet,
} from "../../store/actions/audioService";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Alt } from "../../store/models/alts";
import { AUDIO_UPLOAD_TYPE, FileVersion } from "../../store/models/fileVersion";
import {
  useLatestNonReferenceAudioFileVersion,
  useLatestReference,
} from "../useFilesFromFileVersionStore";
import { useQueryParam } from "../useQueryParam";
import { convertProjectsToPlayListTracks } from "../../store/models/playListTrack";
import { useQuery } from "@tanstack/react-query";
import type { QueryParamsType } from "../../store";
import queryString from "query-string";
import downloadTrackByPath from "../../api/files/audio";
import { readStream } from "../../store/utils/fetch";
import { Error, receiveErrors } from "../../store/actions/errorStore";
import { QUERY_KEYS } from "../../constants/queryKeys";

export const useFetchFiles = () => {
  const {
    projectId,
    url,
    footerFileTrackType,
    isRehydrating,
    playlistId,
    currentTrackIndex,
  } = useAppSelector((state) => state.abPlayerStore);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);

  const dispatch = useAppDispatch();

  const file = useLatestNonReferenceAudioFileVersion(
    projectId || -1,
    Alt.MAIN,
    AUDIO_UPLOAD_TYPE,
  );

  useEffect(() => {
    if (!isRehydrating) return;
    if (!loggedInUser) {
      dispatch(resetAbPlayStore());
      return;
    }

    const getFilesAfterRehydrate = async () => {
      switch (footerFileTrackType) {
        case FooterFileTrackType.SCHEDULED_PROJECT:
          if (!playlistId) break;
          const playlist = await dispatch(
            getPlaylist({
              scheduled_project_id: playlistId,
            }),
          ).unwrap();
          dispatch(
            setPlaylist({
              tracks: convertProjectsToPlayListTracks(playlist),
              playlistId: playlistId,
              index: currentTrackIndex,
              footerFileTrackType: FooterFileTrackType.SCHEDULED_PROJECT,
            }),
          );
          break;
        default:
          dispatch(resetAbPlayStore());
          break;
      }
    };

    void getFilesAfterRehydrate();
  }, [
    dispatch,
    file,
    footerFileTrackType,
    isRehydrating,
    loggedInUser,
    projectId,
    url,
  ]);
};

export const useDownloadTrack = () => {
  const {
    projectId,
    url,
    footerFileTrackType,
    mainUrl,
    refUrl,
    trackedPlayerId,
    mainPlayerId,
    refPlayerId,
    isRehydrating,
  } = useAppSelector((state) => state.abPlayerStore);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const file = useLatestNonReferenceAudioFileVersion(projectId || -1);
  const referenceFileVersion = useLatestReference(projectId || -1);
  const mixOrMasterFileVersion = useLatestNonReferenceAudioFileVersion(
    projectId || -1,
  );
  const code = useQueryParam("code").get();

  const downloadCompletedTrack = useCallback(async () => {
    if (!file) return;
    setIsLoading(true);
    const downloadedTrack = await dispatch(
      downloadTrack({
        fileVersionId: file.id,
        code: code || undefined,
      }),
    ).unwrap();
    setIsLoading(false);
    dispatch(setLocalPlayer({ url: downloadedTrack, keepPosition: true }));
  }, [dispatch, file, projectId, code]);

  const downloadABTrackMP3 = useCallback(
    async (isRef: boolean, id?: number) => {
      if (!id) return;
      void dispatch(
        downloadGeneratedMP3Track({
          fileVersionId: id,
          code: code || undefined,
        }),
      )
        .unwrap()
        .then((data) => {
          if (isRef) {
            dispatch(setRefPlayer({ id: id, url: data }));
          } else {
            dispatch(setMainPlayer({ id: id, url: data }));
          }
          if (trackedPlayerId === id) {
            dispatch(
              setLocalPlayer({
                url: data,
                trackedPlayerId:
                  trackedPlayerId === mainPlayerId ? mainPlayerId : refPlayerId,
                keepPosition: true,
                abState: trackedPlayerId === mainPlayerId ? false : true,
              }),
            );
          }
        });
    },
    [dispatch, trackedPlayerId, mainPlayerId, refPlayerId, code],
  );

  const downloadABTrackWav = useCallback(
    async (isRef: boolean, id?: number) => {
      if (!id) return;
      void dispatch(
        downloadTrack({
          fileVersionId: id,
          code: code || undefined,
        }),
      )
        .unwrap()
        .then((data) => {
          if (isRef) {
            dispatch(setRefPlayer({ id: id, url: data }));
          } else {
            dispatch(setMainPlayer({ id: id, url: data }));
          }
          if (trackedPlayerId === id) {
            dispatch(
              setLocalPlayer({
                url: data,
                trackedPlayerId:
                  trackedPlayerId === mainPlayerId ? mainPlayerId : refPlayerId,
                keepPosition: true,
                abState: trackedPlayerId === mainPlayerId ? false : true,
              }),
            );
          }
        });
    },
    [dispatch, mainPlayerId, refPlayerId, trackedPlayerId, code],
  );

  useEffect(() => {
    if (!isRehydrating) return;
    if (!loggedInUser) {
      dispatch(resetAbPlayStore());
      return;
    }

    const downloadTrackForFooter = async () => {
      if (!loggedInUser || isLoading) return;
      switch (footerFileTrackType) {
        case FooterFileTrackType.COMPLETED_PROJECT:
          if (!file || url) break;
          await downloadCompletedTrack();
          break;
        default:
          break;
      }
      dispatch(setIsRehydrating(false));
    };

    void downloadTrackForFooter();
  }, [
    dispatch,
    downloadABTrackMP3,
    downloadABTrackWav,
    downloadCompletedTrack,
    file,
    footerFileTrackType,
    isLoading,
    isRehydrating,
    loggedInUser,
    mainUrl,
    mixOrMasterFileVersion,
    projectId,
    refPlayerId,
    refUrl,
    referenceFileVersion,
    trackedPlayerId,
    url,
  ]);
};
export const useDownloadTrackFromFileVersionId = (fileVersionId = 0) => {
  const dispatch = useAppDispatch();

  return useQuery({
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    retry: 0,
    refetchOnMount: true,
    enabled: Boolean(fileVersionId),
    queryKey: [QUERY_KEYS.DOWNLOAD_TRACK_BY_FILE_VERSION_ID, fileVersionId],
    queryFn: async () => {
      const fetchTrackObject: QueryParamsType = {};
      fetchTrackObject.use_generated_mp3 = true;
      fetchTrackObject.file_version_id = fileVersionId;
      const params = `?${queryString.stringify(fetchTrackObject)}`;
      try {
        const { url, content_type } = await downloadTrackByPath({ params });
        const blob = await readStream(url, undefined, content_type);
        return URL.createObjectURL(blob);
        return url;
      } catch (e) {
        const errors = { errors: e as Error };
        dispatch(receiveErrors(errors));
      }
    },
  });
};
export const useDownloadTrackFromFileVersion = (fileVersion?: FileVersion) => {
  const [url, setURL] = useState<string>("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!fileVersion) {
      return;
    }
    void dispatch(downloadTrackSnippet({ fileVersionId: fileVersion.id }))
      .unwrap()
      .then((data) => {
        setURL(data);
      });
  }, [dispatch, fileVersion]);
  return {
    url,
  };
};
