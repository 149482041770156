import { styled, Box } from "@mui/material";

export const GenerateBookingCardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$selected" && prop !== "$isDesktop",
})<{ $selected: boolean; $isDesktop: boolean }>(
  ({ theme, $selected, $isDesktop }) => ({
    display: "flex",
    flexDirection: "column",
    width: "264px",
    height: $isDesktop ? "342px" : "unset",
    boxSizing: "border-box",
    padding: "16px",
    borderRadius: " 16px",
    outline: `1px solid ${$selected ? theme.palette.standardColor.Green[600] : theme.palette.customColor.darkerMidgroundColor}`,
    gap: theme.spacing(2),
    background: theme.palette.customColor.dropdownBackgroundColor,
    [theme.breakpoints.down("md")]: {
      width: "unset",
      flex: 1,
    },
  }),
);

export const GenerateBookingHeaderTextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "fit-content",
  alignItems: "center",
  padding: "4px 8px",
  borderRadius: " 8px",
  background: theme.palette.customColor.midgroundColor,
}));

export const GenerateBookingHeaderContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  alignSelf: "stretch",
}));

export const GenerateBookingCheckboxContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$selected",
})<{ $selected: boolean }>(({ theme, $selected }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "24px",
  width: "24px",
  borderRadius: "50%",
  backgroundColor: $selected
    ? theme.palette.standardColor.Green[50]
    : theme.palette.standardColor.Gray[100],
  color: $selected
    ? theme.palette.standardColor.Green[600]
    : theme.palette.standardColor.Gray[100],
}));
