import { SelectChangeEvent } from "@mui/material";
import { OptionsDropdown } from "../../core-ui/components/MUIOptionsDropdown/MUIOptionsDropdown";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";

export interface BundleDropdownProps {
  selectedBundleId: number | "";
  bundlesOptions: OptionType<number>[];
  updateSelectedBundleId: (_: number) => void;
}
export const BundleDropdown = ({
  selectedBundleId,
  bundlesOptions,
  updateSelectedBundleId,
}: BundleDropdownProps) => {
  return (
    <OptionsDropdown
      value={selectedBundleId}
      options={bundlesOptions}
      onChange={({ target: { value } }: SelectChangeEvent<number>) => {
        if (typeof value === "number") {
          updateSelectedBundleId(value);
        }
      }}
      placeholder="Choose a bundle"
      id={BUNDLE_DROPDOWN_ID}
    />
  );
};

export const BUNDLE_DROPDOWN_ID = "bundle-dropdown";
