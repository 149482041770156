import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { receiveErrors } from "../../store/actions/errorStore";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Studio } from "../../store/models/studio";
import { selectCurrentUser } from "../../store/selectors/userInfoSelectors";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_AFFILIATED_STUDIOS } from "../../store/utils/routes";

interface useGetAffiliatedStudiosForUserProps {
  userId: number;
  enabled?: boolean;
}

export const useGetAffiliatedStudiosForUser = ({
  userId,
  enabled = true,
}: useGetAffiliatedStudiosForUserProps) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  return useQuery({
    staleTime: userId && userId === currentUser?.id ? Infinity : undefined,
    queryKey: [QUERY_KEYS.GET_AFFILIATED_STUDIOS, userId],
    enabled: Boolean(userId) && enabled,
    queryFn: async () => {
      const params = `?user_id=${userId}`;
      const result = await makeBackendGetCallWithJsonResponse<Studio[]>(
        GET_AFFILIATED_STUDIOS,
        params,
      );
      if (result.success) {
        return result.resultJson;
      }
      const errors = { errors: result.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(errors);
    },
  });
};
