export enum AuthSteps {
  NONE,
  START_LOGIN,
  LOGIN,
  START_SIGNUP,
  EMAIL_SIGNUP,
  PROFILE_TYPE,
  USERNAME,
  VERIFY_EMAIL,
  ADD_PHONE,
  VERIFY_PHONE,
  COMPLETE,
  ADD_STUDIO_LOCATION,
  ADD_STUDIO_CONTACT,
  AANDR_LABEL_SELECTION,
  AANDR_BUILD_TEAM,
  AANDR_USERNAME,
  AANDR_ADD_PHONE,
  AANDR_VERIFY_PHONE,
}

export enum AuthFlows {
  NONE,
  LOGIN,
  EMAIL_LOGIN,
  PHONE_ADD,
  PHONE_LOGIN,
  PHONE_SIGNUP,
  START_SIGNUP,
  STANDARD_SIGNUP,
  STANDARD_RESUME_LOGIN,
  PHONE_RESUME_LOGIN,
  STUDIO_OWNER,
  STUDIO_OWNER_RESUME_LOGIN,
  AANDR,
  AANDR_RESUME_LOGIN,
  ADD_STUDIO,
}

type FlowStepsType = {
  [key in AuthFlows]: {
    totalSteps: number;
    steps: AuthSteps[][];
  };
};

export const FLOW_STEPS: FlowStepsType = {
  [AuthFlows.NONE]: {
    totalSteps: 0,
    steps: [],
  },
  [AuthFlows.LOGIN]: {
    totalSteps: 1,
    steps: [[AuthSteps.START_LOGIN]],
  },
  [AuthFlows.EMAIL_LOGIN]: {
    totalSteps: 1,
    steps: [[AuthSteps.LOGIN]],
  },
  [AuthFlows.PHONE_ADD]: {
    totalSteps: 1,
    steps: [[AuthSteps.ADD_PHONE]],
  },
  [AuthFlows.PHONE_LOGIN]: {
    totalSteps: 1,
    steps: [[AuthSteps.VERIFY_PHONE]],
  },
  [AuthFlows.PHONE_SIGNUP]: {
    totalSteps: 4,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      [AuthSteps.USERNAME],
      [AuthSteps.VERIFY_EMAIL],
      [AuthSteps.COMPLETE],
    ],
  },
  [AuthFlows.START_SIGNUP]: {
    totalSteps: 2,
    steps: [[AuthSteps.START_SIGNUP], [AuthSteps.EMAIL_SIGNUP]],
  },
  [AuthFlows.STANDARD_SIGNUP]: {
    totalSteps: 3,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      [AuthSteps.USERNAME, AuthSteps.VERIFY_EMAIL],
      [AuthSteps.ADD_PHONE, AuthSteps.VERIFY_PHONE],
      [AuthSteps.COMPLETE],
    ],
  },
  [AuthFlows.STANDARD_RESUME_LOGIN]: {
    totalSteps: 3,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      [AuthSteps.USERNAME, AuthSteps.VERIFY_EMAIL],
      [AuthSteps.ADD_PHONE, AuthSteps.VERIFY_PHONE],
    ],
  },
  [AuthFlows.PHONE_RESUME_LOGIN]: {
    totalSteps: 2,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      [AuthSteps.USERNAME, AuthSteps.VERIFY_EMAIL],
    ],
  },
  [AuthFlows.STUDIO_OWNER]: {
    totalSteps: 3,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      //[AuthSteps.ADD_STUDIO_LOCATION, AuthSteps.ADD_STUDIO_CONTACT],
      [AuthSteps.ADD_STUDIO_LOCATION],
      [AuthSteps.VERIFY_EMAIL, AuthSteps.ADD_PHONE, AuthSteps.VERIFY_PHONE],
      [AuthSteps.COMPLETE],
    ],
  },
  [AuthFlows.STUDIO_OWNER_RESUME_LOGIN]: {
    totalSteps: 3,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      //[AuthSteps.ADD_STUDIO_LOCATION, AuthSteps.ADD_STUDIO_CONTACT],
      [AuthSteps.ADD_STUDIO_LOCATION],
      [AuthSteps.VERIFY_EMAIL, AuthSteps.ADD_PHONE, AuthSteps.VERIFY_PHONE],
    ],
  },
  [AuthFlows.AANDR]: {
    totalSteps: 4,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      //[AuthSteps.AANDR_LABEL_SELECTION, AuthSteps.AANDR_BUILD_TEAM],
      [AuthSteps.AANDR_LABEL_SELECTION],
      [AuthSteps.AANDR_USERNAME],
      [AuthSteps.AANDR_ADD_PHONE, AuthSteps.AANDR_VERIFY_PHONE],
      [AuthSteps.COMPLETE],
    ],
  },
  [AuthFlows.AANDR_RESUME_LOGIN]: {
    totalSteps: 4,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      //[AuthSteps.AANDR_LABEL_SELECTION, AuthSteps.AANDR_BUILD_TEAM],
      [AuthSteps.AANDR_LABEL_SELECTION],
      [AuthSteps.AANDR_USERNAME],
      [AuthSteps.AANDR_ADD_PHONE, AuthSteps.AANDR_VERIFY_PHONE],
    ],
  },
  [AuthFlows.ADD_STUDIO]: {
    totalSteps: 1,
    steps: [
      //[AuthSteps.ADD_STUDIO_LOCATION], [AuthSteps.ADD_STUDIO_CONTACT]
      [AuthSteps.ADD_STUDIO_LOCATION],
    ],
  },
};
