import { atomWithStorage } from "jotai/utils";
import { atom, Getter, Setter } from "jotai";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";

interface ProductPriceDetailsValue {
  totalPrice: number;
  unitPrice: number;
}

export interface ProductPriceDetails {
  [key: number]: ProductPriceDetailsValue;
}

const _productPriceDetailsAtom = atomWithStorage<ProductPriceDetails>(
  LOCAL_STORAGE_KEYS.PRODUCT_PRICE_DETAILS,
  {},
);

const productDetailsGetter = (get: Getter) => {
  return get(_productPriceDetailsAtom);
};

const productDetailsSetter = (
  get: Getter,
  setter: Setter,
  productPriceDetails: ProductPriceDetails,
) => {
  const currentProductPriceDetails = get(_productPriceDetailsAtom);
  setter(
    _productPriceDetailsAtom,
    Object.assign({}, currentProductPriceDetails, productPriceDetails),
  );
};

export const productPriceDetailsAtom = atom(
  productDetailsGetter,
  productDetailsSetter,
);
