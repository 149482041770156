import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { RecordingService } from "../../store/models/recording";
import Service from "../../store/models/service";
import { uploadFilePromise } from "../../store/utils/fetch";
import { IDLE_PROGRESS } from "../../stories/screens/ProfileScreen/constants";
import { useGetServicePhotoSignedUrl } from "./useUploadServicePhoto";

interface useOnUploadServicePhotoProps {
  service?: Service | RecordingService;
  engineerId: number;
}

export const useOnUploadServicePhoto = ({
  service,
  engineerId,
}: useOnUploadServicePhotoProps) => {
  const queryClient = useQueryClient();
  const [uploadingPhoto, setUploadingPhoto] = useState<File>();
  const [progress, setProgress] = useState(IDLE_PROGRESS);
  const { mutateAsync: getSignedUrl } = useGetServicePhotoSignedUrl();
  const xhr = useMemo(() => {
    return new XMLHttpRequest();
  }, []);

  const uploadServicePhoto = async (acceptedFile: File) => {
    try {
      if (!service) return;

      setProgress(0);

      const { signed_url, entity_photo } = await getSignedUrl({
        service_id: service.id!,
        service_type: service.service_type,
        content_type: acceptedFile.type,
        file_size: acceptedFile.size,
      });

      await uploadFilePromise(
        acceptedFile,
        signed_url,
        (progress) => {
          setProgress(progress);
        },
        xhr,
      );

      queryClient.setQueryData(
        [QUERY_KEYS.GET_ENGINEER_SERVICES, engineerId],
        (oldData: (Service | RecordingService)[] | undefined) => {
          if (!oldData) {
            return undefined;
          }

          return oldData.map((oldService) => {
            if (oldService.service_type === service.service_type) {
              return {
                ...oldService,
                cover_photo: entity_photo,
              };
            }
            return oldService;
          });
        },
      );

      setProgress(IDLE_PROGRESS);

      return entity_photo.id;
    } catch (e) {
      toast.error(
        "Something went wrong with your upload. Reach out for support.",
      );
    }
  };

  const cancelRequest = () => {
    xhr.abort();
  };

  return {
    uploadServicePhoto,
    progress,
    uploadingPhoto,
    setUploadingPhoto,
    cancelRequest,
  };
};
