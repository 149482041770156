import { Box, styled } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";

export const ProductCardContainer = styled(Box)(({ theme, onClick }) => ({
  width: "215px",
  boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.15)",
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.border.radius.md,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  cursor: onClick ? "pointer" : "default",
}));

export const ProductImage = styled("img")({
  aspectRatio: 1,
  width: "100%",
  objectFit: "cover",
});

export const ProductBodyContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "8px 16px 16px 16px",
  gap: "8px",
  flex: 1,
});

export const ProductFooter = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const PriceTagContainer = styled(Box)(({ theme }) => ({
  padding: "4px",
  borderRadius: theme.border.radius.xxs,
  backgroundColor: theme.palette.customColor.darkerMidgroundColor,
}));

export const PriceTag = styled(Text)(({ theme }) => ({
  color: `${theme.palette.customColor.textTertiaryColor} !important`,
}));
