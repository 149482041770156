import queryString from "query-string";
import { EngineEarsError } from "../../store/actions/errorStore";
import { Release, ReleaseTrack, ReleaseType } from "../../store/models/release";
import { BareUser } from "../../store/models/user";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
  makeBackendPutCallWithJsonResponse,
} from "../../store/utils/fetch";
import {
  ARTIST_USER_RELEASE_ROUTE,
  BASE_RELEASE_API,
  CREATE_RELEASE_FROM_SCHEDULED_PROJECT,
  DOWNLOAD_RELEASE_TRACKS,
  FEATURED_RELEASE_ROUTE,
  SCHEDULED_PROJECT_RELEASE_ROUTE,
  SWAP_ORDER_ROUTE,
  TRACKS_ROUTE,
} from "../../store/utils/routes";
import { replaceRoutePlaceholders } from "../helpers";

export const fetchReleaseByReleaseId = async (releaseId: number) => {
  const route = replaceRoutePlaceholders(`${BASE_RELEASE_API}{releaseId}/`, {
    releaseId,
  });
  return await makeBackendGetCallWithJsonResponse<Release | EngineEarsError>(
    route,
    "",
  );
};

export const fetchReleaseByScheduledProjectId = async (
  scheduledProjectId: number,
) => {
  const route = replaceRoutePlaceholders(
    `${SCHEDULED_PROJECT_RELEASE_ROUTE}{scheduledProjectId}/`,
    { scheduledProjectId },
  );
  return await makeBackendGetCallWithJsonResponse<Release | EngineEarsError>(
    route,
    "",
  );
};

export interface ArtistReleaseResponse {
  data: Release[];
  count: number;
  current_page: number;
  total_pages: number;
}

export const fetchArtistReleases = async (
  artistUserId: number,
  releaseType: ReleaseType,
  pageParam: number,
  isEditMode: boolean,
) => {
  const params = queryString.stringify({
    page: pageParam,
    is_edit_mode: isEditMode,
  });
  const route = replaceRoutePlaceholders(
    `${ARTIST_USER_RELEASE_ROUTE}{artistUserId}/{releaseType}/`,
    { artistUserId, releaseType },
  );
  return await makeBackendGetCallWithJsonResponse<
    ArtistReleaseResponse | EngineEarsError
  >(`${route}?${params}`, "");
};

export const fetchFeaturedRelease = async (artistUserId: number) => {
  const route = replaceRoutePlaceholders(
    `${FEATURED_RELEASE_ROUTE}{artistUserId}/`,
    { artistUserId },
  );
  return await makeBackendGetCallWithJsonResponse<Release | EngineEarsError>(
    route,
    "",
  );
};

export interface ArtistTrendingReleaseTracksResponse {
  data: ReleaseTrack[];
  count: number;
  current_page: number;
  total_pages: number;
}

export const fetchTrendingArtistReleaseTracks = async (
  pageParam: number,
  artistUserId = 0,
) => {
  const params = queryString.stringify({ page: pageParam });
  const route = replaceRoutePlaceholders(
    `${ARTIST_USER_RELEASE_ROUTE}{artistUserId}/tracks/trending/`,
    { artistUserId },
  );
  return await makeBackendGetCallWithJsonResponse<
    ArtistTrendingReleaseTracksResponse | EngineEarsError
  >(`${route}?${params}`, "");
};

export const fetchReleaseTracks = async (releaseId: number) => {
  const route = replaceRoutePlaceholders(
    `${BASE_RELEASE_API}{releaseId}/tracks/`,
    { releaseId },
  );
  return await makeBackendGetCallWithJsonResponse<
    ReleaseTrack[] | EngineEarsError
  >(route, "");
};

export interface ReleaseDetails {
  recording_engineer_users: BareUser[];
  mixing_engineer_users: BareUser[];
  dolby_atmos_mixing_engineer_users: BareUser[];
  mastering_engineer_users: BareUser[];
  // Temporarily hide play count
  // total_play: number;
}

export const fetchReleaseDetails = async (releaseId = 0) => {
  const route = replaceRoutePlaceholders(
    `${BASE_RELEASE_API}{releaseId}/details/`,
    { releaseId },
  );
  return await makeBackendGetCallWithJsonResponse<
    ReleaseDetails | EngineEarsError
  >(route, "");
};

export const updateReleaseTrackOrder = async (releaseTrackIds: number[]) => {
  return await makeBackendPutCallWithJsonResponse(SWAP_ORDER_ROUTE, {
    release_tracks_to_swap: releaseTrackIds,
  });
};

export const createReleaseFromScheduledProject = async (
  releaseType: ReleaseType,
  scheduledProjectId = 0,
  published = true,
  featured = true,
) => {
  const args = {
    scheduled_project_id: scheduledProjectId,
    release_type: releaseType,
    published,
    featured,
  };
  return await makeBackendPostCallWithJsonResponse<Release>(
    CREATE_RELEASE_FROM_SCHEDULED_PROJECT,
    args,
  );
};

export interface UpdateReleaseArgs {
  title?: string;
  artist_display_name?: string;
  description?: string;
  featured?: boolean;
  published_at?: boolean;
}

export const updateRelease = async (
  releaseId: number,
  args: UpdateReleaseArgs,
) => {
  const route = replaceRoutePlaceholders(`${BASE_RELEASE_API}{releaseId}/`, {
    releaseId,
  });
  return await makeBackendPutCallWithJsonResponse<Release>(route, args);
};

export const updateReleaseTrack = async (
  releaseTrackId: number,
  title: string,
) => {
  const route = replaceRoutePlaceholders(`${TRACKS_ROUTE}{releaseTrackId}/`, {
    releaseTrackId,
  });
  return makeBackendPutCallWithJsonResponse<ReleaseTrack>(route, { title });
};

export const fetchReleaseTrackById = async (releaseTrackId: number) => {
  const route = replaceRoutePlaceholders(`${TRACKS_ROUTE}{releaseTrackId}/`, {
    releaseTrackId,
  });
  return makeBackendGetCallWithJsonResponse<ReleaseTrack>(route, "");
};

export const downloadReleaseTracks = async (
  releaseId: number,
  params: string,
) => {
  const route = replaceRoutePlaceholders(DOWNLOAD_RELEASE_TRACKS, {
    release_id: releaseId,
  });
  return makeBackendGetCallWithJsonResponse<{
    download_urls: string[];
  }>(route, params);
};
