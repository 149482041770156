import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { ServiceOperations } from "../../store/actions/services";
import { ProjectType } from "../../store/models/project";
import {
  DEFAULT_NOTICE_DAYS,
  DEFAULT_SONGS_PER_DAY,
  DEFAULT_WORKDAYS,
  EngineerSchedulingSurvey,
  ServiceFormProps,
} from "../../store/models/service";
import User from "../../store/models/user";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { useEngineerServiceCrud } from "./useEngineerServiceCrud";
import { useUpdateSchedulingSurveyAnswers } from "./useUpdateSchedulingSurveyAnswers";

interface useEngineeringServiceFormProps {
  selectedServiceType: ProjectType;
  userData: User;
  isCreatingNewService: boolean;
  pendingFormData: ServiceFormProps | undefined;
}

export const useEngineeringServiceForm = ({
  selectedServiceType,
  userData,
  isCreatingNewService,
  pendingFormData,
}: useEngineeringServiceFormProps) => {
  const queryClient = useQueryClient();
  const [surveyAnswers, setSurveyAnswers] = useState<EngineerSchedulingSurvey>({
    service_type: selectedServiceType,
    songs_per_day: DEFAULT_SONGS_PER_DAY,
    workdays: DEFAULT_WORKDAYS,
    number_of_days_notice: DEFAULT_NOTICE_DAYS,
  });
  const [enableRushFees, setEnableRushFees] = useState(false);
  const {
    mutateAsync: engineerServiceCrud,
    isPending: isEngineerServiceCrudLoading,
  } = useEngineerServiceCrud({});
  const {
    isPending: isUpdatingSchedulingSurveyAnswers,
    mutateAsync: updateSchedulingSurveyAnswers,
  } = useUpdateSchedulingSurveyAnswers();
  const hasSchedulingSurveyAnswersBeenFetched =
    queryClient.getQueryState([
      QUERY_KEYS.GET_SCHEDULING_SURVEY_ANSWERS,
      userData.id,
      selectedServiceType,
    ])?.status === "success";

  const handleSavingSchedulingPreferences = async () => {
    try {
      emitAnalyticsTrackingEvent(
        "update_survey_answers",
        {
          service_type: `${selectedServiceType}`,
        },
        userData.id,
      );

      await updateSchedulingSurveyAnswers({
        ...surveyAnswers,
        per_day_rush_fee_percentage: enableRushFees
          ? surveyAnswers.per_day_rush_fee_percentage
          : 0,
      });
    } catch (error) {
      // The error has already been handled
    }
  };

  const upsertEngineeringService = async () => {
    if (!pendingFormData) return;

    const operation = isCreatingNewService
      ? ServiceOperations.UPSERT
      : ServiceOperations.UPDATE;

    emitAnalyticsTrackingEvent(
      operation === ServiceOperations.UPDATE
        ? "update_engineering_service"
        : operation === ServiceOperations.UPSERT
          ? "add_engineering_service"
          : "delete_engineering_service",
      {
        service_type: `${selectedServiceType}`,
      },
      userData.id,
    );

    try {
      const serviceResponse = await engineerServiceCrud({
        updatedService: {
          ...pendingFormData,
          service_rate: {
            ...pendingFormData.service_rate,
            user_id: userData.id,
          },
        },
        operation,
      });

      return serviceResponse;
    } catch (error) {
      // The error has already been handled
    }
  };

  const isSubmitting = useMemo(() => {
    return isUpdatingSchedulingSurveyAnswers || isEngineerServiceCrudLoading;
  }, [isEngineerServiceCrudLoading, isUpdatingSchedulingSurveyAnswers]);

  return {
    surveyAnswers,
    setSurveyAnswers,
    enableRushFees,
    setEnableRushFees,
    isSubmitting,
    hasSchedulingSurveyAnswersBeenFetched,
    handleSavingSchedulingPreferences,
    upsertEngineeringService,
  };
};
