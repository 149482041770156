import { Fade, Skeleton } from "@mui/material";
import { ReactNode, useState } from "react";
import EntityPhoto from "../../../store/models/entityPhoto";
import { getEntityPhotoImageProps } from "../../screens/ProfileScreen/utils";
import { ReleaseCoverPhotoViewContainer } from "./ReleaseList.styles";

export interface ReleaseListItemCoverPhotoProps {
  releaseCover: EntityPhoto | null;
  width?: number;
  height?: number;
  children?: ReactNode;
  loading?: boolean;
  noExplicitWidth?: boolean;
  hasShadow?: boolean;
}

export const ReleaseListItemCoverPhoto = ({
  releaseCover,
  width = 64,
  height = 64,
  children,
  loading = false,
  noExplicitWidth = false,
  hasShadow = true,
}: ReleaseListItemCoverPhotoProps) => {
  const imageProps = getEntityPhotoImageProps(releaseCover);
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <ReleaseCoverPhotoViewContainer
      width={width}
      height={height}
      $noExplicitWidth={noExplicitWidth}
      $hasShadow={hasShadow}
    >
      {(!loaded || loading) && (
        <Skeleton variant={"rounded"} width={width} height={height} />
      )}
      <Fade in={loaded && !loading}>
        <img
          onLoad={() => setLoaded(true)}
          style={{
            objectFit: "cover",
            zIndex: 1,
            ...(noExplicitWidth
              ? {
                  width: "100%",
                  height: "unset",
                }
              : {}),
          }}
          width={width}
          height={height}
          {...imageProps}
          src={imageProps.src}
        />
      </Fade>
      {children}
    </ReleaseCoverPhotoViewContainer>
  );
};
