import { HTMLAttributes, ReactNode } from "react";
import { StyledText } from "./Text.styles";
import { TextColor, TextComponent, TextStyleVariant } from "./TextUtils";

export const TEXT_COLOR = TextColor;

export enum TEXT_SIZE {
  XXS = "var(--text-size-xxs)",
  XS = "var(--text-size-xs)",
  SMALL = "var(--text-size-sm)",
  MEDIUM = "var(--text-size-md)",
  LARGE = "var(--text-size-lg)",
  XLARGE = "var(--text-size-xlg)",
  XXLARGE = "var(--text-size-xxlg)",
}

export enum TEXT_WEIGHT {
  MEDIUM = "",
  SEMI_BOLD = "var(--text-weight-semibold)",
  BOLD = "var(--text-weight-bold)",
}

export enum TEXT_LINE_HEIGHT {
  SHORT = "var(--text-line-height-short)",
  NORMAL = "",
  LONG = "var(--text-line-height-long)",
  NO_EXTRA_HEIGHT = "1",
}

export type TextProps = {
  bold?: boolean;
  children: ReactNode;
  className?: HTMLAttributes<HTMLParagraphElement>["className"];
  color?: TextColor;
  component?: TextComponent;
  lineHeight?: TEXT_LINE_HEIGHT; // deprecated (use variant instead)
  size?: TEXT_SIZE; // deprecated (use variant instead)
  variant?: TextStyleVariant;
  weight?: TEXT_WEIGHT; // deprecated (use bold instead)
} & Pick<HTMLAttributes<HTMLParagraphElement>, "className" | "style" | "title">;

/**
 * A basic text component that can be used to render text with different styles.
 *
 * Use the TextStyleVariant enum to set the style of the text in the `variant` prop.
 * Use the TextComponent enum to define the specific HTML element to render the text in the `component` prop.
 *
 * Bold text can be set using the `bold` prop.
 * Color can be set using the `color` prop.
 *
 * ClassName and Style can be used for custom styling.
 * All other props should be phased out.
 */
export const Text = ({
  bold = false,
  children,
  component,
  color,
  className,
  lineHeight,
  size,
  style,
  variant = TextStyleVariant.P1,
  weight,
}: TextProps) => {
  return (
    <StyledText
      $bold={bold}
      className={className}
      $color={color}
      component={component}
      $styleVariant={variant}
      variant={variant}
      style={{
        ...(lineHeight && { lineHeight }),
        ...(size && { fontSize: size }),
        ...(weight && { fontWeight: weight }),
        ...style,
      }}
    >
      {children}
    </StyledText>
  );
};
