import { faBolt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import Lottie from "react-lottie";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  bottomNavHeightAtom,
  showBottomNavAtom,
} from "../../../atoms/navAtoms";
import { DevLinkProvider, EngineEarsFooter2024 } from "../../../devlink";
import {
  useIsPrimaryStudioManager,
  useIsStudioConfigured,
} from "../../../hooks/studioHooks";
import { useFetchTrophies } from "../../../hooks/useFetchTrophies";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { useGetTeams } from "../../../hooks/useTeam";
import { isValidRecordingServiceStudio } from "../../../hooks/useValidRecordingServices";
import { RootState } from "../../../store";
import { applyPromoCode } from "../../../store/actions/marketing";
import { clearRecordingServices } from "../../../store/actions/services";
import { SetUpCartPayload } from "../../../store/actions/shoppingCart";
import { getSubscriptionStatus } from "../../../store/actions/subscriptions";
import { createTransaction } from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import EntityPhoto from "../../../store/models/entityPhoto";
import { ProjectType } from "../../../store/models/project";
import { StudioRoom } from "../../../store/models/studio";
import { selectStudioRoom } from "../../../store/selectors/studioRoomSelector";
import { useManagerCanEditStudio } from "../../../store/selectors/teamSelectors";
import { getDisplayableNameForStudio } from "../../../store/utils/entityUtils";
import { getTransactionBookingScreenRoute } from "../../../store/utils/routeGetters";
import { BookingParameters } from "../../../store/utils/transactions";
import { host } from "../../../store/utils/utils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { AmenityList } from "../../components/AmenityList/AmenityList";
import { DirectMessageButtonController } from "../../components/DirectMessageButton/DirectMessageButtonController";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {
  DEFAULT_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "../../components/Navigation/BottomNav/useBottomTabBarOverlayView";
import { ReviewsList } from "../../components/ReviewsList/ReviewsList";
import { EditStudioModal } from "../../components/StudioModals/EditStudioModal";
import StudioRoomList from "../../components/StudioRoom/StudioRoomList";
import { StudioTeammates } from "../../components/StudioTeammates/StudioTeammates";
import { TrophyListItem } from "../../components/TrophyRoomList/TrophyRoomList";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Card } from "../../elements/Card/Card";
import { ExpandableTextArea } from "../../elements/ExpandableTextArea/ExpandableTextArea";
import { MapDisplay } from "../../elements/MapDisplay/MapDisplay";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import underMaintenance from "../../lotties/webpage-under-maintenance.json";
import { EntityPhotosScreen } from "../EntityPhotosScreen/EntityPhotosScreen";
import StudioBenefitsAndConnection from "./StudioBenefitsAndConnection";
import StudioHeader from "./StudioHeader";
import "./StudioScreen.css";
import {
  StudioScreenActionButtonWrapper,
  StudioScreenConstructionLogoWrapper,
  StudioScreenConstructionWrapper,
} from "./StudioScreen.styles";
import useStudioQuery from "../../../hooks/studioHooks/useStudioQuery";
import useStudioRoomsQuery from "../../../hooks/studioHooks/useStudioRoomsQuery";
import isGoogleMapsLoadedAtom from "../../../atoms/maps/isGoogleMapsLoadedAtom";

export const StudioScreen = () => {
  const [showStudioPhotos, setShowStudioPhotos] = useState(false);
  const { username } = useParams<{ username: string | undefined }>();
  const { data: studioDetails, isLoading: isStudioLoading } = useStudioQuery({
    username,
  });
  const { data: rooms, isLoading: isStudioRoomsLoading } = useStudioRoomsQuery({
    username,
  });
  const history = useHistory();
  const [isBookNowLoading, setIsBookNowLoading] = useState(false);
  const showStickyButtons = useAtomValue(showBottomNavAtom);
  const bottomHeight = useAtomValue(bottomNavHeightAtom);

  const shareURL = useMemo(() => {
    return `${host}/studio/${studioDetails?.username || username}`;
  }, [username, studioDetails?.username]);

  const userCanEditStudio = useManagerCanEditStudio(studioDetails?.id);
  const editQueryParam = useQueryParam("edit").get();
  const [showEditStudioModal, setShowEditStudio] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: RootState) => state.accountInfo);
  const isLoaded = useAtomValue(isGoogleMapsLoadedAtom);
  const { studioRooms, equipmentHighlights } = useAppSelector(
    selectStudioRoom(username?.toLowerCase(), userCanEditStudio),
  );
  const { isDesktop } = useMediaQueryBreakpoint();
  const studioConfigured = useIsStudioConfigured(studioDetails);
  const { studioTrophies } = useFetchTrophies({ studioId: studioDetails?.id });
  useGetTeams();
  const isPrimaryStudioManager = useIsPrimaryStudioManager(studioDetails?.id);
  const location = useLocation();
  const appliedPromoCode = useAppSelector(
    (state: RootState) => state.marketingDataStore.appliedPromoCode,
  );
  const showDirectMessageButton = Boolean(
    !isDesktop && studioDetails && !userCanEditStudio,
  );
  const showBookNowButton = Boolean(
    isValidRecordingServiceStudio(studioDetails, user) &&
      studioRooms.find((studioRoom: StudioRoom) =>
        Boolean(studioRoom.recording_service),
      ),
  );

  // if the edit param is in the URL, show the edit modal, only if the user can edit the studio.
  useEffect(() => {
    if (editQueryParam && userCanEditStudio) {
      setShowEditStudio(true);
    }
  }, [editQueryParam, userCanEditStudio]);

  // Fire studio visit analytic on page mount
  useEffect(() => {
    if (!studioDetails?.id) {
      return;
    }
    emitAnalyticsTrackingEvent(
      "studio_screen_visit",
      {
        studioUsername: username,
        studioId: studioDetails.id,
      },
      user?.id,
    );
  }, [studioDetails?.id, user?.id]);

  const onClickBookNow = async () => {
    if (!studioDetails) return;
    const studioRoom = studioRooms?.find(
      (studioRoom: StudioRoom) => studioRoom.recording_service,
    );
    if (!studioRoom?.recording_service) return;
    dispatch(clearRecordingServices());
    try {
      setIsBookNowLoading(true);
      const transaction = await dispatch(createTransaction()).unwrap();

      const bookingParameters: BookingParameters = {
        transactionId: +transaction.id,
        activeStudioId: studioDetails.id,
        activeStudioRoomId: studioRoom.id,
        activeServiceType: ProjectType.RECORDING,
        activeServiceTypeProjectIds: [],
      };

      const shoppingCart: SetUpCartPayload = {
        pendingSessionData: [],
      };

      emitAnalyticsTrackingEvent(
        "clicked_book_studio_screen",
        {
          transaction_id: bookingParameters.transactionId,
          active_studio_id: bookingParameters.activeStudioId,
          active_studio_room_id: bookingParameters.activeStudioRoomId,
          service_type: bookingParameters.activeServiceType,
        },
        user?.id,
      );

      history.push(
        getTransactionBookingScreenRoute(
          transaction.code,
          dispatch,
          bookingParameters,
          shoppingCart,
        ),
      );
    } catch {
      setIsBookNowLoading(false);
    }
  };

  const stickyFooterButton = useBottomTabBarOverlayView(
    showDirectMessageButton,
    <div style={{ display: "flex", gap: "8px" }}>
      <div style={{ flex: 1 }}>
        <DirectMessageButtonController
          studioId={studioDetails?.id}
          customLabel={!isDesktop ? "Message" : "Message Studio Manager"}
          customClassName="studio-screen-message-button sticky"
        />
      </div>
      {showBookNowButton && (
        <div style={{ flex: 1 }}>
          <Button
            fullWidth
            className="studio-screen-book-now-button"
            variant={ButtonVariant.GRADIENT}
            labelIcon={<FontAwesomeIcon icon={faBolt} />}
            onClick={onClickBookNow}
          >
            Book Now
          </Button>
        </div>
      )}
    </div>,
    DEFAULT_TAB_OVERLAY_CLASS,
  );

  useEffect(() => {
    if (!studioDetails) return;
    const searchParams = new URLSearchParams(location.search);
    const promoCode = searchParams.get("promocode");
    if (promoCode) {
      dispatch(
        applyPromoCode({
          promocode: promoCode,
          studio_id: studioDetails?.id,
        }),
      ).catch(() => {});
    }
  }, [location, dispatch, studioDetails?.id]);

  useEffect(() => {
    if (!isPrimaryStudioManager) return;
    if (isPrimaryStudioManager) {
      dispatch(getSubscriptionStatus({ refetch_subs_status: false })).catch(
        () => {},
      );
    }
  }, [isPrimaryStudioManager]);

  const [selectedPhoto, setSelectedPhoto] = useState<EntityPhoto | undefined>();
  useSetPageTitle(
    isStudioLoading ? "Loading..." : getDisplayableNameForStudio(studioDetails),
  );

  if (isStudioLoading || !studioDetails || isStudioRoomsLoading) {
    return <LoadingScreen />;
  }

  if (
    !isStudioLoading &&
    (!studioConfigured || !studioRooms?.length) &&
    !userCanEditStudio
  ) {
    return (
      <StudioScreenConstructionWrapper className="container-fluid flex flex-column justify-content-start align-items-center">
        <p
          style={{
            textAlign: "center",
          }}
          className={"h3"}
        >
          Studio profile is under construction. Please check back soon.
        </p>
        <StudioScreenConstructionLogoWrapper>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: underMaintenance,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={"100%"}
            width={"100%"}
          />
        </StudioScreenConstructionLogoWrapper>
      </StudioScreenConstructionWrapper>
    );
  }

  const renderBookNowButtonComponent = () => {
    if (
      !userCanEditStudio &&
      !showStudioPhotos &&
      studioDetails &&
      studioRooms &&
      !isStudioRoomsLoading
    ) {
      // Check if studio facility has valid recording services
      if (!isValidRecordingServiceStudio(studioDetails, user)) {
        return null;
      }

      // Check if any studio rooms have recording services
      const hasRecordingServiceRooms = studioRooms.find(
        (studioRoom: StudioRoom) => Boolean(studioRoom.recording_service),
      );
      if (!hasRecordingServiceRooms) {
        return null;
      }

      return (
        <Button
          className="studio-screen-book-now-button"
          variant={ButtonVariant.GRADIENT}
          loading={isBookNowLoading}
          labelIcon={<FontAwesomeIcon icon={faBolt} />}
          onClick={onClickBookNow}
          style={{
            minWidth: "158px",
          }}
        >
          Book Now
        </Button>
      );
    }

    return null;
  };

  const renderActionButtons = () => {
    if (userCanEditStudio) {
      return null;
    }

    const actionButtonContainerClassnames = classNames(
      "studio-screen-button-container-fixed",
    );

    return (
      <StudioScreenActionButtonWrapper
        $show={showStickyButtons}
        $navItemsHeight={bottomHeight}
        className={actionButtonContainerClassnames}
      >
        {renderBookNowButtonComponent()}
      </StudioScreenActionButtonWrapper>
    );
  };

  return (
    <>
      <StudioHeader
        studio={studioDetails}
        setShowEditStudio={setShowEditStudio}
        showEditStudioModal={showEditStudioModal}
        userCanEditStudio={userCanEditStudio}
        actionButtons={renderActionButtons()}
        setShowStudioPhotos={setShowStudioPhotos}
        setSelectedPhoto={setSelectedPhoto}
        trophies={studioTrophies}
      />
      <div className="studio-screen">
        <div className={"container"}>
          <div className={"studio-row header"}>
            <p className={"h3"}>Select a Studio Room</p>
            <p className={"h7 studio-row-header-subtitle"}>
              Our facilities are designed to equip you with everything you need.
              View and book a studio room below.
            </p>
          </div>
          {isStudioRoomsLoading && <SoundWaveLoader width={100} height={100} />}
          {studioDetails && studioRooms && !isStudioRoomsLoading && (
            <StudioRoomList
              studio={studioDetails}
              studioRooms={studioRooms}
              promoCode={
                appliedPromoCode?.studio === studioDetails.id
                  ? appliedPromoCode
                  : undefined
              }
            />
          )}
          <div className={"studio-row header"}>
            <p className={"h3"}>What&apos;s Included</p>
          </div>
          <div className={"studio-split-row"}>
            <div className={"studio-split-row-column"}>
              <div className={"studio-split-row-column-row"}>
                {studioDetails.studio_profile?.long_bio && (
                  <div className="studio-facilities-section">
                    <p className="b2">
                      <ExpandableTextArea
                        header={"ABOUT OUR FACILITY"}
                        body={studioDetails.studio_profile.long_bio}
                      />
                    </p>
                  </div>
                )}
                {studioDetails.studio_amenities &&
                  studioDetails.studio_amenities.length > 0 && (
                    <div className="studio-facilities-section">
                      <p className={"label-semi-bold studio-section-header"}>
                        AMENITIES
                      </p>
                      <AmenityList
                        studioId={studioDetails.id}
                        username={studioDetails.username}
                        amenities={studioDetails.studio_amenities}
                        editMode={false}
                        useCardForm={false}
                      />
                    </div>
                  )}
                {equipmentHighlights && (
                  <div className="studio-facilities-section">
                    <ExpandableTextArea
                      header="STUDIO EQUIPMENT"
                      body={equipmentHighlights}
                    />
                  </div>
                )}
                <div
                  className={"studio-facilities-section studio-screen-divider"}
                />
                <div className="studio-screen-location">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    width={18}
                    height={14}
                    style={{ marginRight: "4px" }}
                  />
                  <p className="studio-location-text h7-semi-bold">
                    Studio Location
                  </p>
                  {studioDetails.location?.city_location && (
                    <p className="b1">{studioDetails.location.city_location}</p>
                  )}
                </div>
                <div className="map-display-container">
                  {isLoaded &&
                    studioDetails.location?.rough_latitude &&
                    studioDetails.location?.rough_longitude && (
                      <MapDisplay
                        mapContainerStyle={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                        }}
                        latitude={studioDetails.location.rough_latitude}
                        longitude={studioDetails.location.rough_longitude}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className={"studio-split-row-column"}>
              <div className={"studio-split-row-column-row"}>
                <StudioTeammates
                  managerUsers={studioDetails?.studio_team?.managers ?? []}
                  engineerUsers={studioDetails?.studio_team?.members ?? []}
                />
                {studioTrophies && studioTrophies.length > 0 && (
                  <div>
                    <p className={"label-semi-bold studio-section-header mb-3"}>
                      OUR BADGES
                    </p>
                    {studioTrophies.map((trophy, index) => {
                      return (
                        <TrophyListItem
                          key={index}
                          idx={index}
                          trophy={trophy}
                          showInfo
                          showStatus={false}
                        />
                      );
                    })}
                  </div>
                )}
                <div
                  className={"studio-facilities-section studio-screen-divider"}
                />
                <StudioBenefitsAndConnection
                  studio={studioDetails}
                  userCanEditStudio={userCanEditStudio}
                  showMessageButton
                />
              </div>
            </div>
          </div>
          <Card hideOverflow customClassName="studio-reviews-card">
            <ReviewsList studio={studioDetails} hideIfNoReviews />
          </Card>
        </div>
        {showEditStudioModal && (
          <EditStudioModal
            studio={studioDetails}
            show={showEditStudioModal}
            showXButton={true}
            onHide={() => {
              if (showEditStudioModal) setShowEditStudio(false);
            }}
          />
        )}
        <EntityPhotosScreen
          selectedPhoto={selectedPhoto}
          shareUrl={shareURL}
          onClose={() => {
            setSelectedPhoto(undefined);
            setShowStudioPhotos(false);
          }}
          studio={studioDetails}
          canManageStudio={userCanEditStudio}
          username={username}
          slideUp={showStudioPhotos}
        />
        {stickyFooterButton}
      </div>
      <div className="studio-screen-footer">
        <DevLinkProvider>
          <EngineEarsFooter2024 />
        </DevLinkProvider>
      </div>
    </>
  );
};
