import { styled, Box } from "@mui/material";

export const GenerateBookingInfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  gap: theme.spacing(3),
}));

export const GenerateBookingInfoCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexWrap: "wrap",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    overflowY: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "nowrap",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: theme.spacing(2),
  },
}));

export const GenerateBookingInfoButtonGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  gap: theme.spacing(2),
  marginLeft: "auto",
  marginRight: "auto",
}));
