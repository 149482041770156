import { useMutation } from "@tanstack/react-query";
import queryString from "query-string";
import { toast } from "react-toastify";
import { displayEngineEarsError } from "../../api/helpers";
import { EngineEarsError } from "../../store/actions/errorStore";
import EntityPhoto from "../../store/models/entityPhoto";
import { ProjectType } from "../../store/models/project";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_SERVICE_PHOTO_SIGNED_URL } from "../../store/utils/routes";

interface MutateFunctionProps {
  service_type: ProjectType;
  service_id: number;
  content_type: string;
  file_size: number;
}

interface SignedUrlResponseProps {
  signed_url: string;
  entity_photo: EntityPhoto;
}

export const useGetServicePhotoSignedUrl = () => {
  return useMutation({
    mutationFn: async ({
      service_type,
      service_id,
      content_type,
      file_size,
    }: MutateFunctionProps) => {
      return makeBackendGetCallWithJsonResponse<SignedUrlResponseProps>(
        GET_SERVICE_PHOTO_SIGNED_URL,
        `?${queryString.stringify({
          service_type,
          service_id,
          content_type,
          file_size,
        })}`,
      ).then((res) => {
        if (!res.success) {
          throw res.resultJson;
        }

        return res.resultJson;
      });
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      return error;
    },
  });
};

interface uploadServicePhotoProps {
  url: string;
  file: File;
}

export const useUploadServicePhoto = () => {
  return useMutation({
    mutationFn: async ({ url, file }: uploadServicePhotoProps) => {
      return fetch(url, { body: file, method: "PUT" }).then((res) => {
        if (!res.ok) {
          throw new Error("There was an error uploading your photo!");
        }

        return null;
      });
    },
    // Error coming from the signed URL
    onError: (error) => {
      toast.error(error.message);
      return error;
    },
  });
};
