import { useQuery } from "@tanstack/react-query";
import { displayEngineEarsError } from "../../api/helpers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EquipmentTypeEnum } from "../../store/models/equipment";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_EQUIPMENT_AUTOCOMPLETE } from "../../store/utils/routes";

interface useGetAutocompleteEquipmentProps {
  equipmentType: EquipmentTypeEnum;
  query: string;
}

export const useGetAutoCompleteEquipment = ({
  equipmentType,
  query,
}: useGetAutocompleteEquipmentProps) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_EQUIPMENT_AUTOCOMPLETE, equipmentType, query],
    queryFn: async () => {
      const result = await makeBackendGetCallWithJsonResponse<
        { id: number; label: string }[]
      >(GET_EQUIPMENT_AUTOCOMPLETE, `${equipmentType}/${query}`);

      if (result.success) {
        return result.resultJson;
      }

      displayEngineEarsError(result.resultJson);
      return Promise.reject(result.resultJson);
    },
    enabled: Boolean(query),
  });
};
