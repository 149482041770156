import { Box, Theme, useMediaQuery, useTheme } from "@mui/material";
import { Dispatch, ReactElement, SetStateAction } from "react";
import { useMinAndMaxServiceRates } from "../../../hooks/useMinAndMaxServiceRates";
import { createRecordingServiceParams } from "../../../store/actions/recording";
import { ProjectType } from "../../../store/models/project";
import { RecordingService } from "../../../store/models/recording";
import Service, { ServiceFormProps } from "../../../store/models/service";
import { DollarFormatter } from "../../../store/utils/formatUtils";
import {
  getIncludedIncentiveByServiceType,
  getServiceFromServiceType,
} from "../../../store/utils/serviceUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  ServiceCardColumnContainer,
  ServiceCardRowContainer,
  ServiceCardV2Container,
} from "../ServiceCard/ServiceCardV2/ServiceCardV2.styles";
import { WhatsIncludedListItem } from "../WhatsIncludedListItem/WhatsIncludedListItem";
import "./EditServiceCard.css";
import { EditServiceCardContainer, StyledList } from "./EditServiceCard.styles";
import { ServiceCoverPhoto } from "./ServiceCoverPhoto";

export interface EditServiceCardProps {
  serviceType: ProjectType;
  children?: ReactElement | ReactElement[];
  service?: Service | RecordingService;
  engineerId: number;
  previewPrice: number;
  setPendingService: Dispatch<
    SetStateAction<createRecordingServiceParams | ServiceFormProps>
  >;
  showWhatsIncluded?: boolean;
}

export const EditServiceCard = ({
  children,
  service,
  serviceType,
  engineerId,
  previewPrice,
  setPendingService,
  showWhatsIncluded = true,
}: EditServiceCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const whatsIncluded: string[] = getIncludedIncentiveByServiceType({
    service_type: serviceType,
  });
  const [minServiceRate] = useMinAndMaxServiceRates(serviceType);

  return (
    <EditServiceCardContainer>
      {!isMobile && (
        <Box sx={{ width: "285px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "32px",
            }}
          >
            <div>
              <Text
                variant={TextStyleVariant.P2}
                style={{
                  color: theme.palette.customColor.disabledTextPrimaryColor,
                  fontWeight: 600,
                  marginBottom: "16px",
                }}
              >
                Service preview:
              </Text>
              <ServiceCardV2Container sx={{ width: "100%", height: "unset" }}>
                <ServiceCoverPhoto
                  service={service}
                  engineerId={engineerId}
                  serviceType={serviceType}
                  setPendingService={setPendingService}
                />
                <ServiceCardColumnContainer>
                  <Text bold variant={TextStyleVariant.S3}>
                    {getServiceFromServiceType(serviceType, true)}
                  </Text>
                  <Button disabled variant={ButtonVariant.OUTLINED}>
                    Select Service
                  </Button>

                  <ServiceCardRowContainer sx={{ justifyContent: "center" }}>
                    <Text variant={TextStyleVariant.P2}>
                      <strong>
                        {DollarFormatter().format(
                          previewPrice || minServiceRate,
                        )}
                      </strong>{" "}
                      {serviceType === ProjectType.RECORDING
                        ? "/hour"
                        : "/song"}
                    </Text>
                  </ServiceCardRowContainer>
                </ServiceCardColumnContainer>
              </ServiceCardV2Container>
            </div>
            {showWhatsIncluded && (
              <Box>
                <Text
                  style={{ textAlign: "center" }}
                  weight={TEXT_WEIGHT.SEMI_BOLD}
                >
                  What&apos;s included
                </Text>
                <StyledList>
                  {whatsIncluded.map((whatsIncludedBulletPoint) => {
                    return (
                      <WhatsIncludedListItem
                        whatsIncludedBulletPoint={whatsIncludedBulletPoint}
                        key={whatsIncludedBulletPoint}
                      />
                    );
                  })}
                </StyledList>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box sx={{ flex: 1, alignSelf: "stretch" }}>{children}</Box>
    </EditServiceCardContainer>
  );
};
