import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { Box } from "@mui/material";

interface ProfileYouTubeStreamProps {
  streamId?: string;
}

export const ProfileYouTubeStream = ({
  streamId,
}: ProfileYouTubeStreamProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();
  return (
    <Box
      width={"100%"}
      position={"relative"}
      minHeight={"60vh"}
      height={"100%"}
      display={"flex"}
      flexDirection={isDesktop ? "row" : "column"}
      alignItems={"stretch"}
      sx={(theme) => ({
        "& iframe": {
          minHeight: "25vh",
          minWidth: "300px",
          [theme.breakpoints.up("md")]: {
            minHeight: "60vh",
          },
        },
        "& iframe.chat": {
          minHeight: "max(325px, 25vh)",
          [theme.breakpoints.up("md")]: {
            minHeight: "60vh",
          },
        },
      })}
    >
      <iframe
        src={`https://www.youtube-nocookie.com/embed/${streamId}?autoplay=1`}
        title="Engineears Livestream"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        width={isDesktop ? "75%" : "100%"}
        height="100%"
      ></iframe>
      <iframe
        width={isDesktop ? "25%" : "100%"}
        className={"chat"}
        frameBorder="0"
        height="100%"
        src={`https://www.youtube.com/live_chat?v=${streamId}&embed_domain=${window.location.hostname}`}
      ></iframe>
    </Box>
  );
};
