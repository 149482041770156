import { styled, Box, Theme } from "@mui/material";
import { ScheduledProjectPaywallTypes } from "../../../store/models/project";
import { Text } from "../../core-ui/components/Text/Text";

const getBackgroundColor = (
  type: ScheduledProjectPaywallTypes,
  theme: Theme,
) => {
  if (type === ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED) {
    return theme.palette.standardColor.BoomyOrange[100];
  }
  if (type === ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED) {
    return theme.palette.standardColor.Green[100];
  }
  if (type === ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED) {
    return theme.palette.standardColor.DeepBlue[100];
  }
  return theme.palette.grey[100];
};

const getColor = (type: ScheduledProjectPaywallTypes, theme: Theme) => {
  if (type === ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED) {
    return theme.palette.standardColor.BoomyOrange[600];
  }
  if (type === ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED) {
    return theme.palette.standardColor.Green[600];
  }
  if (type === ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED) {
    return theme.palette.standardColor.DeepBlue[600];
  }
  return theme.palette.grey[800];
};

export const BookingLabelContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$type",
})<{ $type: ScheduledProjectPaywallTypes }>(({ theme, $type }) => ({
  padding: theme.spacing(1),
  width: "fit-content",
  borderRadius: "4px",
  height: "7px",
  visibility:
    $type === ScheduledProjectPaywallTypes.TRACK_PREVIEW_NOT_ALLOWED
      ? "hidden"
      : "visible",
  backgroundColor: getBackgroundColor($type, theme),
}));

export const BookingLabelText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "$type",
})<{ $type: ScheduledProjectPaywallTypes }>(({ theme, $type }) => ({
  color: `${getColor($type, theme)} !important`,
}));
