import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import { getResetCode, ResetCodeResponse } from "./getResetCode";

const useGetResetCodeQuery = (hasPassword?: boolean) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_RESET_CODE],
    queryFn: async (): Promise<ResetCodeResponse> => {
      const response = await getResetCode();
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson;
    },
    enabled: hasPassword === false,
  });
};

export default useGetResetCodeQuery;
