import { ScheduledProjectPaywallTypes } from "../../../store/models/project";
import { BookingLabelContainer, BookingLabelText } from "./BookingLabel.styles";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

const BookingLabelTextMap: {
  [key in ScheduledProjectPaywallTypes]: string;
} = {
  [ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED]:
    "Full platform collaboration",
  [ScheduledProjectPaywallTypes.TRACK_PREVIEW_NOT_ALLOWED]: "Standard",
  [ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED]: "Most popular",
  [ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED]:
    "Least friction",
};

interface BookingLabelProps {
  type: ScheduledProjectPaywallTypes;
}

export const BookingLabel = ({ type }: BookingLabelProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();
  if (
    type === ScheduledProjectPaywallTypes.TRACK_PREVIEW_NOT_ALLOWED &&
    !isDesktop
  ) {
    return null;
  }
  return (
    <BookingLabelContainer $type={type}>
      <BookingLabelText
        style={{ lineHeight: ".625rem" }}
        $type={type}
        bold
        variant={TextStyleVariant.P4}
      >
        {BookingLabelTextMap[type]}
      </BookingLabelText>
    </BookingLabelContainer>
  );
};
