import React, { ReactNode, useCallback, useState } from "react";
import { AutoCompleteTypeEnum } from "../../../store/models/autocomplete";
import { ProjectType } from "../../../store/models/project";
import { EntitySearchResultList } from "../../components/EntitySearchResultList/EntitySearchResultList";
import { SearchResultOverlay } from "../../components/SearchResultOverlay/SearchResultOverlay";
import "./SearchResultHOCScreen.css";
import useSearch from "../../../hooks/searchHooks/useSearch";
import { SearchQuickFilter } from "../../components/SearchFilters/SearchFilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faMap } from "@fortawesome/pro-solid-svg-icons";

interface SearchResultsHOCScreenProps {
  autoCompleteTypeFilter?: AutoCompleteTypeEnum;
  toggleFilters?: () => void;
  children?: ReactNode;
  placeholder?: string;
  showQuickFilters?: boolean;
  searchTerm?: string;
  updateSearchView?: () => void;
  mapView?: boolean;
  showResultsViewOptions?: boolean;
}

export const SearchResultsHOCScreen = ({
  children,
  autoCompleteTypeFilter,
  toggleFilters,
  placeholder,
  showQuickFilters = true,
  updateSearchView,
  mapView,
  showResultsViewOptions = true,
}: SearchResultsHOCScreenProps) => {
  const { isFetchingAutocomplete, autocompleteSuggestions, setSearchTerm } =
    useSearch({
      enabled: true,
      mapView,
    });
  const [isActive, setIsActive] = useState(false);

  const onListItemClick = useCallback(() => {
    setSearchTerm("");
  }, [setSearchTerm]);

  const userQuickFilters = [
    AutoCompleteTypeEnum.GENRE,
    AutoCompleteTypeEnum.ARTIST_CREDIT,
    AutoCompleteTypeEnum.USER,
    AutoCompleteTypeEnum.SERVICE_TYPE,
  ];

  return (
    <>
      <SearchResultOverlay
        placeholder={placeholder}
        inputfieldActive={isActive}
        toggleFilters={toggleFilters}
        setInputfieldActive={setIsActive}
        isEngineerSearch={autoCompleteTypeFilter === AutoCompleteTypeEnum.USER}
        isStudioSearch={autoCompleteTypeFilter === AutoCompleteTypeEnum.STUDIO}
        mapView={mapView}
      >
        <EntitySearchResultList
          loading={isFetchingAutocomplete}
          data={autocompleteSuggestions.filter((result) => {
            switch (autoCompleteTypeFilter) {
              case AutoCompleteTypeEnum.STUDIO:
                if (
                  result.type === AutoCompleteTypeEnum.SERVICE_TYPE &&
                  result.value === ProjectType.RECORDING
                ) {
                  return true;
                }
                return !userQuickFilters.includes(result.type);
              case AutoCompleteTypeEnum.USER:
                return result.type !== AutoCompleteTypeEnum.STUDIO;
              default:
                return true;
            }
          })}
          onListItemClick={onListItemClick}
        />
      </SearchResultOverlay>
      <div className="search-results-overlay-filter-container container">
        <div />
        <SearchQuickFilter
          showQuickFilters={showQuickFilters}
          isEngineerSearch={
            autoCompleteTypeFilter === AutoCompleteTypeEnum.USER
          }
        />
        {showResultsViewOptions ? (
          <div className="search-results-search-view-container">
            <div
              onClick={updateSearchView}
              className={"search-results-view".concat(
                !mapView ? " active-search-results-view" : "",
              )}
            >
              <FontAwesomeIcon icon={faList} color="var(--black)" />
              &nbsp;
              <span>List</span>
            </div>
            <div className="search-results-view">|</div>
            <div
              onClick={updateSearchView}
              className={"search-results-view".concat(
                mapView ? " active-search-results-view" : "",
              )}
            >
              <FontAwesomeIcon icon={faMap} color="var(--black)" />
              &nbsp;
              <span>Map</span>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="screen-wrapper">
        {children}
        <div
          className="screen-overlay"
          style={{
            display:
              isActive &&
              (Boolean(autocompleteSuggestions.length) ||
                isFetchingAutocomplete)
                ? "block"
                : "none",
          }}
        />
      </div>
    </>
  );
};
