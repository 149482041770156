import { Box, Button, styled } from "@mui/material";

export const HomeSearchForm = styled("form")<{
  $showAttachedDropDown?: boolean;
}>(({ theme, $showAttachedDropDown = false }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.border.radius.md,
  borderBottomLeftRadius: $showAttachedDropDown ? 0 : theme.border.radius.md,
  borderBottomRightRadius: $showAttachedDropDown ? 0 : theme.border.radius.md,
  boxSizing: "border-box",
  display: "flex",
  height: "44px",
  padding: theme.spacing(0.5),
  transition: $showAttachedDropDown
    ? "border-radius 0s"
    : "border-radius 1.2s ease-in-out",
  width: "100%",
}));

export const HomeSearchInput = styled("input")(({ theme }) => ({
  backgroundColor: "transparent",
  border: "none",
  boxSizing: "border-box",
  color: theme.palette.text.primary,
  fontSize: "1rem",
  height: "100%",
  outline: "none",
  padding: theme.spacing(0, 1),
  width: "100%",
}));

export const HomeSearchButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.customColor.homepageBrownishOrange,
  borderRadius: theme.border.radius.sm,
  minHeight: "unset",
  padding: "10px",
  ["&:hover"]: {
    backgroundColor: theme.palette.standardColor.BoomyOrange[900],
  },
}));

export const HomeSearchResults = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$showResultsDropDown",
})<{ $showResultsDropDown: boolean }>(({ theme, $showResultsDropDown }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottomLeftRadius: theme.border.radius.md,
  borderBottomRightRadius: theme.border.radius.md,
  boxShadow: theme.palette.customColor.homeSearchBoxShadow,
  mozBoxShadow: theme.palette.customColor.homeSearchBoxShadow,
  webkitBoxShadow: theme.palette.customColor.homeSearchBoxShadow,
  boxSizing: "border-box",
  overflow: "hidden",
  maxHeight: $showResultsDropDown ? "1500px" : "0",
  paddingLeft: theme.spacing(2),
  position: "absolute",
  transition: "max-height 0.5s ease-in-out",
  width: "100%",
  zIndex: 1,
  ["& > div"]: {
    margin: 0,
  },
}));
