import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const ProductCheckoutFooterContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  flexWrap: "wrap",
  marginTop: "auto",
  gap: "16px",
  padding: "24px",
  height: "44px",
});

export const ProductCheckoutFooterTextContainer = styled(Box)({
  gap: "8px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "35px",
});
