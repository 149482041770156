import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";
import { useEffect, useState } from "react";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import VerificationCodeInput from "./VerificationCodeInput";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { Box } from "@mui/material";

interface VerifyPhoneInputProps {
  phoneNumber?: string;
  handleVerifyPhone: (verificationCode: string) => Promise<boolean>;
  handleResendVerifyPhone: () => Promise<void>;
  handleDifferentPhoneNumber?: () => void;
  handleSubmit?: () => void;
}

export const VerifyPhoneInput = ({
  phoneNumber,
  handleVerifyPhone,
  handleResendVerifyPhone,
  handleDifferentPhoneNumber,
  handleSubmit = () => {},
}: VerifyPhoneInputProps) => {
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resentVerification, setResentVerification] = useState(false);

  useEffect(() => {
    if (resentVerification) {
      const timer = setTimeout(() => {
        setResentVerification(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [resentVerification]);

  const handleVerified = () => {
    setVerificationError(false);
    setPhoneVerified(true);
  };

  const handleVerificationError = () => {
    setPhoneVerified(false);
    setVerificationError(true);
  };

  const onVerifyPhone = async (verificationCode: string) => {
    setIsLoading(true);
    const success = await handleVerifyPhone(verificationCode);
    if (success) {
      handleVerified();
    } else {
      handleVerificationError();
    }
    setIsLoading(false);
  };

  const onResendVerifyPhone = async () => {
    setResendLoading(true);
    await handleResendVerifyPhone();
    setResendLoading(false);
  };

  const onSubmit = () => {
    if (phoneVerified) {
      handleSubmit();
    }
  };

  return (
    <Box>
      <Text variant={TextStyleVariant.H6}>Confirm your phone</Text>
      {phoneNumber && (
        <Text variant={TextStyleVariant.P1}>
          Enter the verification code we sent to:
          <br />
          {phoneNumber}
        </Text>
      )}
      {handleDifferentPhoneNumber && (
        <Button
          variant={ButtonVariant.TEXT}
          style={{ fontWeight: "600" }}
          onClick={handleDifferentPhoneNumber}
        >
          (Use a different phone)
        </Button>
      )}
      <VerificationCodeInput codeLength={6} onComplete={onVerifyPhone} />
      {verificationError && (
        <Text variant={TextStyleVariant.P1} color={TextColor.ERROR}>
          <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faXmark} />
          Invalid verification code
        </Text>
      )}
      {phoneVerified && (
        <Text variant={TextStyleVariant.P1} color={TextColor.SUCCESS}>
          <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
          Verified!
        </Text>
      )}
      {resendLoading ? (
        <SoundWaveLoader width={100} height={100} />
      ) : resentVerification ? (
        <Text variant={TextStyleVariant.P1} color={TextColor.SUCCESS}>
          <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
          Verification code resent
        </Text>
      ) : (
        <Button
          variant={ButtonVariant.TEXT}
          style={{ display: "inline", fontWeight: "600" }}
          onClick={onResendVerifyPhone}
          loading={isLoading}
        >
          Resend a code
        </Button>
      )}
      <Button
        loading={isLoading}
        fullWidth
        onClick={onSubmit}
        disabled={!phoneVerified || isLoading}
      >
        Submit
      </Button>
    </Box>
  );
};
