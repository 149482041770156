import { Box, SelectChangeEvent } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  SelectAMPMOptions,
  SelectHourInDayOptions,
} from "../../../store/models/alts";
import { OptionsDropdown } from "../../core-ui/components/MUIOptionsDropdown/MUIOptionsDropdown";
import { Text } from "../../core-ui/components/Text/Text";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { ServiceFormLabel } from "../ManageEngineeringService/ManageEngineeringService.styles";
import { WeekdayPicker } from "../WeekdayPicker/WeekdayPicker";
import {
  SelectWorkingHoursContainer,
  SelectWorkingHoursHeader,
} from "./SelectWorkingHourComponent.style";
import "./SelectWorkingHoursComponent.css";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import { WEEKDAYS } from "../../../store/models/workingHours";

// eslint-disable-next-line import/no-unused-modules
export interface SelectWorkingHoursComponentProps {
  weeklyWorkDates: WEEKDAYS[];
  setWeeklyWorkDates: Dispatch<SetStateAction<WEEKDAYS[]>>;
  startTime: OptionType<number>;
  endTime: OptionType<number>;
  startAMPM: OptionType<number>;
  endAMPM: OptionType<number>;
  setWeekdayTimes: Dispatch<
    SetStateAction<
      Record<
        number,
        {
          start: OptionType<number>;
          end: OptionType<number>;
          startAMPM: OptionType<number>;
          endAMPM: OptionType<number>;
        }
      >
    >
  >;
  weekdayTimes: Record<
    number,
    {
      start: OptionType<number>;
      end: OptionType<number>;
      startAMPM: OptionType<number>;
      endAMPM: OptionType<number>;
    }
  >;
}

export const SelectWorkingHoursComponent = ({
  weeklyWorkDates,
  setWeeklyWorkDates,
  startTime,
  endTime,
  startAMPM,
  endAMPM,
  weekdayTimes,
  setWeekdayTimes,
}: SelectWorkingHoursComponentProps) => {
  const [, setNextDay] = useState(false);

  useEffect(() => {
    if (startAMPM.value === 0 && endAMPM.value === 0) {
      if (startTime.value >= endTime.value) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }
    } else if (startAMPM.value === 1 && endAMPM.value === 1) {
      if (startTime.value >= endTime.value) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }
    } else if (startAMPM.value === 1 && endAMPM.value === 0) {
      setNextDay(true);
    } else {
      setNextDay(false);
    }
  }, [startAMPM, endAMPM, startTime, endTime]);

  const handleWeekdayTimeChange = (
    day: number,
    times: {
      start: OptionType<number>;
      end: OptionType<number>;
      startAMPM: OptionType<number>;
      endAMPM: OptionType<number>;
    },
  ) => {
    setWeekdayTimes((prevTimes) => ({ ...prevTimes, [day]: times }));
  };

  const selectedDaysCount = weeklyWorkDates.length;
  const dayText = selectedDaysCount === 1 ? "day" : "days";

  return (
    <SelectWorkingHoursContainer>
      <SelectWorkingHoursHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            gap: "4px",
          }}
        >
          <ServiceFormLabel>
            Select the days you are willing to book recording sessions and
            preferred time of availability:
          </ServiceFormLabel>
          <ToolTipTextArea
            text={
              "If there are time slots blocked off on your linked Google calendar " +
              "(e.g. event marked as Busy), clients will not be able to book services during those times"
            }
          />
        </Box>
        <Text variant={TextStyleVariant.P3} color={TextColor.SECONDARY}>
          ({selectedDaysCount} {dayText} selected)
        </Text>
      </SelectWorkingHoursHeader>
      <WeekdayPicker
        weekdaysInput={weeklyWorkDates}
        onChange={setWeeklyWorkDates}
        enableTimeSelection={true}
        weekdayTimes={weekdayTimes}
        onTimeChange={handleWeekdayTimeChange}
      />
    </SelectWorkingHoursContainer>
  );
};

export interface SelectHourInDayDropdownComponentProps {
  onHourChange?: (option: OptionType) => void;
  hour: OptionType;
  onTimeChange?: (option: OptionType) => void;
  time: OptionType;
}

export const SelectTimeInDayDropdownComponent = ({
  onHourChange,
  onTimeChange,
  hour,
  time,
}: SelectHourInDayDropdownComponentProps) => {
  return (
    <div className="select-time-drop-down-container">
      <OptionsDropdown
        style={{ flex: 1 }}
        options={SelectHourInDayOptions}
        value={hour.value}
        onChange={(e: SelectChangeEvent<number>) => {
          const {
            target: { value },
          } = e;
          const foundOption = SelectHourInDayOptions.find(
            (option) => option.value === value,
          );
          if (foundOption) {
            onHourChange?.(foundOption);
          }
        }}
      />

      <OptionsDropdown
        style={{ flex: 1 }}
        options={SelectAMPMOptions}
        value={time.value}
        onChange={(e: SelectChangeEvent<number>) => {
          const {
            target: { value },
          } = e;
          const foundOption = SelectAMPMOptions.find(
            (option) => option.value === value,
          );
          if (foundOption) {
            onTimeChange?.(foundOption);
          }
        }}
      />
    </div>
  );
};
