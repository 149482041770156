import { Box, Skeleton } from "@mui/material";
import { MadeOnEngineers } from "./MadeOnEngineers";
import { FeatureReleasePill } from "./FeaturedReleasePill";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

export const FeaturedArtistReleaseHeaderDetailsLoader = () => {
  const { isMobile } = useMediaQueryBreakpoint();
  return (
    <Box mb={2}>
      <Box
        sx={{
          display: "flex",
          gap: "24px",
          flexDirection: !isMobile ? "row" : "column-reverse",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Skeleton height={10} width={200} />
          <Skeleton height={10} width={200} />
          <Skeleton height={10} width={200} />
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}
        >
          <MadeOnEngineers />
          <FeatureReleasePill />
        </Box>
      </Box>
      <Skeleton height={10} />
      <Skeleton height={10} />
    </Box>
  );
};
