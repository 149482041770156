export enum EquipmentTypeEnum {
  NONE,
  HARDWARE,
  SOFTWARE,
  MONITORS,
  MICROPHONES,
}

export const EquipmentTypeReadableNameMap: Record<EquipmentTypeEnum, string> = {
  [EquipmentTypeEnum.NONE]: "None",
  [EquipmentTypeEnum.HARDWARE]: "Hardware",
  [EquipmentTypeEnum.SOFTWARE]: "Software & Plugins",
  [EquipmentTypeEnum.MONITORS]: "Studio Monitors",
  [EquipmentTypeEnum.MICROPHONES]: "Microphones",
};

export interface Equipment {
  id: number;
  label: string;
  created: string;
  deleted: null | string;
  equipment_type: EquipmentTypeEnum;
}
