import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PromoCode } from "../../../store/models/promoCode";
import "./PromoList.css";
import { DefaultSoundWaveLoader } from "../../elements/DefaultSoundWaveLoader/DefaultSoundWaveLoader";
import { ColorPalette } from "../../theme";
import {
  deletePromoCode,
  getProfilePromoCodes,
  PromoCodeUpdates,
} from "../../../store/actions/marketing";
import { CheckBox } from "../../elements/CheckBox/CheckBox";
import { formatDateToShort } from "../../../utils/formatDateToShort";
import delete_icon from "../../lotties/delete-trash-can.json";
import { faLink as linkIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import copyToClipboard from "copy-to-clipboard";
import { toast } from "react-toastify";
import { host } from "../../../store/utils/utils";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@mui/material";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import { useAtomValue, useSetAtom } from "jotai";
import { activeProfileAtom } from "../../../atoms/user/activeProfileAtom";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import {
  assetViewerModalAssetsAtom,
  ModalAssets,
} from "../GeneratedAssetViewerModal/atoms";
import {
  ProjectType,
  projectTypeReadableName,
} from "../../../store/models/project";
import { ProfileType } from "../../../store/models/base";

interface PromoListItemProps {
  promoCode: PromoCode;
}

export const options = {
  loop: false,
  autoplay: true,
  duration: 1000,
  animationData: delete_icon,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
// TOOD: make this more dynamic
const PROMO_CODE_ACTION_TYPES = ["square", "vertical"];

export const PromoListItem: FC<PromoListItemProps> = ({ promoCode }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const { isDesktop } = useMediaQueryBreakpoint();
  const activeProfile = useAtomValue(activeProfileAtom);
  const setAssets = useSetAtom(assetViewerModalAssetsAtom);
  const selectedStudioProfile = useAppSelector(
    (state) => state.selectedProfileSlice.studio,
  );
  const { isLoading, services } = useAppSelector(
    (state) => state.engineerServices,
  );
  const serviceTypes = useMemo(() => {
    return services.map((service) => service.service_type);
  }, [services]);
  const handleDelete = useCallback(() => {
    window.analytics.track(getDebugEventPrefix + "delete_custom_promo_code", {
      user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
    });
    void dispatch(
      deletePromoCode({
        code: promoCode.code,
        action: PromoCodeUpdates.delete,
        studio_id: selectedStudioProfile?.id,
      }),
    ).unwrap();
  }, [dispatch, promoCode.code, selectedStudioProfile?.id, user?.id]);

  const handleDownloadPromoCodeAssets = useCallback(() => {
    const serviceTypeList =
      activeProfile?.type === ProfileType.STUDIO
        ? [ProjectType.RECORDING]
        : [0, ...serviceTypes];
    const assets: ModalAssets = {};
    if (serviceTypeList.length) {
      serviceTypeList.forEach((serviceTypeCode) => {
        PROMO_CODE_ACTION_TYPES.forEach((actionType) => {
          const prefix = selectedStudioProfile?.id
            ? "Studio Time"
            : projectTypeReadableName.get(serviceTypeCode) || "Engineering";
          assets[`${prefix} - ${actionType}`] = {
            key: actionType,
            promoCodeId: promoCode.id.toString(),
            studioId: selectedStudioProfile?.id.toString(),
            serviceType: serviceTypeCode,
          };
        });
      });
    } else {
      PROMO_CODE_ACTION_TYPES.forEach((actionType) => {
        assets[`Promo Code Asset(s) - ${actionType}`] = {
          key: actionType,
          promoCodeId: promoCode.id.toString(),
          studioId: selectedStudioProfile?.id.toString(),
        };
      });
    }

    setAssets({
      [promoCode.code]: assets,
    });
  }, [
    setAssets,
    promoCode,
    selectedStudioProfile?.id,
    activeProfile?.type,
    serviceTypes,
  ]);
  return (
    <div className="engineer-promo-list-item">
      <div className="engineer-promo-list-item-column">
        <p className={"b1"}>
          {promoCode.expires_at
            ? formatDateToShort(new Date(promoCode.expires_at))
            : null}
        </p>
      </div>
      <div className="engineer-promo-list-item-column promo-code-name-container">
        <p className="label2-semi-bold">{promoCode.code}</p>
      </div>
      <div className="engineer-promo-list-item-column">
        <p className={"b1"}>
          {`${Math.floor(+promoCode.discount_percentage * 100)}%`}
        </p>
      </div>
      {isDesktop && (
        <div className="engineer-promo-list-item-column promo-check-box-column">
          <CheckBox label={""} isSelected={!promoCode.owner} />
        </div>
      )}
      <div className="engineer-promo-list-item-column promo-check-box-column">
        <FontAwesomeIcon
          onClick={() => {
            if (promoCode.studio) {
              copyToClipboard(
                `${host}${getStudioScreenRoute(selectedStudioProfile?.username ?? "")}?promocode=${promoCode.code}`,
              );
            } else {
              copyToClipboard(
                `${host}${getProfileScreenRoute(user?.username ?? "")}/?promocode=${promoCode.code}`,
              );
            }

            toast.success("Copied promocode share link to clipboard");
          }}
          className="promo-code-link-icon"
          icon={linkIcon}
          style={{
            color: ColorPalette.DarkGrey,
          }}
          size="lg"
        />
      </div>
      <Box gap={2} className="engineer-promo-list-item-column actions" px={2}>
        <Button
          variant={ButtonVariant.ICON}
          onClick={handleDownloadPromoCodeAssets}
        >
          <FontAwesomeIcon
            icon={faDownload}
            title={"Download Promotional Asset(s)"}
          />
          {/*<DownloadPromotionalAssetButton*/}
          {/*  asset={`promo_code:${promoCode.id}`}*/}
          {/*  serviceType={*/}
          {/*    activeProfile?.type === ProfileType.STUDIO*/}
          {/*      ? ProjectType.RECORDING*/}
          {/*      : isLoading*/}
          {/*        ? undefined*/}
          {/*        : serviceTypes.length === 1*/}
          {/*          ? serviceTypes[0]*/}
          {/*          : undefined*/}
          {/*  }*/}
          {/*  isLoading={isLoading}*/}
          {/*  studioId={*/}
          {/*    activeProfile?.type === ProfileType.STUDIO*/}
          {/*      ? activeProfile.id.toString()*/}
          {/*      : undefined*/}
          {/*  }*/}
          {/*/>*/}
        </Button>
        <div className="delete-promocode-button" style={{ minWidth: "16px" }}>
          {(promoCode.owner !== null || promoCode.studio) && (
            <PopConfirm
              title={"Are you sure you want to delete this promo code?"}
              onConfirm={handleDelete}
              description={"This action cannot be undone!"}
            >
              <Button variant={ButtonVariant.ICON} title="delete promo code">
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
            </PopConfirm>
            // <button onClick={handleDelete}>
            //   <Lottie options={options} height={25} width={25} />
            // </button>
          )}
        </div>
      </Box>
    </div>
  );
};

export const PromoList = () => {
  const dispatch = useAppDispatch();
  const {
    promoCodes,
    loading,
    campaignsOptedIn,
    competitionsOptedIn,
    optedIntoUnverifiedEngineerPromo,
  } = useAppSelector((state) => state.marketingDataStore);
  // add promo code
  const selectedStudioProfile = useAppSelector(
    (state) => state.selectedProfileSlice.studio,
  );

  const { isDesktop } = useMediaQueryBreakpoint();
  const [didScroll, setDidScroll] = useState(false);
  const promoRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!didScroll) {
      if (window.location.hash === "#promo-codes") {
        setTimeout(() => {
          if (promoRef?.current) {
            setDidScroll(true);
            promoRef.current.scrollIntoView({
              behavior: "auto",
              inline: "start",
              block: "start",
            });
          }
        }, 600); // needs a longer timeout because other cards grow
      }
    }
  }, [didScroll, setDidScroll, promoRef]);

  useEffect(() => {
    void dispatch(
      getProfilePromoCodes({
        studio_id: selectedStudioProfile?.id,
      }),
    ).unwrap();
  }, [
    campaignsOptedIn,
    competitionsOptedIn,
    optedIntoUnverifiedEngineerPromo,
    dispatch,
    selectedStudioProfile,
  ]);

  return (
    <div className="engineer-promo-list-container" ref={promoRef}>
      <div className="engineer-promo-list-item">
        <div className="engineer-promo-list-item-column">
          <p className="b1-semi-bold">Expires on</p>
        </div>
        <div className="engineer-promo-list-item-column">
          <p className="b1-semi-bold">Name</p>
        </div>
        <div className="engineer-promo-list-item-column">
          <p className="b1-semi-bold">Discount</p>
        </div>
        {isDesktop && (
          <div className="engineer-promo-list-item-column">
            <p className="b1-semi-bold">Platform Promotion</p>
          </div>
        )}
        <div className="engineer-promo-list-item-column">
          <p className="b1-semi-bold">Share</p>
        </div>
        <div className="engineer-promo-list-item-column"></div>
      </div>
      <div className="engineer-promo-list-container scrollable">
        {!loading &&
          promoCodes.map((promoCode) => {
            return <PromoListItem key={promoCode.id} promoCode={promoCode} />;
          })}
        {loading && (
          <div className="no-promo-code-view">
            <DefaultSoundWaveLoader />
          </div>
        )}
        {!loading && !promoCodes.length && (
          <div className="no-promo-code-view">
            <div>No active promo codes</div>
          </div>
        )}
      </div>
    </div>
  );
};
