import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Ref, forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import {
  FinancialMethod,
  Transaction,
} from "../../../store/models/transaction";
import CardSection from "./CardSection";

import { useAccountStatus } from "../../../hooks/accountHooks";
import useInvalidateOnboardingProgress from "../../../hooks/onboardingHooks/useInvalidateOnboardingProgress";
import { updateProjectCounts } from "../../../store/actions/projects";
import {
  markRecordingSessionExtensionPaid,
  markRevisionTransactionPaid,
  markTransactionPaid,
} from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  ContactInfoModal,
  MISSING_PHONE_NUMBER_MESSAGE,
} from "../ContactInfoModal/ContactInfoModal";
import { UnauthenticatedModal } from "../UnauthenticatedModal/UnauthenticatedModal";
import "./StripePaymentForm.css";
import { getPaymentRedirectURL } from "../../../store/utils/routeGetters";

export interface StripePaymentFormProps {
  transaction: Transaction;
  projectTitle?: string;
  artistName?: string;
  scheduledProjectIdForHandoff?: number;
  isRevisionPurchase?: boolean;
  isSessionExtensionPurchase?: boolean;
  checkPhoneNumber?: boolean;
}

export interface StripePaymentFormHandles {
  handleSubmit: () => Promise<void>;
}

export const StripePaymentForm = forwardRef<
  StripePaymentFormHandles,
  StripePaymentFormProps
>(
  (
    {
      projectTitle,
      scheduledProjectIdForHandoff,
      transaction,
      isRevisionPurchase,
      isSessionExtensionPurchase,
      artistName,
      checkPhoneNumber,
    },
    ref: Ref<StripePaymentFormHandles>,
  ) => {
    const { missingPhoneNumber } = useAccountStatus();
    const { user, isAuthenticated } = useAppSelector(
      (state) => state.accountInfo,
    );
    const [showUnauthenticatedModal, setShowUnauthenticatedModal] =
      useState(false);
    const [showMissingPhoneNumberModal, setShowMissingPhoneNumberModal] =
      useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [selectedFinancialMethod, setSelectedFinancialMethod] =
      useState<FinancialMethod>(transaction.financial_method);
    const dispatch = useAppDispatch();
    const errorMessages = {
      default:
        "Something went wrong. Please try again or reach out to customer support.",
      financial: "Please select a financial method.",
    };
    const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();

    const urlEncodedProjectTitle = useMemo(
      () => encodeURIComponent(projectTitle ?? "Untitled"),
      [projectTitle],
    );

    const handleSubmit = async () => {
      if (!isAuthenticated) {
        setShowUnauthenticatedModal(true);
        throw new Error("Please log in to continue");
      }
      if (missingPhoneNumber && checkPhoneNumber) {
        setShowMissingPhoneNumberModal(true);
        throw new Error("Please add a phone number to your account");
      }
      if (!stripe || !elements) {
        throw new Error("Invalid stripe or elements instance");
      }

      const redirectUrl = getPaymentRedirectURL(selectedFinancialMethod, {
        transaction_id: `${transaction.id}`,
        transaction_code: transaction.code,
        project_title: urlEncodedProjectTitle,
        scheduled_project_id: `${scheduledProjectIdForHandoff}`,
      });

      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message ?? errorMessages.default);
      }
      const clientSecret = transaction.stripe_session_id;
      if (!clientSecret) {
        throw new Error(errorMessages.default);
      }
      // Use the clientSecret and Elements instance to confirm the setup
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: redirectUrl,
        },
        redirect: "if_required",
      });

      if (error) {
        throw new Error(error.message ?? errorMessages.default);
      }
      if (selectedFinancialMethod === FinancialMethod.CASH_APP && isMobile) {
        return;
      }
      if (isRevisionPurchase) {
        emitAnalyticsTrackingEvent(
          "mark_paid_revision",
          {
            transaction_id: `${transaction.id}`,
            value: transaction.total_price ?? 0,
          },
          user?.id,
        );
        dispatch(
          markRevisionTransactionPaid({
            transaction_id: transaction.id,
          }),
        )
          .unwrap()
          .then((project) => {
            dispatch(updateProjectCounts(project));
          })
          .catch((error) => {
            if (error instanceof Error) {
              toast.error(error.message);
            }
          });
      } else if (isSessionExtensionPurchase) {
        emitAnalyticsTrackingEvent(
          "mark_paid_session_extension",
          {
            transaction_id: `${transaction.id}`,
            value: transaction.total_price ?? 0,
          },
          user?.id,
        );
        try {
          await dispatch(
            markRecordingSessionExtensionPaid({
              transaction_id: transaction.id,
            }),
          ).unwrap();
          toast.success(
            "You have requested a extension. The involved clients will be notified and will need to approve the request.",
          );
        } catch (error) {
          toast.error(
            "Something went wrong. Please reach out to customer support.",
          );
        }
      } else {
        emitAnalyticsTrackingEvent(
          "mark_paid_scheduled_project",
          {
            transaction_id: `${transaction.id}`,
            value: transaction.total_price ?? 0,
          },
          user?.id,
        );

        await dispatch(
          markTransactionPaid({
            transaction_id: transaction.id,
            title: projectTitle,
            artist_name: artistName,
            booked_with_purchase_order: false,
            financial_method: selectedFinancialMethod,
          }),
        ).unwrap();
        await invalidateOnboardingProgress();
      }
    };

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    if (!transaction.stripe_session_id) {
      return null;
    }
    if (transaction.financial_method === FinancialMethod.PAYPAL) {
      return null;
    }

    return (
      <>
        <CardSection
          handlePaymentTypeChange={(updatedFinancialMethod) => {
            setSelectedFinancialMethod(updatedFinancialMethod);
          }}
        />
        {showMissingPhoneNumberModal && (
          <ContactInfoModal
            disableDismiss={missingPhoneNumber}
            overwriteShowModal={true}
            customHeading={MISSING_PHONE_NUMBER_MESSAGE}
          />
        )}
        <UnauthenticatedModal
          showModal={showUnauthenticatedModal}
          closeModal={() => {
            setShowUnauthenticatedModal(false);
          }}
          message={"You need to be logged in to book your project."}
        />
      </>
    );
  },
);

StripePaymentForm.displayName = "StripePaymentForm";
