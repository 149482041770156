import React, { useMemo } from "react";
import { TabsProps, useMediaQuery } from "@mui/material";
import { StyledTab, StyledTabs } from "./Tabs.styles";
import { Breakpoint } from "../../../utils/breakpoints";

export interface TabsParams extends Omit<TabsProps, "children"> {
  tabTitles?: Record<string, React.ReactNode>;
}

/**
 * @deprecated Please use TabsV2 instead
 */
const Tabs = ({ tabTitles, ...props }: TabsParams) => {
  const isDesktop = useMediaQuery(Breakpoint.Desktop);
  const tabListContent = useMemo(() => {
    const safeTabTitles = tabTitles || {};
    return Object.keys(safeTabTitles).map((key) => {
      const kid = safeTabTitles[key];
      return <StyledTab wrapped disableRipple key={key} label={kid} />;
    });
  }, [tabTitles]);
  return (
    <StyledTabs
      className="container"
      selectionFollowsFocus={true}
      variant={isDesktop ? "standard" : "fullWidth"}
      {...props}
    >
      {tabListContent}
    </StyledTabs>
  );
};

export default Tabs;
