import { styled, Box } from "@mui/material";

export const PaywallPermissionsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isHidden",
})<{ $isHidden: boolean }>(({ theme, $isHidden }) => ({
  display: "flex",
  padding: $isHidden ? "0px" : theme.spacing(1.5),
  boxSizing: "border-box",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1),
  alignSelf: "stretch",
  borderRadius: "0 0 8px 8px",
  borderTop: $isHidden ? "none" : `1px solid ${theme.palette.grey[100]}`,
  maxHeight: $isHidden ? "0px" : "400px",
  overflowY: "hidden",
  transition: "max-height .3s",
  background: theme.palette.customColor.backgroundColor,
}));

export const PaywallPermissionsDetailsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  alignSelf: "stretch",
}));

export const PaywallPermissionsDetailContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "fit-content",
  gap: theme.spacing(1),
}));
