import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useQueryClient } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import { CSSProperties, useMemo } from "react";
import { useTrackOrderUpdateMutation } from "../../../api/releases/hooks/useTrackOrderUpdateMutation";
import { editMode } from "../../../atoms/profileScreenEdit";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { usePlayPauseOnFooter } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import useModal from "../../../hooks/useModal";
import { updatePlayListOrder } from "../../../store/actions/abPlayerStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { convertArtistReleaseToPlaylistTrack } from "../../../store/models/playListTrack";
import { ReleaseTrack } from "../../../store/models/release";
import { TableRow } from "../../elements/TableElements/TableRow";
import { FeedbackModal } from "../FeedbackModal/FeedbackModal";
import {
  columnDefinitionForReleaseTracks,
  getColumnSize,
  RELEASE_TRACK_MOBILE_TABLE_COLUMNS,
  ReleaseTrackTableRowId,
} from "./defaultColumnsForReleaseTrackTable";
import { ArtistReleaseTableHeader, ArtistReleaseTableWrapper } from "./styles";

interface ReleaseTrackTableProps {
  releaseTracks: ReleaseTrack[];
  artistUserId?: number;
  unitPrice?: string | null;
  selectedProductId?: number;
  isArtistOnRelease?: boolean;
  lowestUnitPriceProductId?: number;
  productToGetPriceFromId?: number;
}

const DraggableArtistReleaseTrackTableRow = ({
  row,
  unitPrice,
  selectedProductId,
  isArtistOnRelease,
  openFeedbackModal,
}: {
  row: Row<ReleaseTrack>;
  unitPrice?: string | null;
  selectedProductId?: number;
  isArtistOnRelease?: boolean;
  openFeedbackModal?: () => void;
}) => {
  const { isMobile } = useMediaQueryBreakpoint();
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
    justifyContent: "flex-start",
    flexDirection: "row",
  };
  return (
    <TableRow ref={setNodeRef} style={style} key={row.id}>
      {row.getVisibleCells().map((cell) => {
        const sizeForCell = getColumnSize(
          cell.column.columnDef.id as ReleaseTrackTableRowId,
          Boolean(isArtistOnRelease),
          isMobile,
          cell.column.columnDef.size ?? 0,
          unitPrice,
        );
        if (!sizeForCell) {
          return null;
        }
        const isPlaySection =
          cell.column.columnDef.id === ReleaseTrackTableRowId.PlaySection;
        return (
          <td
            key={cell.id}
            style={{
              padding: "16px",
              width: sizeForCell,
              marginLeft: isMobile && isPlaySection ? "auto" : undefined,
            }}
          >
            {flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              unitPrice,
              selectedProductId,
              isArtistOnRelease,
              openFeedbackModal,
            })}
          </td>
        );
      })}
    </TableRow>
  );
};

export const ArtistReleaseTrackTable = ({
  releaseTracks,
  artistUserId,
  unitPrice,
  productToGetPriceFromId,
  lowestUnitPriceProductId,
}: ReleaseTrackTableProps) => {
  const {
    isOpen: isFeedbackModalOpen,
    openModal: openFeedbackModal,
    closeModal: closeFeedbackModal,
  } = useModal();
  const queryClient = useQueryClient();
  const { isFooterPlaying, currentTrackIndex, playlist } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const isEditMode = useAtomValue(editMode);
  const dispatch = useAppDispatch();
  const { handleClick: pauseFooterPlayer } = usePlayPauseOnFooter();
  const { mutate: updateTrackOrder } = useTrackOrderUpdateMutation();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const { isMobile } = useMediaQueryBreakpoint();
  const columns = useMemo(() => {
    let result = columnDefinitionForReleaseTracks;
    if (isMobile) {
      result = result.filter(
        (column) =>
          RELEASE_TRACK_MOBILE_TABLE_COLUMNS.includes(
            column.id as ReleaseTrackTableRowId,
          ) || column.id === ReleaseTrackTableRowId.ReorderTab,
      );
    }

    if (
      productToGetPriceFromId !== undefined &&
      lowestUnitPriceProductId !== undefined &&
      productToGetPriceFromId !== lowestUnitPriceProductId
    ) {
      result = result.filter(
        (column) => column.id !== ReleaseTrackTableRowId.Price,
      );
    }

    return result;
  }, [isMobile, lowestUnitPriceProductId, productToGetPriceFromId]);

  const isArtistOnRelease = useMemo(() => {
    return loggedInUser?.id === artistUserId;
  }, [loggedInUser, artistUserId]);

  const table = useReactTable({
    data: releaseTracks,
    columns:
      isArtistOnRelease && isEditMode
        ? columns
        : columns.filter(
            (column) => column.id !== ReleaseTrackTableRowId.ReorderTab,
          ),

    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
  });

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => releaseTracks?.map(({ id }) => id),
    [releaseTracks],
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    // Find active and over release tracks
    const activeRelease = releaseTracks.find(
      (releaseTrack) => releaseTrack.id === active.id,
    );
    const overRelease = releaseTracks.find(
      (releaseTrack) => releaseTrack.id === over?.id,
    );

    // Early return if either release is not found
    if (!activeRelease || !overRelease) {
      return;
    }

    const activeId = activeRelease.id;
    const overId = overRelease.id;

    // Find the indexes of active and over releases
    const activeIndex = releaseTracks.findIndex(
      (releaseTrack) => releaseTrack.id === activeId,
    );
    const overIndex = releaseTracks.findIndex(
      (releaseTrack) => releaseTrack.id === overId,
    );

    // Update the track number in the release tracks
    const updatedData = releaseTracks.map((releaseTrack) => {
      if (releaseTrack.id === overId) {
        return { ...releaseTrack, track_number: activeRelease.track_number };
      } else if (releaseTrack.id === activeId) {
        return { ...releaseTrack, track_number: overRelease.track_number };
      }
      return releaseTrack;
    });

    // Move elements in the array to the sorted positions
    const sortedData = arrayMove(updatedData, activeIndex, overIndex);

    // Update query cache with new sorted data
    queryClient.setQueryData(
      [QUERY_KEYS.FETCH_ARTIST_RELEASE_TRACKS, activeRelease.release.id],
      sortedData,
    );

    // Determine new current track index
    let newCurrentIndex;
    if (currentTrackIndex === overIndex) {
      newCurrentIndex = activeIndex;
    } else if (currentTrackIndex === activeIndex) {
      newCurrentIndex = overIndex;
    }

    // Dispatch new playlist order to Redux
    dispatch(
      updatePlayListOrder({
        playlist: convertArtistReleaseToPlaylistTrack(sortedData),
        currentTrackIndex: newCurrentIndex,
      }),
    );

    // Update the track order server-side
    updateTrackOrder({
      releaseId: activeRelease.release.id,
      releaseTrackIds: [activeId, overId],
      //  OnError sets state back to before optimistic updates.
      previousState: {
        currentTrackIndex,
        playlist,
      },
    });
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragStart={() => {
          if (isFooterPlaying) {
            pauseFooterPlayer();
          }
        }}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <ArtistReleaseTableWrapper>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow $disabledHover={true} key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const sizeForHeader = getColumnSize(
                    header.column.id as ReleaseTrackTableRowId,
                    Boolean(isArtistOnRelease),
                    isMobile,
                    header.column.columnDef.size ?? 0,
                    unitPrice,
                  );
                  if (!sizeForHeader) {
                    return null;
                  }
                  return (
                    <ArtistReleaseTableHeader
                      style={{
                        width: sizeForHeader,
                        padding: "16px",
                      }}
                      key={header.id}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </ArtistReleaseTableHeader>
                  );
                })}
              </TableRow>
            ))}
          </thead>
          <tbody>
            <SortableContext
              items={dataIds}
              strategy={verticalListSortingStrategy}
            >
              {table.getRowModel().rows.map((row) => {
                return (
                  <DraggableArtistReleaseTrackTableRow
                    key={row.id}
                    row={row}
                    unitPrice={unitPrice}
                    isArtistOnRelease={isArtistOnRelease}
                    selectedProductId={productToGetPriceFromId}
                    openFeedbackModal={openFeedbackModal}
                  />
                );
              })}
            </SortableContext>
          </tbody>
        </ArtistReleaseTableWrapper>
      </DndContext>
      {isFeedbackModalOpen && <FeedbackModal onClose={closeFeedbackModal} />}
    </>
  );
};
