import { PhotoFileType } from "../../../store/models/entityPhoto";
import { VideoFileType } from "../../../store/models/entityVideo";

export const ACCEPTED_IMAGE_UPLOAD_TYPE = "image/png, image/jpeg, image/jpg";
export const COVER_PHOTO_ASPECT_MOBILE = 1.01;
export const COVER_PHOTO_ASPECT_TABLET = 1.78;
export const COVER_PHOTO_ASPECT_DESKTOP = 3.75;
export const PROFILE_PHOTO_ASPECT = 1;

export interface PhotoDimension {
  width: number;
  height: number;
}

export const COVER_PHOTO_DIMENSION_MOBILE = {
  width: 1125,
  height: 1113,
};

export const COVER_PHOTO_DIMENSION_DESKTOP = {
  width: 1800,
  height: 480,
};

export const PROFILE_PHOTO_DIMENSION = {
  width: 320,
  height: 320,
};

export const RELEASE_COVER_DIMENSION = {
  width: 1024,
  height: 1024,
};

export enum FormType {
  EDIT = "edit",
  CREATE = "create",
}

export const IDLE_PROGRESS = -1;
export const UPLOADED_PROGRESS = 100;

export enum MediaTypes {
  CoverPhoto = "cover-photo",
  FeaturedVideo = "featured-video",
}

export const mediaUploadAcceptedFileTypes = {
  [MediaTypes.CoverPhoto]: [PhotoFileType.JPEG, PhotoFileType.JPG],
  [MediaTypes.FeaturedVideo]: [VideoFileType.MP4, VideoFileType.MOV],
};

export const PHOTO_FILE_TYPE_PREFIX = "image";
export const VIDEO_FILE_TYPE_PREFIX = "video";
