/* eslint-disable import/no-unused-modules */
import EntityPhoto from "../../../store/models/entityPhoto";
import {
  BaseModal,
  BaseModalProps,
} from "../../core-ui/components/BaseModal/BaseModal";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import {
  ProductModalContent,
  ProductModalContentProps,
} from "./ProductModalContent";

export interface ProductDetailsModalWrapperProps
  extends Omit<BaseModalProps, "children"> {
  releaseId: number;
  coverPhoto: EntityPhoto | null;
  name: string;
  description: string;
  totalPrice: string;
  productId: number;
  options: OptionType[];
  productPurchasedByCurrentUser: boolean;
  onChangeProduct: (productId: number) => void;
  availableQuantity?: number | null;
  purchaseLimit?: number | null;
}

interface ProductDetailsModalProps
  extends ProductDetailsModalWrapperProps,
    Omit<ProductModalContentProps, "onErrorFetchingData"> {}

export const ProductDetailsModal = ({
  name,
  description,
  coverPhoto,
  totalPrice,
  productId,
  options,
  productPurchasedByCurrentUser,
  onChangeProduct,
  availableQuantity,
  purchaseLimit,
  releaseDetails,
  releaseTracks,
  ...restProps
}: ProductDetailsModalProps) => {
  return (
    <BaseModal
      {...restProps}
      customModalStyle={{
        boxSizing: "border-box",
        maxWidth: "1230px",
        width: "95%",
        padding: 0,
      }}
      style={{
        width: "100%",
        maxWidth: "unset",
      }}
      modalBodyStyle={{
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        padding: 0,
        overflowY: "auto",
        maxHeight: "60vh",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <ProductModalContent
        onErrorFetchingData={() => restProps.setOpen(false)}
        coverPhoto={coverPhoto}
        name={name}
        description={description}
        totalPrice={totalPrice}
        onChangeProduct={onChangeProduct}
        productId={productId}
        options={options}
        releaseDetails={releaseDetails}
        productPurchasedByCurrentUser={productPurchasedByCurrentUser}
        releaseTracks={releaseTracks}
        availableQuantity={availableQuantity}
        purchaseLimit={purchaseLimit}
      />
    </BaseModal>
  );
};
