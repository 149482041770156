import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { RESET_CODE } from "../../store/utils/routes";

export interface ResetCodeResponse {
  reset_code: string;
}

export const getResetCode = async () => {
  return await makeBackendGetCallWithJsonResponse<ResetCodeResponse>(
    RESET_CODE,
    "",
  );
};
