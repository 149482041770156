import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { ReactNode } from "react";
import { Text } from "../../../core-ui/components/Text/Text";
import { SuccessIcon, TabTextContainer } from "../ProfileScreen.styles";

export enum ModalTab {
  ServiceDetails = "service-details",
  SchedulingPreferences = "scheduling-preferences",
  ServiceDescription = "service-description",
  ServiceMedia = "service-media",
}

interface TabPanelProps {
  children?: ReactNode;
  index: ModalTab;
  value: ModalTab;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ alignSelf: "stretch" }}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

export const ServiceTabLabel = ({
  isTabReady,
  label,
}: {
  isTabReady: boolean;
  label: string;
}) => {
  return (
    <TabTextContainer>
      <Text>{label}</Text>
      {isTabReady && <SuccessIcon icon={faCheck} />}
    </TabTextContainer>
  );
};
