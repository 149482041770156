import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import { EngineEarsError } from "../../store/actions/errorStore";
import {
  getGuestVerificationCode,
  GetGuestVerificationCodeParams,
} from "../../api/auth/getGuestVerificationCode";

interface GuestCheckoutGetResponse {
  created: boolean;
}
const useGetGuestVerificationCodeMutation = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS.LOGIN_GUEST_VERIFICATION_CODE],
    mutationFn: async (
      params: GetGuestVerificationCodeParams,
    ): Promise<GuestCheckoutGetResponse> => {
      emitAnalyticsTrackingEvent("guest_login_get_code", {
        email: params.email,
        phoneNumber: params.phone_number,
      });
      const result = await getGuestVerificationCode(params);
      if (!result.success) {
        throw result.resultJson;
      }
      return result.resultJson as GuestCheckoutGetResponse;
    },
    // If we handle the error as part of the mutation function, it can show the toast numerous times.
    // Our custom errors are technically valid responses
    onSuccess: (result) => {
      if (assertEngineEarsErrorType(result)) {
        return Promise.reject(result);
      }
      return result;
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      return error;
    },
  });
};

export default useGetGuestVerificationCodeMutation;
