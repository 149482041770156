import { Box, styled } from "@mui/material";

export const ColoredSpan = styled("span")<{ $backgroundColor: string }>(
  ({ theme, $backgroundColor }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    padding: 8,
    height: 14,
    borderRadius: theme.border.radius.md,
    backgroundColor: $backgroundColor,
    minWidth: 91,
  }),
);

export const FeaturedArtistReleasePhotoViewContainer = styled(Box)(
  ({ theme }) => ({
    width: "100%",
    aspectRatio: 1,
    boxSizing: "border-box",

    [theme.breakpoints.down("sm")]: {
      padding: "0 46px",
      aspectRatio: "unset",
    },
  }),
);
