import { styled } from "styled-components";
import { Text } from "../../../core-ui/components/Text/Text";

export const StyledFooterProjectInfoContainer = styled.div<{
  $expandFooter?: boolean;
}>`
  background-color: inherit;
  display: inline-flex;
  align-items: center;
  gap: ${({ $expandFooter }) => ($expandFooter ? "unset" : "12px")};
  height: ${({ $expandFooter }) =>
    $expandFooter ? "calc(100% - 26px)" : "54px"};
  width: calc(100% - 32px);
  cursor: pointer;
  flex-direction: ${({ $expandFooter }) => ($expandFooter ? "column" : "row")};
  padding: ${({ $expandFooter }) => ($expandFooter ? "16px" : "unset")};
`;

export const StyledFooterProjectInfoImage = styled.img`
  color: ${({ theme }) => theme.colorPalette.White};
  border-radius: 8px;
`;

export const StyledFooterProjectInfoTrackInfo = styled.div<{
  $expandFooter?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ $expandFooter }) => ($expandFooter ? "24px" : "unset")};
  height: 100%;
  justify-content: ${({ $expandFooter }) =>
    $expandFooter ? "center" : "space-between"};
  align-items: ${({ $expandFooter }) =>
    $expandFooter ? "center" : "flex-start"};
  overflow-x: hidden;
  width: ${({ $expandFooter }) => ($expandFooter ? "100%" : "200px")};
`;

export const StyledFooterProjectInfoTrackTitle = styled(Text)<{
  $expandFooter?: boolean;
}>`
  color: ${({ theme }) => theme.colorPalette.White};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  margin-bottom: ${({ $expandFooter }) => ($expandFooter ? "12px" : "unset")};
  white-space: nowrap;
  width: 100%;
`;

export const StyledFooterProjectInfoSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledFooterProjectInfoProjectDetails = styled(
  StyledFooterProjectInfoTrackInfo,
)`
  align-items: flex-start;
  justify-content: flex-start;
  width: unset;
  & > a {
    text-decoration: none;
  }
`;

export const StyledFooterProjectInfoUserInfo = styled(Text)<{
  $isUserInfo?: boolean;
  $expandFooter?: boolean;
}>`
  color: ${({ theme, $isUserInfo, $expandFooter }) => {
    if ($expandFooter) {
      return theme.colorPalette.Gray400;
    }
    if ($isUserInfo) {
      return theme.colorPalette.White;
    }
    return theme.colorPalette.Gray400;
  }};
  text-wrap: nowrap;
  font-weight: ${({ theme, $isUserInfo }) =>
    $isUserInfo ? theme.textWeightSemibold : theme.textWeightNormal};
  text-align: ${({ $expandFooter }) => ($expandFooter ? "center" : "unset")};
`;
