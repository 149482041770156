import { useAtomValue } from "jotai";
import { productCartAtom } from "../cart";
import { productPriceDetailsAtom } from "../productPriceDetails";

export const useProductCartTotals = () => {
  const cart = useAtomValue(productCartAtom);
  const productPriceDetails = useAtomValue(productPriceDetailsAtom);

  if (!productPriceDetails || Object.keys(productPriceDetails).length === 0) {
    return [0, 0];
  }

  const totalCost = cart.reduce((acc, item) => {
    if (!productPriceDetails) return 0;
    if (item.use_total_price) {
      return acc + productPriceDetails[item.product_id]?.totalPrice || 0;
    } else {
      const cost =
        item.release_track_ids.length *
        productPriceDetails[item.product_id].unitPrice;
      return acc + cost;
    }
  }, 0);

  const songsInCart = cart.reduce(
    (acc, item) => acc + item.release_track_ids?.length ?? 0,
    0,
  );
  return [totalCost, songsInCart] as const;
};
