import { ReactNode, useCallback, useEffect, useState } from "react";
import { useNetwork } from "ahooks";
import { getWsRootUrl } from "../../../utils/utils";
import ErrorBoundary from "./ErrorBoundary";
import { VERSION } from "../../../constants/generated";
import { useAppSelector } from "../../../store/hooks";
import { useAtom } from "jotai";
import listenWebsocketAtom from "../../../atoms/websockets/listenWebsocketAtom";

interface OnlineHeartbeatProps {
  children: ReactNode;
}

const triggerWebsocket = (websocket: WebSocket) => {
  let token = localStorage.getItem("token");
  if (token && token.startsWith('"')) {
    try {
      const parsedToken = JSON.parse(token);
      if (parsedToken) {
        token = parsedToken;
      }
    } catch {
      // Do Nothing
    }
  }
  websocket.send(
    JSON.stringify({
      token,
    }),
  );
};

const makeWebsocket = (
  onopen: () => void,
  onerror: () => void,
  onclose: (event: CloseEvent) => void,
  onmessage: (event: MessageEvent) => void,
) => {
  const websocket = new WebSocket(getWsRootUrl() + "/ws/online/");
  websocket.onopen = () => {
    onopen();
    triggerWebsocket(websocket);
  };
  websocket.onerror = () => {
    onerror();
  };

  websocket.onmessage = (ev: MessageEvent) => {
    onmessage(ev);
  };

  websocket.onclose = (ev: CloseEvent) => {
    onclose(ev);
  };
  return websocket;
};

const OnlineHeartbeat = ({ children }: OnlineHeartbeatProps) => {
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);
  const { isFooterPlaying } = useAppSelector((state) => state.abPlayerStore);
  const { online } = useNetwork();
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [listenWebsocket, setListenWebsocket] = useAtom(listenWebsocketAtom);
  const [_isValidVersion, setIsValidVersion] = useState(true);

  const handleOpen = useCallback(() => {
    setIsValidVersion(true);
  }, [setIsValidVersion]);

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      setIsValidVersion(event.data === VERSION);
    },
    [setIsValidVersion],
  );

  const handleError = useCallback(() => {
    setIsValidVersion(false);
  }, [setIsValidVersion]);
  const handleClose = useCallback(
    (event: CloseEvent) => {
      // Websocket Codes: https://www.rfc-editor.org/rfc/rfc6455#section-7.4.1
      // 1006 - Abnormal Closure
      if (!event.wasClean && event.code === 1006) {
        if (document.hasFocus()) {
          setIsValidVersion(false);
        }
      }
    },
    [setIsValidVersion],
  );

  useEffect(() => {
    if (online) {
      if (!websocket) {
        setWebsocket(
          makeWebsocket(handleOpen, handleError, handleClose, handleMessage),
        );
        // Ready State Codes: https://developer.mozilla.org/docs/Web/API/WebSocket/readyState
        // Code 3 - CLOSED
      } else if (websocket.readyState === WebSocket.CLOSED) {
        setWebsocket(
          makeWebsocket(handleOpen, handleError, handleClose, handleMessage),
        );
      }
    } else {
      if (websocket) {
        websocket.close();
        setWebsocket(null);
      }
    }
  }, [
    online,
    websocket,
    handleOpen,
    handleError,
    handleClose,
    setWebsocket,
    handleMessage,
  ]);

  useEffect(() => {
    if (online) {
      if (!listenWebsocket) {
        setListenWebsocket(new WebSocket(getWsRootUrl() + "/ws/listen/"));
        // Ready State Codes: https://developer.mozilla.org/docs/Web/API/WebSocket/readyState
        // Code 3 - CLOSED
      } else if (listenWebsocket.readyState === WebSocket.CLOSED) {
        setListenWebsocket(new WebSocket(getWsRootUrl() + "/ws/listen/"));
      }
    } else {
      if (listenWebsocket) {
        listenWebsocket.close();
        setListenWebsocket(null);
      }
    }
  }, [online, listenWebsocket, setListenWebsocket, handleMessage]);

  useEffect(() => {
    // Code 1 - OPEN
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      triggerWebsocket(websocket);
    }
  }, [isAuthenticated, websocket]);

  // const activateErrorBoundary = useMemo(() => {
  //   if (!online || IS_DEVELOPMENT_MODE) {
  //     return false;
  //   }
  //   return !isValidVersion;
  // }, [online, isValidVersion]);
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default OnlineHeartbeat;
