import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartMinus, faCartPlus } from "@fortawesome/sharp-regular-svg-icons";
import { Typography, useTheme } from "@mui/material";
import { useFetchReleaseTrackQuery } from "../../../api/releases/hooks/useFetchReleaseById";
import { useFetchReleaseTracksQuery } from "../../../api/releases/hooks/useFetchReleaseTracksQuery";
import { useProductCart } from "../../../atoms/products/hooks/useProductCart";
import { AddToCartButton } from "./AddReleaseTrackToCartButton.styles";

interface AddReleaseTrackToCartButtonProps {
  releaseTrackId: number;
  unitPrice: string | null;
  productToGetPriceFromId: number;
  releaseId: number;
}

export const AddReleaseTrackToCartButton = (
  props: AddReleaseTrackToCartButtonProps,
) => {
  const { releaseTrackId, unitPrice, releaseId, productToGetPriceFromId } =
    props;
  const theme = useTheme();
  const {
    cart,
    handleAddOrRemoveBundleClick,
    handleAddIndividualItemToCart,
    handleRemoveIndividualItemFromCart,
  } = useProductCart();
  const { data: releaseTracks, isPending: isPendingReleaseReleaseTracks } =
    useFetchReleaseTracksQuery(releaseId);
  const { data: releaseTrack, isPending: isPendingReleaseTrack } =
    useFetchReleaseTrackQuery(releaseTrackId);
  const isPending = isPendingReleaseReleaseTracks || isPendingReleaseTrack;

  const productInCart = cart.find(
    (item) => item.product_id === productToGetPriceFromId,
  );
  const releaseTrackIsInShoppingCart = Boolean(
    productInCart?.release_track_ids?.includes(releaseTrackId),
  );
  const addOrRemoveTrackFromCart = () => {
    const tracksOnProduct = productInCart?.release_track_ids?.length || 0;
    const trackCountAfterUpdate = releaseTrackIsInShoppingCart
      ? tracksOnProduct - 1
      : tracksOnProduct + 1;

    const isLastTrack = trackCountAfterUpdate === releaseTracks?.length;
    const shouldAddToCart = !productInCart || !releaseTrackIsInShoppingCart;

    if (shouldAddToCart && !isLastTrack) {
      handleAddIndividualItemToCart(productToGetPriceFromId, releaseTrackId);
      return;
    }
    if (releaseTrackIsInShoppingCart) {
      handleRemoveIndividualItemFromCart(
        productToGetPriceFromId,
        releaseTrackId,
      );
      return;
    }
    // add entire bundle to cart or remove entire bundle from cart
    handleAddOrRemoveBundleClick(releaseTracks, productToGetPriceFromId);
  };

  const disabled =
    !unitPrice || !productToGetPriceFromId || !releaseTracks.length;
  const releaseTrackPurchased = Boolean(
    releaseTrack?.release_track_purchased_by_current_user,
  );

  if (releaseTrackPurchased && !isPending) {
    if (releaseTrackIsInShoppingCart && !productInCart?.use_total_price) {
      handleRemoveIndividualItemFromCart(
        productToGetPriceFromId,
        releaseTrackId,
      );
    }
    return (
      <AddToCartButton
        title={"Purchased"}
        $isTrackInCart={releaseTrackIsInShoppingCart}
        $releaseTrackBought={releaseTrackPurchased}
        disabled
      >
        <Typography
          fontWeight={"bold"}
          fontSize={"14px"}
          lineHeight={"18px"}
          color={theme.palette.standardColor.Green[600]}
        >
          Purchased
        </Typography>
      </AddToCartButton>
    );
  }

  return (
    <AddToCartButton
      $isTrackInCart={releaseTrackIsInShoppingCart || isPending}
      disabled={disabled}
      onClick={disabled || isPending ? undefined : addOrRemoveTrackFromCart}
    >
      {!isPending && (
        <FontAwesomeIcon
          fontWeight={"600"}
          color={theme.palette.text.primary}
          icon={releaseTrackIsInShoppingCart ? faCartMinus : faCartPlus}
          size={"sm"}
        />
      )}
      <Typography fontWeight={"bold"} fontSize={"14px"} lineHeight={"18px"}>
        {isPending
          ? "Loading..."
          : releaseTrackIsInShoppingCart
            ? "Remove"
            : "Add"}
      </Typography>
    </AddToCartButton>
  );
};
