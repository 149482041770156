import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import EntityVideo from "../../../../store/models/entityVideo";
import { BaseModal } from "../../../core-ui/components/BaseModal/BaseModal";
import { ButtonVariant } from "../../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../../core-ui/components/Button/FixedMinWidthButton";
import { Text } from "../../../core-ui/components/Text/Text";
import { SoundWaveLoader } from "../../../elements/SoundWaveLoader/SoundWaveLoader";
import { VideoJS } from "../../VideoJS/VideoJS";
import {
  FeatureVideoBlankContainer,
  ServiceCardBaseModalContainer,
} from "./ServiceCardV2.styles";

interface FeaturedVideoModalProps {
  isVideoPreviewModalOpen: boolean;
  setIsVideoPreviewModalOpen: Dispatch<SetStateAction<boolean>>;
  serviceName: string;
  onClick: () => void;
  buttonText: string;
  isLoading: boolean;
  disabled: boolean;
  featuredVideo: EntityVideo;
}

export const FeaturedVideoModal = ({
  isVideoPreviewModalOpen,
  setIsVideoPreviewModalOpen,
  serviceName,
  onClick,
  buttonText,
  isLoading,
  disabled,
  featuredVideo,
}: FeaturedVideoModalProps) => {
  const renderVideo = () => {
    if (!featuredVideo?.hls_path) {
      return (
        <FeatureVideoBlankContainer>
          <SoundWaveLoader width={100} height={30} />
          <Text>
            The uploaded video is being processed. Please check back later!
          </Text>
        </FeatureVideoBlankContainer>
      );
    }

    return (
      <VideoJS
        controls
        autoplay
        fluid
        sources={[
          {
            type: "application/x-mpegURL",
            src: featuredVideo.hls_path,
          },
        ]}
      />
    );
  };

  return (
    <BaseModal
      open={isVideoPreviewModalOpen}
      header={serviceName}
      setOpen={setIsVideoPreviewModalOpen}
      hasStickyFooter={false}
      fullScreenOnMobile
      style={{ width: "100%", maxWidth: "unset", height: "100%" }}
      customModalStyle={{
        boxSizing: "border-box",
        width: "95%",
        maxWidth: "720px", // As per design
      }}
    >
      <ServiceCardBaseModalContainer sx={{ gap: "24px" }}>
        <Box
          sx={(theme) => ({
            borderRadius: theme.border.radius.md,
            overflow: "hidden",
          })}
        >
          {renderVideo()}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            columnGap: "24px",
          }}
        >
          <FixedMinWidthButton
            disabled={disabled || isLoading}
            variant={ButtonVariant.PRIMARY}
            onClick={onClick}
            loading={isLoading}
          >
            {buttonText}
          </FixedMinWidthButton>
        </Box>
      </ServiceCardBaseModalContainer>
    </BaseModal>
  );
};
