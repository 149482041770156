import { ScheduledProjectPaywallTypes } from "../../../store/models/project";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  PaywallPermissionsContainer,
  PaywallPermissionsDetailsContainer,
  PaywallPermissionsDetailContainer,
} from "./PaywallPermissions.styles";
import { Text } from "../../core-ui/components/Text/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLock } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material";
import { ToolTipTextArea } from "../../components/ToolTipTextArea/ToolTipTextArea";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useState } from "react";

enum ClientPermissionTextEnum {
  PAY = "Pay",
  START_PROJECT = "Start project",
  UPLOAD_FILES = "Upload files",
  LISTEN_TO_MIX = "Listen to mix",
  REQUEST_REVISIONS = "Request revisions",
  APPROVE = "Approve",
  DOWNLOAD_ASSETS = "Download assets",
}

const ClientPermissionToBookingLinkEnumMap: {
  [key in ScheduledProjectPaywallTypes]: ClientPermissionTextEnum[];
} = {
  [ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED]: [
    ClientPermissionTextEnum.PAY,
  ],
  [ScheduledProjectPaywallTypes.TRACK_PREVIEW_NOT_ALLOWED]: [
    ClientPermissionTextEnum.PAY,
    ClientPermissionTextEnum.START_PROJECT,
    ClientPermissionTextEnum.UPLOAD_FILES,
  ],
  [ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED]: [
    ClientPermissionTextEnum.PAY,
    ClientPermissionTextEnum.START_PROJECT,
    ClientPermissionTextEnum.UPLOAD_FILES,
    ClientPermissionTextEnum.LISTEN_TO_MIX,
  ],
  [ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED]: [
    ClientPermissionTextEnum.PAY,
    ClientPermissionTextEnum.START_PROJECT,
    ClientPermissionTextEnum.LISTEN_TO_MIX,
    ClientPermissionTextEnum.REQUEST_REVISIONS,
    ClientPermissionTextEnum.APPROVE,
    ClientPermissionTextEnum.UPLOAD_FILES,
  ],
};

const BookingLinkEnumToOrderMap: {
  [key in ScheduledProjectPaywallTypes]: ClientPermissionTextEnum[];
} = {
  [ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED]: [
    ClientPermissionTextEnum.PAY,
    ClientPermissionTextEnum.START_PROJECT,
    ClientPermissionTextEnum.UPLOAD_FILES,
    ClientPermissionTextEnum.LISTEN_TO_MIX,
    ClientPermissionTextEnum.REQUEST_REVISIONS,
    ClientPermissionTextEnum.APPROVE,
    ClientPermissionTextEnum.DOWNLOAD_ASSETS,
  ],
  [ScheduledProjectPaywallTypes.TRACK_PREVIEW_NOT_ALLOWED]: [
    ClientPermissionTextEnum.START_PROJECT,
    ClientPermissionTextEnum.UPLOAD_FILES,
    ClientPermissionTextEnum.PAY,
    ClientPermissionTextEnum.LISTEN_TO_MIX,
    ClientPermissionTextEnum.REQUEST_REVISIONS,
    ClientPermissionTextEnum.APPROVE,
    ClientPermissionTextEnum.DOWNLOAD_ASSETS,
  ],
  [ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED]: [
    ClientPermissionTextEnum.START_PROJECT,
    ClientPermissionTextEnum.UPLOAD_FILES,
    ClientPermissionTextEnum.LISTEN_TO_MIX,
    ClientPermissionTextEnum.PAY,

    ClientPermissionTextEnum.REQUEST_REVISIONS,
    ClientPermissionTextEnum.APPROVE,
    ClientPermissionTextEnum.DOWNLOAD_ASSETS,
  ],
  [ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED]: [
    ClientPermissionTextEnum.START_PROJECT,
    ClientPermissionTextEnum.UPLOAD_FILES,
    ClientPermissionTextEnum.LISTEN_TO_MIX,
    ClientPermissionTextEnum.REQUEST_REVISIONS,
    ClientPermissionTextEnum.APPROVE,
    ClientPermissionTextEnum.PAY,
    ClientPermissionTextEnum.DOWNLOAD_ASSETS,
  ],
};

interface PaywallPermissionsProps {
  type: ScheduledProjectPaywallTypes;
  selected: boolean;
}

export const PaywallPermissions = ({
  type,
  selected,
}: PaywallPermissionsProps) => {
  const theme = useTheme();
  const orderMap = BookingLinkEnumToOrderMap[type];
  const { isDesktop } = useMediaQueryBreakpoint();

  return (
    <PaywallPermissionsContainer $isHidden={!isDesktop && !selected}>
      <Text
        style={{
          color: theme.palette.customColor.unselectedTextPrimaryColor,
          lineHeight: ".75rem",
        }}
        variant={TextStyleVariant.P3}
      >
        This client can...
      </Text>
      <PaywallPermissionsDetailsContainer>
        {orderMap.map((text, index) => {
          const map = ClientPermissionToBookingLinkEnumMap[type];
          return (
            <ToolTipTextArea
              key={index}
              text={map.includes(text) ? "" : "Step unlocked after payment"}
            >
              <PaywallPermissionsDetailContainer>
                <FontAwesomeIcon
                  style={{
                    height: ".75rem",
                    color: map.includes(text)
                      ? theme.palette.success.main
                      : theme.palette.text.disabled,
                  }}
                  icon={map.includes(text) ? faCheck : faLock}
                />
                <Text
                  variant={TextStyleVariant.P3}
                  style={{
                    lineHeight: ".75rem",
                    color: map.includes(text)
                      ? theme.palette.customColor.clientAllowedColor
                      : theme.palette.text.disabled,
                  }}
                >
                  {text}
                </Text>
              </PaywallPermissionsDetailContainer>
            </ToolTipTextArea>
          );
        })}
      </PaywallPermissionsDetailsContainer>
    </PaywallPermissionsContainer>
  );
};
