import { styled } from "@mui/material";

export const TableRow = styled("tr")<{
  $width?: number | string;
  $disabledHover?: boolean;
  $backgroundColor?: string;
}>(({ $width, $disabledHover, theme, $backgroundColor = "transparent" }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: $width ?? "100%",
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: $backgroundColor,
  "&:hover": {
    backgroundColor: $disabledHover
      ? "transparent"
      : theme.palette.action.hover,
  },
}));
