import React, { FC, ReactElement } from "react";
import "./Pill.css";

export interface PillProps {
  label: string;
  backgroundColor?: string;
  color?: string;
  onClick?: () => void;
  className?: string;
  boldLabel?: boolean;
  icon?: ReactElement;
}

export const Pill: FC<PillProps> = ({
  label,
  backgroundColor,
  color,
  onClick = () => {},
  className = "",
  boldLabel = false,
  icon,
}): ReactElement => {
  return (
    <div
      style={{ backgroundColor }}
      className={"pill-container ".concat(className)}
      onClick={onClick}
    >
      {className.includes("weekday-pill selected") && icon}
      <p style={{ color, fontWeight: boldLabel ? 600 : 400 }}>{label}</p>
    </div>
  );
};
