import { GUEST_LOGIN } from "../../store/utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import queryString from "query-string";

export interface GetGuestVerificationCodeParams {
  email?: string;
  phone_number?: string;
}

export const getGuestVerificationCode = async (
  args: GetGuestVerificationCodeParams,
) => {
  const params = `?${queryString.stringify(args)}`;
  return await makeBackendGetCallWithJsonResponse(GUEST_LOGIN, params);
};
