import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { activeUserAtom } from "../../../atoms/user/activeUserAtom";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { EngineEarsError } from "../../../store/actions/errorStore";
import { ReleaseTrack } from "../../../store/models/release";
import { fetchReleaseTracks } from "../releaseAPI";

export const useFetchReleaseTracksQuery = (releaseId: number) => {
  const slimUser = useAtomValue(activeUserAtom);

  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_ARTIST_RELEASE_TRACKS, releaseId, slimUser?.id],
    queryFn: async () => {
      const response = await fetchReleaseTracks(releaseId);
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as ReleaseTrack[];
    },
    retry: 0,
    initialData: [],
    enabled: Boolean(releaseId),
  });
};
