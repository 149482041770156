import { EngineEarsError } from "../../store/actions/errorStore";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { PRODUCTS_BY_RELEASE_ROUTE } from "../../store/utils/routes";
import { replaceRoutePlaceholders } from "../helpers";
import { Product } from "./models";

export interface ProductResponse extends Product {
  product_purchased_by_current_user: boolean;
}
export const fetchProductsByReleaseId = async (releaseId: number) => {
  const route = replaceRoutePlaceholders(
    `${PRODUCTS_BY_RELEASE_ROUTE}{releaseId}/`,
    {
      releaseId,
    },
  );
  return await makeBackendGetCallWithJsonResponse<
    ProductResponse[] | EngineEarsError
  >(route, "");
};
