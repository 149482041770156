import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import { useDownloadReleaseTrackMutation } from "../../../api/releases/hooks/useDownloadReleaseTrackMutation";
import { UploadType } from "../../../store/models/fileVersion";
import { ReleaseTrack } from "../../../store/models/release";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";
import { shareOrCopyUrl } from "../../../utils/utils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Select } from "../../core-ui/components/Select/Select";
import { ReleaseTrackOptionsMenuStyle } from "../../screens/ProfileScreen/components/CoverPhoto.styles";

interface ReleaseTrackOptionsProps {
  releaseTrack: ReleaseTrack;
  openFeedbackModal: () => void;
}

export const ReleaseTrackOptions = ({
  releaseTrack,
  openFeedbackModal,
}: ReleaseTrackOptionsProps) => {
  const { mutate: downloadReleaseTrack } = useDownloadReleaseTrackMutation();

  return (
    <Box>
      <Select
        overrideDropdownMenuStyle={ReleaseTrackOptionsMenuStyle}
        triggerButton={
          <Button variant={ButtonVariant.ICON} style={{ padding: "12px 16px" }}>
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </Button>
        }
      >
        {releaseTrack.audio_download_mp3_enabled && (
          <DropdownMenuItem
            onSelect={() => {
              downloadReleaseTrack({
                releaseId: releaseTrack.release.id,
                uploadType: UploadType.GENERATED_MP3,
                trackId: releaseTrack.id,
              });
            }}
          >
            Download MP3
          </DropdownMenuItem>
        )}
        {releaseTrack.audio_download_wav_enabled && (
          <DropdownMenuItem
            onSelect={() => {
              downloadReleaseTrack({
                releaseId: releaseTrack.release.id,
                uploadType: UploadType.AUDIO,
                trackId: releaseTrack.id,
              });
            }}
          >
            Download WAV
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          onSelect={() => {
            const username = releaseTrack.release.artist_user?.username;
            if (!username) return;
            void shareOrCopyUrl(getProfileScreenRoute(username));
          }}
        >
          Share
        </DropdownMenuItem>
        <DropdownMenuItem
          onSelect={() => {
            openFeedbackModal();
          }}
        >
          Report
        </DropdownMenuItem>
      </Select>
    </Box>
  );
};
