import { useQuery, useQueryClient } from "@tanstack/react-query";
import { StatusCodes } from "http-status-codes";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { EngineEarsError } from "../../../store/actions/errorStore";
import { Release } from "../../../store/models/release";
import { fetchFeaturedRelease } from "../releaseAPI";

export const useFetchFeaturedArtistReleaseQuery = (
  artistUserId = 0,
  enabled = Boolean(artistUserId),
) => {
  const queryClient = useQueryClient();

  return useQuery({
    retry: 0,
    enabled,
    queryKey: [QUERY_KEYS.FETCH_FEATURED_ARTIST_RELEASE, artistUserId],
    queryFn: async () => {
      const response = await fetchFeaturedRelease(artistUserId);
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      if (response.statusCode === StatusCodes.NO_CONTENT) {
        return null;
      }
      const featuredRelease = response.resultJson as Release;
      queryClient.setQueryData(
        [QUERY_KEYS.FETCH_ARTIST_RELEASE, featuredRelease.id],
        featuredRelease,
      );
      return featuredRelease;
    },
  });
};
