import { Box } from "@mui/material";
import { forwardRef } from "react";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { StyledFooterProjectInfoTrackTitle } from "./FooterProjectInfo.styles";

export const TRACK_TITLE_DUPLICATE_GAP = 100;

interface FooterPlayerTrackTitleProps {
  trackTitle: string;
  trackTitleAnimation: string | undefined;
  expandFooter?: boolean;
  showTrackIndex: boolean;
  currentTrackIndex: number;
}

export const FooterPlayerTrackTitle = forwardRef<
  HTMLDivElement,
  FooterPlayerTrackTitleProps
>((props, ref) => {
  const {
    trackTitle,
    trackTitleAnimation,
    expandFooter,
    showTrackIndex,
    currentTrackIndex,
  } = props;
  const trackTitleWithTrackIndex = `${showTrackIndex ? currentTrackIndex + "." : ""} ${trackTitle}`;

  return (
    <Box
      sx={{
        width: "100%",
        "@keyframes scroll": {
          to: {
            transform: `translateX(calc(-100% - ${TRACK_TITLE_DUPLICATE_GAP}px))`,
          },
        },
        animation: trackTitleAnimation,
      }}
      ref={ref}
    >
      <StyledFooterProjectInfoTrackTitle
        variant={expandFooter ? TextStyleVariant.S2 : TextStyleVariant.P3}
        $expandFooter={expandFooter}
        title={trackTitle}
      >
        {trackTitleWithTrackIndex}
      </StyledFooterProjectInfoTrackTitle>
    </Box>
  );
});

FooterPlayerTrackTitle.displayName = "FooterPlayerTrackTitle";
