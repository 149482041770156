import { faCheck, faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import { centerCrop } from "react-image-crop";
import { toast } from "react-toastify";
import { useUpdateRelease } from "../../../api/releases/hooks/useUpdateRelease";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { useGetEntityPhotoUploadUrl } from "../../../hooks/entityPhotoAPIHooks";
import { useUploadProfileCoverPhoto } from "../../../hooks/profileScreenHooks/useUploadProfileCoverPhoto";
import { useDrawOnCanvas } from "../../../hooks/useDrawOnCanvas";
import { SupportedEntityTypes } from "../../../hooks/useEntityPhotos";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { Release, ReleaseTypeOptions } from "../../../store/models/release";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { computeCropDimensions } from "../../../utils/utils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { EditableTextArea } from "../../elements/EditableTextArea/EditableTextArea";
import {
  TOGGLE_SWITCH_SIZE,
  ToggleSwitch,
} from "../../elements/ToggleSwitch/ToggleSwitch";
import {
  ACCEPTED_IMAGE_UPLOAD_TYPE,
  RELEASE_COVER_DIMENSION,
} from "../../screens/ProfileScreen/constants";
import { ReleaseListItemCoverPhoto } from "../ReleaseList/ReleaseListItemCoverPhoto";
import {
  EditReleaseInputRowContainer,
  ImagePlaceHolder,
  InputContainer,
  InputLabel,
  ReleaseFormInput,
} from "./styles";

interface EditReleaseModalProps {
  release: Release | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  scheduledProjectId?: number;
  defaultPublishedAndDefaultToTrue?: boolean;
}

export const EditReleaseModal = ({
  open,
  setOpen,
  release,
  scheduledProjectId = 0,
  defaultPublishedAndDefaultToTrue = false,
}: EditReleaseModalProps) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { mutateAsync: updateRelease, isPending } = useUpdateRelease(
    release?.id,
    scheduledProjectId,
  );
  const { isTablet } = useMediaQueryBreakpoint();
  const displayableNameForUser = getDisplayableNameForUser(
    release?.artist_user,
  );
  const [publishedAt, setPublishedAt] = useState<boolean>(
    Boolean(release?.published_at) || defaultPublishedAndDefaultToTrue,
  );
  const [featuredAt, setFeaturedAt] = useState<boolean>(
    Boolean(release?.featured) || defaultPublishedAndDefaultToTrue,
  );
  const [artistName, setArtistName] = useState(
    release?.artist_display_name ?? displayableNameForUser,
  );
  const [title, setReleaseTitle] = useState(release?.title ?? "");
  const [description, setDescription] = useState(release?.description ?? "");
  const releaseLabel = ReleaseTypeOptions.find(
    (option) => option.value === release?.release_type,
  );
  const uploadImage = useGetEntityPhotoUploadUrl();
  const uploadFunc = useUploadProfileCoverPhoto({
    screenType: "desktop",
  });
  const { drawOnCanvas, generateFileFromCanvas } = useDrawOnCanvas(
    RELEASE_COVER_DIMENSION,
  );
  const isRevalidatingData =
    (release &&
      queryClient.getQueryState([QUERY_KEYS.FETCH_ARTIST_RELEASE, release.id])
        ?.fetchStatus === "fetching") ||
    queryClient.getQueryState([
      QUERY_KEYS.FETCH_SCHEDULED_PROJECT_RELEASE,
      scheduledProjectId,
    ])?.fetchStatus === "fetching";

  const handleUpload = async (file: File) => {
    if (!release) return;
    if (file) {
      try {
        const reader = new FileReader();

        reader.onload = (fileLoadEvent) => {
          if (fileLoadEvent.target?.result) {
            const placeholderImage = new Image();

            placeholderImage.onload = (imageLoadEvent) => {
              if (imageLoadEvent.currentTarget) {
                const { width, height } =
                  imageLoadEvent.currentTarget as HTMLImageElement;

                if (
                  width < RELEASE_COVER_DIMENSION.width ||
                  height < RELEASE_COVER_DIMENSION.height
                ) {
                  toast.error(
                    "The image is too small. Please upload a bigger one!",
                  );
                  return;
                }

                const { croppingWidth, croppingHeight } = computeCropDimensions(
                  { width, height },
                  RELEASE_COVER_DIMENSION,
                );
                const targetCrop = centerCrop(
                  {
                    unit: "px",
                    width: croppingWidth,
                    height: croppingHeight,
                  },
                  width,
                  height,
                );

                drawOnCanvas(placeholderImage, targetCrop);

                void generateFileFromCanvas().then(async (generatedFile) => {
                  if (!generatedFile) return;

                  const result = await uploadImage.mutateAsync({
                    entity_type: SupportedEntityTypes.Release,
                    entity_id: release.id,
                    content_type: generatedFile.type,
                    file_size: generatedFile.size,
                    entity_photo_id: release.release_cover?.id,
                  });

                  const signed_url = result.signed_url;

                  await uploadFunc.mutateAsync({
                    url: signed_url,
                    file: generatedFile,
                  });

                  await queryClient.invalidateQueries({
                    queryKey: [QUERY_KEYS.FETCH_ARTIST_RELEASE, release.id],
                  });

                  await queryClient.invalidateQueries({
                    queryKey: [
                      QUERY_KEYS.FETCH_SCHEDULED_PROJECT_RELEASE,
                      scheduledProjectId,
                    ],
                  });
                  placeholderImage.remove();
                });
              }
            };
            placeholderImage.src = fileLoadEvent.target.result as string;
          }
        };
        reader.readAsDataURL(file);
      } catch {
        toast.error("Failed to upload image");
      }
    }
  };

  return (
    <BaseModal
      confirmText={"Save"}
      cancelText={"Cancel"}
      loading={isPending}
      onCancel={() => {
        setOpen(false);
      }}
      onConfirm={async () => {
        await updateRelease({
          artist_display_name: artistName,
          title: title,
          description: description,
          featured: featuredAt,
          published_at: publishedAt,
        });
        toast.success("Release updated successfully!");
        setOpen(false);
      }}
      showModalFooter
      header={"Project Details"}
      open={open}
      setOpen={setOpen}
      modalFooterStyle={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "8px",
      }}
      modalBodyStyle={{
        justifyContent: "flex-start",
        alignItems: "flex-start",
        overflowY: "auto",
        maxHeight: "60vh",
        boxSizing: "border-box",
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        sx={{
          margin: 0,
          padding: 0,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          flexDirection: isTablet ? "column-reverse" : "row",
          gap: "24px",
        }}
      >
        <Text
          style={{
            textAlign: "center",
          }}
          color={TextColor.SECONDARY}
          variant={TextStyleVariant.P2}
        >
          Please add the relevant information related to the track you’ve chosen
          below.
        </Text>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            justifyContent: "flex-start",
            alignItems: "stretch",
            padding: 0,
            margin: 0,
            flex: 1,
            width: "100%",
          }}
        >
          <EditReleaseInputRowContainer
            style={{
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <ToggleSwitch
              label={"Public: share with the world"}
              size={TOGGLE_SWITCH_SIZE.SMALL}
              updateCheckedLocally={false}
              checked={publishedAt}
              onChange={(checked) => setPublishedAt(checked)}
            />
          </EditReleaseInputRowContainer>
          <EditReleaseInputRowContainer
            style={{
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <ToggleSwitch
              label={"Featured: highlight this project on your profile"}
              size={TOGGLE_SWITCH_SIZE.SMALL}
              updateCheckedLocally={false}
              checked={featuredAt}
              onChange={(checked) => setFeaturedAt(checked)}
            />
          </EditReleaseInputRowContainer>
          <EditReleaseInputRowContainer>
            <InputLabel>Artist name:</InputLabel>
            <InputContainer>
              <ReleaseFormInput
                value={artistName}
                onChange={(e) => setArtistName(e.target.value)}
              ></ReleaseFormInput>
              <FontAwesomeIcon
                color={theme.palette.text.primary}
                icon={faCheck}
                size="sm"
              />
            </InputContainer>
          </EditReleaseInputRowContainer>
          <EditReleaseInputRowContainer>
            <InputLabel>{`${releaseLabel?.label} name:`}</InputLabel>
            <InputContainer>
              <ReleaseFormInput
                value={title}
                onChange={(e) => setReleaseTitle(e.target.value)}
              ></ReleaseFormInput>
              <FontAwesomeIcon
                color={theme.palette.text.primary}
                icon={faCheck}
                size="sm"
              />
            </InputContainer>
          </EditReleaseInputRowContainer>
          <EditReleaseInputRowContainer>
            <InputLabel>Description:</InputLabel>
            <EditableTextArea
              textAreaProps={{
                style: {
                  padding: "12px 16px",
                  border: `solid 1px ${theme.palette.divider}`,
                  backgroundColor: theme.palette.customColor.backgroundColor,
                },
              }}
              editMode={true}
              initialValue={description}
              placeholder={"A brief description about the project"}
              width={"100%"}
              characterCount={2000}
              numberOfLines={5}
              onChange={(updatedDescription) => {
                setDescription(updatedDescription);
              }}
            />
          </EditReleaseInputRowContainer>
        </Box>
        <Box
          sx={{
            padding: 0,
            margin: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <EditReleaseInputRowContainer
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InputLabel
              style={{
                textAlign: "center",
              }}
            >
              Add or edit cover art
            </InputLabel>
            {release?.release_cover ? (
              <ReleaseListItemCoverPhoto
                width={260}
                height={260}
                releaseCover={release?.release_cover ?? null}
                loading={
                  uploadFunc.isPending ||
                  uploadImage.isPending ||
                  isRevalidatingData
                }
              >
                <input
                  type="file"
                  style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    opacity: 0,
                    position: "absolute",
                  }}
                  id="cover-photo-uploader"
                  accept={ACCEPTED_IMAGE_UPLOAD_TYPE}
                  onChange={async (e) => {
                    if (e.target?.files?.length) {
                      const file = e.target.files[0];
                      await handleUpload(file);
                    }
                  }}
                />
              </ReleaseListItemCoverPhoto>
            ) : (
              <EditReleaseInputRowContainer>
                <ImagePlaceHolder>
                  <FontAwesomeIcon color={"#A9A9A9"} icon={faImage} size="3x" />
                  <Text variant={TextStyleVariant.P2}>No photo selected</Text>
                  <input
                    type="file"
                    style={{
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      opacity: 0,
                      position: "absolute",
                      cursor: "pointer",
                    }}
                    id="cover-photo-uploader"
                    accept={ACCEPTED_IMAGE_UPLOAD_TYPE}
                    onChange={async (e) => {
                      if (e.target?.files?.length) {
                        const file = e.target.files[0];
                        await handleUpload(file);
                      }
                    }}
                  />
                </ImagePlaceHolder>
              </EditReleaseInputRowContainer>
            )}
          </EditReleaseInputRowContainer>
          <EditReleaseInputRowContainer
            style={{
              gap: 0,
            }}
          >
            <Text
              style={{
                textAlign: "center",
              }}
              color={TextColor.SECONDARY}
              variant={TextStyleVariant.P2}
            >
              Accepted file types:
            </Text>
            <Text
              style={{
                textAlign: "center",
              }}
              color={TextColor.SECONDARY}
              variant={TextStyleVariant.P2}
            >
              .PNG, .JPG
            </Text>
          </EditReleaseInputRowContainer>
          <EditReleaseInputRowContainer
            style={{
              gap: 0,
            }}
          >
            <Text
              style={{
                textAlign: "center",
              }}
              color={TextColor.SECONDARY}
              variant={TextStyleVariant.P2}
            >
              Minimum Dimensions
            </Text>
            <Text
              style={{
                textAlign: "center",
              }}
              color={TextColor.SECONDARY}
              variant={TextStyleVariant.P2}
            >
              {RELEASE_COVER_DIMENSION.width}px x{" "}
              {RELEASE_COVER_DIMENSION.height}px
            </Text>
          </EditReleaseInputRowContainer>
        </Box>
      </Box>
    </BaseModal>
  );
};
