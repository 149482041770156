import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { ColoredSpan } from "./styles";

interface FeaturedReleasePillProps {
  text?: string;
}

export const FeatureReleasePill = ({
  text = "Featured release",
}: FeaturedReleasePillProps) => {
  return (
    <ColoredSpan $backgroundColor={`var(--mint-green-200)`}>
      <Text
        style={{
          color: `var(--mint-green-600)`,
        }}
        bold
        variant={TextStyleVariant.P3}
      >
        {text}
      </Text>
    </ColoredSpan>
  );
};
