import { useAtomValue } from "jotai";
import { AuthFlows } from "../../../constants/authSteps";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { SignInForm } from "../SignInForm/SignInForm";
import { currentFlowAtom } from "./atoms";
import {
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import FooterContent from "./FooterContent";

export const UsernameSelection = () => {
  const flow = useAtomValue(currentFlowAtom);
  const isPhoneSignUp = flow === AuthFlows.PHONE_SIGNUP;
  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>Get your profile started</Text>
        <Text variant={TextStyleVariant.P1}>
          Add a username unique to you, this is how you&apos;ll appear to
          others. You can change your username at a later time in account
          settings.
        </Text>
        <SignInForm
          register={true}
          registerUsername={true}
          phoneLoginAddEmail={isPhoneSignUp}
        />
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
