export default interface EntityVideo {
  created: string;
  deleted: null | string;
  id: number;
  original_path: string | null;
  hls_path: string | null;
  mp4_path: string | null;
  media_convert_job_id: string | null;
  media_conversion_complete: string | null;
}

export enum VideoFileType {
  MP4 = ".mp4",
  HLS = ".hls",
  MOV = ".mov",
}
