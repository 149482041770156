import React, { ForwardedRef, forwardRef, MediaHTMLAttributes } from "react";
import "./VideoPlayer.css";

export interface VideoPlayerProps
  extends MediaHTMLAttributes<HTMLVideoElement> {
  mp4File?: string;
  webMFile?: string;
  height?: string;
  width?: string;
}

export const VideoPlayer = forwardRef(
  (
    {
      mp4File,
      webMFile,
      autoPlay,
      muted,
      height = "100%",
      width = "250",
      style,
    }: VideoPlayerProps,
    ref: ForwardedRef<HTMLVideoElement>,
  ) => (
    <video
      ref={ref}
      autoPlay={autoPlay}
      className="video-player"
      controls
      controlsList="nodownload"
      muted={muted}
      height={height}
      width={width}
      style={style}
    >
      {webMFile && <source src={webMFile} type="video/webm" />}
      {mp4File && <source src={mp4File} type="video/mp4" />}
      Sorry, your browser does not support embedded videos.
    </video>
  ),
);

VideoPlayer.displayName = "VideoPlayer";
