import { Box, styled } from "@mui/material";

export const ProductCardsListContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  overflowX: "auto",
  flexWrap: "nowrap",
  scrollbarWidth: "none",
  boxSizing: "border-box",

  // Necessary properties to display shadow
  margin: "-16px 0",
  padding: "16px",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  ".product-card": {
    flex: "0 0 215px",
  },
});

export const StyledProductImage = styled("img")(({ theme }) => ({
  width: "400px",
  height: "400px",
  borderRadius: theme.border.radius.lg,
  objectFit: "cover",

  [theme.breakpoints.down("md")]: {
    width: "200px",
    height: "200px",
  },

  [theme.breakpoints.down(550)]: {
    width: "300px",
    height: "300px",
  },
}));

export const ProductDetailsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: "16px",
  padding: "24px",
  overflowY: "auto",

  [theme.breakpoints.down(550)]: {
    flexDirection: "column",
  },
}));

export const ProductDetailsHeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  columnGap: "16px",

  [theme.breakpoints.down(1024)]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: "16px",
  },
}));

export const ProductDetailsArtistNameContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  columnGap: "8px",
  marginTop: "8px",
  marginBottom: "12px",
  flexWrap: "wrap",
  rowGap: "4px",
});

export const ExclusiveIconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.text.secondary,
  color: theme.palette.customColor.textInvertColor,
  borderRadius: theme.border.radius.xxs,
  fontSize: "10px",
  height: "12px",
  width: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
