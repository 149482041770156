import { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash.debounce";

import "./MapSearchDisplay.css";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_ZOOM,
  MAP_SEARCH_MAX_RESULTS,
  MapSearchTypeEnum,
  searchMapStudios,
  searchMapUsers,
  setCurrentMapBounds,
  setMapBounds,
  setMapLatLng,
  setMapLatLngFromCurrentMapBounds,
  setMapZoom,
} from "../../../store/actions/mapSearch";
import { calculateBoundingBoxOverlap } from "../../../utils/mapUtils";
import { DollarFormatter } from "../../../store/utils/formatUtils";
import { RequestStudio } from "../../components/RequestStudio/RequestStudio";
import { CustomMapMarker } from "./CustomMapMarker";
import { useAtomValue } from "jotai";
import isGoogleMapsLoadedAtom from "../../../atoms/maps/isGoogleMapsLoadedAtom";

interface MapSearchDisplayProps {
  searchType: MapSearchTypeEnum;
}

export const MapSearchDisplay = ({ searchType }: MapSearchDisplayProps) => {
  const dispatch = useAppDispatch();
  const {
    mapLatLng,
    mapBounds,
    currentMapBounds,
    currentMapZoom,
    engineerResults: {
      engineers,
      inViewCount: engineerInViewCount,
      count: engineerCount,
    },
    studioResults: {
      studios,
      inViewCount: studioInViewCount,
      count: studioCount,
    },
    loadingEngineers,
    loadingStudios,
    initialLoad,
  } = useAppSelector((state) => state.mapSearch);
  const {
    serviceTypes,
    longitude: userSearchLongitude,
    latitude: userSearchLatitude,
    genres,
    simpleBudgetSelected,
    minRate,
    maxRate,
    autocompleteSuggestions,
  } = useAppSelector((state) => state.userSearch);
  const {
    latitude: studioSearchLatitude,
    longitude: studioSearchLongitude,
    maxRate: studioRoomMaxRate,
    selectedAmenities,
    daysAvailable,
    durationMinutes,
  } = useAppSelector((state) => state.studioRoomSearch);
  const [loadingUserLocation, setLoadingUserLocation] = useState(false);
  const [activeProfileId, setActiveProfileId] = useState<number | null>(null);
  const [userLocationActive, setUserLocationActive] = useState<boolean>(false);
  const [userLocationClicked, setUserLocationClicked] =
    useState<boolean>(false);
  const mapRef = useRef<google.maps.Map>();
  const isUserSearch = searchType === MapSearchTypeEnum.USER;
  const isExploreSearch = searchType === MapSearchTypeEnum.EXPLORE;
  const isStudioSearch = searchType === MapSearchTypeEnum.STUDIO;

  const searchResultsCount = isExploreSearch
    ? studioInViewCount + engineerInViewCount
    : isStudioSearch
      ? studioInViewCount
      : engineerInViewCount;

  const currentCount = isExploreSearch
    ? Math.max(engineerCount, studioCount)
    : isStudioSearch
      ? studioCount
      : engineerCount;

  const isLoaded = useAtomValue(isGoogleMapsLoadedAtom);

  const options = {
    zoomControl: true,
    fullscreenControl: false,
    streetViewControl: false,
    minZoom: 5,
    disableDoubleClickZoom: true,
    clickableIcons: false,
  };

  const mapStyle = {
    width: "100%",
    minHeight: "700px",
    maxHeight: "100%",
  };

  const setDefaultLatLong = useCallback(() => {
    dispatch(setMapLatLng({ lat: DEFAULT_LATITUDE, lng: DEFAULT_LONGITUDE }));
  }, [dispatch]);

  const setCurrentLocation = useCallback(() => {
    const onSuccess = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      dispatch(setMapZoom(DEFAULT_ZOOM));
      dispatch(setMapLatLng({ lat: latitude, lng: longitude }));
      setLoadingUserLocation(false);
      setUserLocationClicked(true);
    };
    setLoadingUserLocation(true);
    const onError = () => {
      setDefaultLatLong();
      setLoadingUserLocation(false);
    };
    window.navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, [dispatch, setDefaultLatLong]);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const onMapIdle = useCallback(() => {
    if (mapRef.current) {
      const mapJSON = mapRef.current.getBounds()?.toJSON();
      if (mapJSON) {
        const bounds = {
          minLatitude: mapJSON.south,
          maxLatitude: mapJSON.north,
          minLongitude: mapJSON.west,
          maxLongitude: mapJSON.east,
        };
        if (mapBounds) {
          const overlap = calculateBoundingBoxOverlap(mapBounds, bounds);
          // Only update map bounds and trigger a new search if area overlap sufficiently diverges.
          if (overlap < 0.9 || currentCount >= MAP_SEARCH_MAX_RESULTS) {
            dispatch(setMapBounds(bounds));
          } else {
            dispatch(setCurrentMapBounds(bounds));
          }
        } else {
          dispatch(setMapBounds(bounds));
        }
        if (userLocationClicked) {
          setUserLocationActive(true);
          setUserLocationClicked(false);
        } else {
          setUserLocationActive(false);
        }
      }
    }
  }, [mapBounds, userLocationClicked, currentCount, dispatch]);

  const setActiveProfile = (id: number) => {
    if (activeProfileId === id) {
      setActiveProfileId(null);
    } else {
      setActiveProfileId(id);
    }
  };

  const closeActiveProfiles = () => {
    if (activeProfileId) {
      setActiveProfileId(null);
    }
  };

  const searchArea = useCallback(() => {
    if (mapBounds) {
      if (isExploreSearch) {
        dispatch(searchMapStudios(mapBounds));
        dispatch(searchMapUsers(mapBounds));
      } else if (isUserSearch) {
        dispatch(searchMapUsers(mapBounds));
      } else if (isStudioSearch) {
        dispatch(searchMapStudios(mapBounds));
      }
    }
  }, [mapBounds, dispatch, isUserSearch, isStudioSearch, isExploreSearch]);

  const debouncedSearch = useCallback(
    () => debounce(searchArea, 300)(),
    [searchArea],
  );

  const handleZoomChange = useCallback(() => {
    if (mapRef.current) {
      const zoom = mapRef.current.getZoom();
      if (currentMapZoom === zoom) return;
      if (zoom) {
        dispatch(setMapZoom(zoom));
      }
    }
  }, [dispatch, currentMapZoom]);

  useEffect(() => {
    debouncedSearch();
  }, [
    studioRoomMaxRate,
    simpleBudgetSelected,
    minRate,
    maxRate,
    selectedAmenities,
    daysAvailable,
    durationMinutes,
    serviceTypes,
    genres,
    autocompleteSuggestions,
    debouncedSearch,
  ]);

  useEffect(() => {
    if (mapBounds) return;
    if (isUserSearch) {
      if (userSearchLatitude && userSearchLongitude) {
        dispatch(
          setMapLatLng({
            lat: userSearchLatitude,
            lng: userSearchLongitude,
          }),
        );
      } else {
        setDefaultLatLong();
        setCurrentLocation();
      }
    } else if (isStudioSearch || isExploreSearch) {
      if (studioSearchLatitude && studioSearchLongitude) {
        dispatch(
          setMapLatLng({
            lat: studioSearchLatitude,
            lng: studioSearchLongitude,
          }),
        );
      } else {
        setDefaultLatLong();
        setCurrentLocation();
      }
    }
  }, [
    isUserSearch,
    isStudioSearch,
    isExploreSearch,
    userSearchLatitude,
    userSearchLongitude,
    studioSearchLatitude,
    studioSearchLongitude,
    setCurrentLocation,
    mapBounds,
    dispatch,
    setDefaultLatLong,
  ]);

  useEffect(() => {
    // Only trigger on initial map search load.
    // Recenters the map on the previous map state if available.
    if (currentMapBounds) {
      dispatch(setMapLatLngFromCurrentMapBounds());
    }
  }, []);

  if (!isLoaded) {
    return (
      <div style={{ height: "500px", display: "flex", alignItems: "center" }}>
        <SoundWaveLoader width={100} height={100} />
      </div>
    );
  }

  const isEmptyState =
    isLoaded && !loadingUserLocation && searchResultsCount === 0 && initialLoad;

  return (
    <div>
      <div className="map-search-display-header">
        <h3
          className="label"
          style={{
            color: "var(--medium-grey)",
            marginLeft: "15px",
          }}
        >
          {searchResultsCount} RESULTS
        </h3>
        <div />
      </div>
      <div onClick={closeActiveProfiles}>
        <GoogleMap
          mapContainerStyle={mapStyle}
          center={mapLatLng}
          zoom={currentMapZoom}
          options={options}
          onIdle={onMapIdle}
          onLoad={onMapLoad}
          onZoomChanged={handleZoomChange}
        >
          <div
            className="map-search-display-center-button"
            onClick={setCurrentLocation}
          >
            {loadingUserLocation ? (
              <SoundWaveLoader height={40} width={40} />
            ) : (
              <FontAwesomeIcon
                className="map-search-display-center-icon"
                icon={faLocationArrow}
                color={userLocationActive ? "var(--deep-blue)" : "var(--gray)"}
              />
            )}
          </div>
          {isEmptyState &&
            !loadingStudios &&
            (isStudioSearch || isExploreSearch) && (
              <div className="map-search-display-empty-state">
                <RequestStudio />
              </div>
            )}
          {isEmptyState && !loadingEngineers && isUserSearch && (
            <div className="map-search-display-notification">
              NO RESULTS AVAILABLE
            </div>
          )}
          {(loadingEngineers || loadingStudios) && (
            <div className="map-search-display-notification">
              LOADING SEARCH RESULTS...
            </div>
          )}
          {(isStudioSearch || isExploreSearch) &&
            studios.length > 0 &&
            studios.map((studio) => {
              if (
                !studio.location?.rough_latitude ||
                !studio.location?.rough_longitude
              ) {
                return null;
              }
              return (
                <CustomMapMarker
                  key={studio.id}
                  position={{
                    lat: studio.location.rough_latitude,
                    lng: studio.location.rough_longitude,
                  }}
                  price={DollarFormatter().format(
                    studio.recording_service?.service_rate.price || 0,
                  )}
                  id={studio.id}
                  studio={studio}
                  activeProfileId={activeProfileId}
                  setActiveProfile={setActiveProfile}
                />
              );
            })}
          {(isUserSearch || isExploreSearch) &&
            engineers.length > 0 &&
            engineers.map((engineer) => {
              if (
                !engineer.location?.rough_latitude ||
                !engineer.location?.rough_longitude
              ) {
                return null;
              }
              return (
                <CustomMapMarker
                  key={engineer.id}
                  position={{
                    lat: engineer.location.rough_latitude,
                    lng: engineer.location.rough_longitude,
                  }}
                  price={DollarFormatter().format(
                    engineer.service_rate?.price || 0,
                  )}
                  id={engineer.id}
                  engineer={engineer}
                  activeProfileId={activeProfileId}
                  setActiveProfile={setActiveProfile}
                />
              );
            })}
        </GoogleMap>
      </div>
    </div>
  );
};
