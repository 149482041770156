import { useState } from "react";
import { toast } from "react-toastify";
import useModal from "../../../../hooks/useModal";
import { useLatestUploadedFile } from "../../../../hooks/useProjectFilesUploaded";
import { useQueryParam } from "../../../../hooks/useQueryParam";
import { fetchFileComments } from "../../../../store/actions/fileVersionComments";
import {
  artistMasteringTransitions,
  artistMixingTransitions,
} from "../../../../store/actions/projects";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectById,
  ProjectType,
  projectTypeReadableName,
} from "../../../../store/models/project";
import { getTrackComments } from "../../../../store/selectors/trackComments";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../core-ui/components/BasePopover/PopoverContainerContext";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { LoginRequiredPopover } from "../../../core-ui/components/LoginRequiredPopover/LoginRequiredPopover";
import { Text } from "../../../core-ui/components/Text/Text";
import { EditableTextArea } from "../../../elements/EditableTextArea/EditableTextArea";
import { ApproveMixPopoverContent } from "../InProgressTransitionView.styles";
import { PurchaseRevisionButton } from "./PurchaseRevisionButton";

interface RequestRevisionButtonProps {
  project: ProjectById;
  isArtist: boolean;
  disabled?: boolean;
  isInProgressProject?: boolean;
}

/**
 * Updated version of the `ArtistAcceptRejectMix` component.
 * Updated version of the `ArtistAcceptRejectProjectModal` component.
 * Updated version of the `ArtistApproveRejectMix` component.
 */
export const RequestRevisionButton = ({
  project,
  disabled = false,
  isArtist,
  isInProgressProject = false,
}: RequestRevisionButtonProps) => {
  const dispatch = useAppDispatch();
  const codeQuery = useQueryParam("code");
  const code = codeQuery.get();
  const user = useAppSelector((state) => state.accountInfo.user);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const mostRecentFile = useLatestUploadedFile(project.id);
  const { page } = useAppSelector((state) => state.fileVersionCommentsSlice);
  const comments = useAppSelector(getTrackComments(page, project.id));
  const { service_type: projectType, revisions_available: revisionsAvailable } =
    project;
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();

  const handleRejectMix = async () => {
    setIsLoading(true);
    try {
      const transitionParams = {
        project_id: project.id.toString(),
        code,
      };
      if (projectType === ProjectType.MASTERING) {
        await dispatch(
          artistMasteringTransitions({
            ...transitionParams,
            artist_master_revision_notes: comment,
            transition: MasteringTransitions.ARTIST_REJECTING_MASTER,
          }),
        ).unwrap();
      } else {
        await dispatch(
          artistMixingTransitions({
            ...transitionParams,
            artist_mix_revision_notes: comment,
            transition: MixingTransitions.ARTIST_REJECT_MIX,
          }),
        ).unwrap();
      }

      // fetch comment written by the artist
      if (mostRecentFile?.id) {
        await dispatch(
          fetchFileComments({
            projectId: project.id,
            code: null,
          }),
        );
      }
    } finally {
      setIsLoading(false);
      setComment("");
    }
    closePopover();
  };

  if (user && !isArtist && !isInProgressProject) {
    return (
      <Button
        variant={ButtonVariant.DISABLED}
        fullWidth
        onClick={() => {
          toast.error(
            `Only the account that booked the project can officially request a revision`,
          );
        }}
      >
        Request revision ({revisionsAvailable})
      </Button>
    );
  }

  if (!revisionsAvailable) {
    return (
      <PurchaseRevisionButton
        project={project}
        isArtist={isArtist}
        disabled={disabled}
      />
    );
  }

  return (
    <LoginRequiredPopover
      isOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      closePopover={closePopover}
      side="top"
      title="Enter revision notes"
      description=""
      additionalContent={
        <ApproveMixPopoverContent>
          <Text>
            What are you looking to change from this{" "}
            {projectTypeReadableName.get(projectType)}? Give the engineer a
            description of how you would like your song updated.
          </Text>
          <EditableTextArea
            editMode={true}
            initialValue={comment}
            onChange={setComment}
            placeholder="enter revision comment"
          />
        </ApproveMixPopoverContent>
      }
      okButtonProps={{
        disabled: Boolean(!comment && !comments.length) || isLoading,
        loading: isLoading,
      }}
      onConfirm={handleRejectMix}
      wrapperElement={containerElement}
      ignoreAuth={isInProgressProject}
      authConfirmDescription="In order to officially request a revision, please sign in to the account that originally booked the project."
    >
      <Button
        fullWidth
        disabled={isLoading}
        loading={isLoading}
        variant={ButtonVariant.OUTLINED}
      >
        Request revision ({revisionsAvailable})
      </Button>
    </LoginRequiredPopover>
  );
};
