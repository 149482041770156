import { format } from "date-fns";
import { useFetchReleaseQuery } from "../../../api/releases/hooks/useFetchReleaseQuery";
import { useFetchReleaseTracksQuery } from "../../../api/releases/hooks/useFetchReleaseTracksQuery";
import {
  ProductDetailsModal,
  ProductDetailsModalWrapperProps,
} from "./ProductDetailsModal";

export const ProductDetailsModalWrapper = (
  props: ProductDetailsModalWrapperProps,
) => {
  const { data: releaseData, isFetching: isFetchingReleaseData } =
    useFetchReleaseQuery(props.releaseId);
  const { data: releaseTracks, isPending: isPendingReleaseReleaseTracks } =
    useFetchReleaseTracksQuery(props.releaseId);

  return (
    <ProductDetailsModal
      releaseDetails={{
        data: releaseData
          ? {
              title: releaseData.title,
              artistUsername: releaseData.artist_user?.username || "",
              artistDisplayName: releaseData.artist_display_name,
              releaseType: releaseData.release_type,
              year: format(new Date(releaseData.created_at), "yyyy"),
              featured: Boolean(releaseData.featured),
            }
          : null,
        loading: isFetchingReleaseData,
      }}
      releaseTracks={{
        data: releaseTracks || [],
        loading: isPendingReleaseReleaseTracks,
      }}
      {...props}
    />
  );
};
