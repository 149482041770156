import { Skeleton } from "@mui/material";
import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { BareUser } from "../../../store/models/user";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";
import { Text, TEXT_LINE_HEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";

interface ReleaseCollaboratorProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  collaborators: BareUser[];
  isLoadingCollaborators: boolean;
  textStyleVariant?: TextStyleVariant;
  lineHeight?: TEXT_LINE_HEIGHT;
}

export const ReleaseCollaborator = ({
  title,
  collaborators,
  isLoadingCollaborators,
  textStyleVariant = TextStyleVariant.P1,
  lineHeight = TEXT_LINE_HEIGHT.NORMAL,
  ...restProps
}: ReleaseCollaboratorProps) => {
  if (isLoadingCollaborators) {
    return <Skeleton width={"30%"} height={10} />;
  }
  if (!collaborators.length) return null;
  const { style = {}, ...extraProps } = restProps;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        ...style,
      }}
      {...extraProps}
    >
      <Text variant={textStyleVariant} lineHeight={lineHeight}>
        {title}
      </Text>
      {collaborators.map((user) => (
        <Link
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
          key={user.id}
          to={getProfileScreenRoute(user.username)}
        >
          <Text variant={textStyleVariant} lineHeight={lineHeight} bold>
            {`@${user.username}`}
          </Text>
        </Link>
      ))}
    </div>
  );
};
