import { useAtom } from "jotai";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { assertEngineEarsErrorType } from "../../../api/helpers";
import { ShoppingCartItem } from "../../../api/productTransactions/models";
import { useTransactionForProductMutation } from "../../../api/productTransactions/useTransactionForProductMutation";
import { PRODUCT_PURCHASE_LIMIT_REACHED } from "../../../store/models/exceptions";
import { ReleaseTrack } from "../../../store/models/release";
import { getReleaseCheckoutScreenRoute } from "../../../store/utils/routeGetters";
import { productCartAtom } from "../cart";

export const useProductCart = () => {
  const [cart, setCart] = useAtom(productCartAtom);
  const history = useHistory();
  const { mutateAsync: createTransaction, isPending: isCheckingout } =
    useTransactionForProductMutation();

  const handleReplaceBundle = useCallback(
    (
      selectedProductId: number,
      releaseTracks: ReleaseTrack[],
      productIds: number[],
    ) => {
      const filtered = cart.filter(
        (cartItem) => !productIds.includes(cartItem.product_id),
      );

      const updatedCart = [
        ...filtered,
        {
          product_id: selectedProductId,
          release_track_ids: releaseTracks.map((track) => track.id),
          use_total_price: true,
        },
      ];

      setCart(updatedCart);

      return updatedCart;
    },
    [cart, setCart],
  );

  const handleRemoveBundle = useCallback(
    (selectedProductId: number) => {
      setCart(
        cart.filter((cartItem) => cartItem.product_id !== selectedProductId),
      );
    },
    [cart, setCart],
  );

  const handleAddOrRemoveBundleClick = useCallback(
    (releaseTracks: ReleaseTrack[], productId: number) => {
      const productInCart = cart.find((item) => item.product_id === productId);
      const newTracks = releaseTracks.map((track) => track.id);
      let updatedCart = cart;

      if (!productInCart) {
        // Add new product to cart
        updatedCart = [
          ...cart,
          {
            product_id: productId,
            release_track_ids: newTracks,
            use_total_price: true,
          },
        ];
      } else {
        const { use_total_price, release_track_ids } = productInCart;
        const shouldUpdate =
          !use_total_price && release_track_ids.length < releaseTracks?.length;

        if (shouldUpdate) {
          // Update existing product in cart
          updatedCart = cart.map((item) =>
            item.product_id === productId
              ? {
                  ...item,
                  release_track_ids: newTracks,
                  use_total_price: true,
                }
              : item,
          );
        } else {
          // Remove product from cart
          updatedCart = cart.filter((item) => item.product_id !== productId);
        }
      }

      setCart(updatedCart);
      return updatedCart;
    },
    [cart, setCart],
  );

  const handleAddIndividualItemToCart = useCallback(
    (productId: number, releaseTrackId: number) => {
      const productInCart = cart.find((item) => item.product_id === productId);
      if (!productInCart) {
        setCart([
          ...cart,
          {
            product_id: productId,
            release_track_ids: [releaseTrackId],
            use_total_price: false,
          },
        ]);
        return;
      }
      const newTracks = productInCart
        ? [...productInCart.release_track_ids, releaseTrackId]
        : [releaseTrackId];
      const updatedCart = cart.map((item) => {
        if (item.product_id === productId) {
          return { ...item, release_track_ids: newTracks };
        }
        return item;
      });
      setCart(updatedCart);
    },
    [cart, setCart],
  );

  const handleRemoveIndividualItemFromCart = useCallback(
    (productId: number, releaseTrackId: number) => {
      const updatedCart = cart.reduce(
        (acc, item) => {
          if (item.product_id !== productId) {
            acc.push(item);
            return acc;
          }
          const updatedReleaseTrackIds = item.release_track_ids.filter(
            (id) => id !== releaseTrackId,
          );

          if (updatedReleaseTrackIds.length > 0) {
            acc.push({
              ...item,
              use_total_price: false,
              release_track_ids: updatedReleaseTrackIds,
            });
          }
          return acc;
        },
        [] as typeof cart,
      );

      setCart(updatedCart);
    },
    [cart, setCart],
  );

  const handleCheckout = async (shoppingCartItems: ShoppingCartItem[]) => {
    try {
      const { transaction } = await createTransaction({
        shopping_cart_items: shoppingCartItems.map((item) => {
          if (item.use_total_price) {
            return {
              ...item,
              release_track_ids: [],
            };
          }
          return item;
        }),
      });
      const route = getReleaseCheckoutScreenRoute(transaction.code);
      history.push(route);
    } catch (error) {
      if (
        assertEngineEarsErrorType(error) &&
        error.code !== PRODUCT_PURCHASE_LIMIT_REACHED
      ) {
        toast.error(
          "There is an issue with your checkout, please reach out to customer support",
        );
      }
    }
  };

  const handleClearCart = useCallback(() => {
    setCart([]);
  }, [setCart]);

  return {
    handleAddOrRemoveBundleClick,
    handleAddIndividualItemToCart,
    handleRemoveIndividualItemFromCart,
    cart,
    handleClearCart,
    handleCheckout,
    isCheckingout,
    handleReplaceBundle,
    handleRemoveBundle,
  } as const;
};
