import { FC, ReactElement, useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { WEEKDAYS } from "../../../store/models/workingHours";
import { Pill } from "../../elements/Pill/Pill";
import "./WeekdayPicker.css";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useAtomValue } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Box, useTheme } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { SelectTimeInDayDropdownComponent } from "../SelectWorkingHoursComponent/SelectWorkingHoursComponent";
import {
  SelectAMPMOptions,
  SelectHourInDayOptions,
} from "../../../store/models/alts";
import {
  TimeDropdownContainer,
  TimeSelectionContainer,
  WeekdayPickerContainer,
} from "./WeekdayPicker.styles";

function getWeekdayStringFromIndex(index: WEEKDAYS): string {
  switch (index) {
    case 0:
      return "Mon";
    case 1:
      return "Tue";
    case 2:
      return "Wed";
    case 3:
      return "Thu";
    case 4:
      return "Fri";
    case 5:
      return "Sat";
    case 6:
      return "Sun";
  }
  return "Not found";
}

export interface WeekdayPickerProps {
  weekdaysInput: Array<number>;
  onChange?: (selected: Array<number>) => void;
  enableTimeSelection?: boolean;
  weekdayTimes?: Record<
    number,
    {
      start: OptionType<number>;
      end: OptionType<number>;
      startAMPM: OptionType<number>;
      endAMPM: OptionType<number>;
    }
  >;
  onTimeChange?: (
    day: number,
    times: {
      start: OptionType<number>;
      end: OptionType<number>;
      startAMPM: OptionType<number>;
      endAMPM: OptionType<number>;
    },
  ) => void;
}

export const WeekdayPicker: FC<WeekdayPickerProps> = ({
  weekdaysInput,
  onChange,
  enableTimeSelection = false,
  weekdayTimes = {},
  onTimeChange,
}): ReactElement => {
  const theme = useTheme();
  const allWeekdays = [0, 1, 2, 3, 4, 5, 6];
  const initialWeekdays =
    weekdaysInput.length === 0 ? allWeekdays : weekdaysInput;
  const [weekdays, setWeekdays] = useState<Array<number>>(initialWeekdays);
  const darkMode = useAtomValue(darkModeAtom);
  const isLoggedIn = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );

  useEffect(() => {
    if (onChange) onChange(weekdays);
  }, [weekdays, onChange]);

  const add = (weekdayIndex: number) => {
    setWeekdays([...weekdays, weekdayIndex].sort());
  };

  const remove = (weekdayIndex: number) => {
    const index = weekdays.indexOf(weekdayIndex);
    if (index > -1)
      setWeekdays(
        weekdays.filter((val) => {
          return val !== weekdayIndex;
        }),
      );
  };

  const handleTimeChange = (
    day: number,
    times: {
      start: OptionType<number>;
      end: OptionType<number>;
      startAMPM: OptionType<number>;
      endAMPM: OptionType<number>;
    },
  ) => {
    if (onTimeChange) onTimeChange(day, times);
  };

  return (
    <WeekdayPickerContainer
      className={isLoggedIn && darkMode ? "dark-mode-weekday-picker " : ""}
      $enableTimeSelection={enableTimeSelection}
    >
      {allWeekdays.map((g, index) => (
        <TimeSelectionContainer
          key={index}
          $enableTimeSelection={enableTimeSelection}
        >
          <Pill
            className={
              weekdays.indexOf(index) === -1
                ? " weekday-pill "
                : " weekday-pill selected "
            }
            key={g}
            label={getWeekdayStringFromIndex(index)}
            onClick={() => {
              if (weekdays.indexOf(index) > -1) {
                remove(index);
              } else {
                add(index);
              }
            }}
            icon={
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  color: theme.palette.standardColor.BoomyOrange[500],
                  width: "12px",
                  height: "12px",
                }}
              />
            }
          />
          {enableTimeSelection && (
            <TimeDropdownContainer>
              <SelectTimeInDayDropdownComponent
                hour={weekdayTimes[index]?.start || SelectHourInDayOptions[0]}
                time={weekdayTimes[index]?.startAMPM || SelectAMPMOptions[0]}
                onHourChange={(option) =>
                  handleTimeChange(index, {
                    start: option,
                    end: weekdayTimes[index]?.end || SelectHourInDayOptions[0],
                    startAMPM:
                      weekdayTimes[index]?.startAMPM || SelectAMPMOptions[0],
                    endAMPM:
                      weekdayTimes[index]?.endAMPM || SelectAMPMOptions[0],
                  })
                }
                onTimeChange={(option) =>
                  handleTimeChange(index, {
                    start:
                      weekdayTimes[index]?.start || SelectHourInDayOptions[0],
                    end: weekdayTimes[index]?.end || SelectHourInDayOptions[0],
                    startAMPM: option,
                    endAMPM:
                      weekdayTimes[index]?.endAMPM || SelectAMPMOptions[0],
                  })
                }
              />
              <Text variant={TextStyleVariant.P1}>to</Text>
              <SelectTimeInDayDropdownComponent
                hour={weekdayTimes[index]?.end || SelectHourInDayOptions[0]}
                time={weekdayTimes[index]?.endAMPM || SelectAMPMOptions[0]}
                onHourChange={(option) =>
                  handleTimeChange(index, {
                    start:
                      weekdayTimes[index]?.start || SelectHourInDayOptions[0],
                    end: option,
                    startAMPM:
                      weekdayTimes[index]?.startAMPM || SelectAMPMOptions[0],
                    endAMPM:
                      weekdayTimes[index]?.endAMPM || SelectAMPMOptions[0],
                  })
                }
                onTimeChange={(option) =>
                  handleTimeChange(index, {
                    start:
                      weekdayTimes[index]?.start || SelectHourInDayOptions[0],
                    end: weekdayTimes[index]?.end || SelectHourInDayOptions[0],
                    startAMPM:
                      weekdayTimes[index]?.startAMPM || SelectAMPMOptions[0],
                    endAMPM: option,
                  })
                }
              />
            </TimeDropdownContainer>
          )}
        </TimeSelectionContainer>
      ))}
      {!enableTimeSelection && (
        <Text variant={TextStyleVariant.P3}>
          ({weekdays.length} days selected)
        </Text>
      )}
    </WeekdayPickerContainer>
  );
};
