import { useAtomValue } from "jotai";
import { TwitchEmbed } from "react-twitch-embed";
import { topNavSpacerHeightAtom } from "../../../atoms/navAtoms";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useStickyCoverPhotoHeight } from "../../../hooks/profileScreenHooks/useStickyCoverPhoto";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

interface ProfileTwitchStreamProps {
  channelName?: string;
}

export const ProfileTwitchStream = ({
  channelName,
}: ProfileTwitchStreamProps) => {
  const darkMode = useAtomValue(darkModeAtom);
  const { isMobile } = useMediaQueryBreakpoint();
  const stickyCoverPhotoHeight = useStickyCoverPhotoHeight();
  const topNavHeight = useAtomValue(topNavSpacerHeightAtom);
  return (
    <TwitchEmbed
      channel={channelName}
      autoplay
      withChat
      width="100%"
      height={
        isMobile
          ? "600px"
          : `calc(100vh - ${stickyCoverPhotoHeight + topNavHeight}px)`
      }
      darkMode={Boolean(darkMode)}
    />
  );
};
