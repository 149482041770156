import { atom } from "jotai";
const _listenWebsocketAtom = atom<WebSocket | null>(null);
_listenWebsocketAtom.debugPrivate = true;

const listenWebsocketAtom = atom(
  (get) => get(_listenWebsocketAtom),
  (get, set, websocket: WebSocket | null) => {
    const oldWebsocket = get(_listenWebsocketAtom);
    if (
      oldWebsocket?.readyState &&
      oldWebsocket.readyState === WebSocket.OPEN
    ) {
      oldWebsocket.close();
    }
    set(_listenWebsocketAtom, websocket);
  },
);
listenWebsocketAtom.debugLabel = "Listen Websocket Atom";

export default listenWebsocketAtom;
