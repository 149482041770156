import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ProjectType } from "../models/project";
import { RecordingService } from "../models/recording";
import Service, {
  APIService,
  EngineerSchedulingSurvey,
  MockEngineerSchedulingSurvey,
  ServiceFormProps,
  ServicesMock,
  transformRawData,
} from "../models/service";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  ENGINEER_SERVICE_CRUD,
  LOAD_ENGINEER_SERVICES,
  SCHEDULING_SURVEY,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";
import { createRecordingService, deleteRecordingService } from "./recording";

interface ServicesState {
  isLoading?: boolean;
  isLoadingEngineeringServices: boolean;
  services: Service[];
  loadingAnswers: boolean;
  surveyData?: EngineerSchedulingSurvey;
  recordingService?: RecordingService;
  studioRecordingServices: RecordingService[];
}

const initialState: ServicesState = {
  isLoading: undefined,
  isLoadingEngineeringServices: false,
  services: [],
  recordingService: undefined,
  loadingAnswers: false,
  studioRecordingServices: [],
};

export interface LoadEngineerServices {
  engineer_id: number;
}

export interface LoadEngineeringServicesResponse {
  services: APIService[];
  recording_services: RecordingService[];
}

export const loadEngineerServices = createAsyncThunk(
  LOAD_ENGINEER_SERVICES,
  async (args: LoadEngineerServices, thunkAPI) => {
    const params = `?engineer_id=${args.engineer_id.toString()}`;
    const response =
      await makeBackendGetCallWithJsonResponse<LoadEngineeringServicesResponse>(
        LOAD_ENGINEER_SERVICES,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export enum ServiceOperations {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  UPSERT = "upsert",
}

interface ServiceCrudParams extends ServiceFormProps {
  operation: ServiceOperations;
}

export const engineerServiceCrud = createAsyncThunk(
  ENGINEER_SERVICE_CRUD,
  async (args: ServiceCrudParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<APIService>(
      ENGINEER_SERVICE_CRUD,
      args,
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface loadSchedulingSurveyParams {
  service_type: ProjectType;
}

export const loadSchedulingSurveyAnswers = createAsyncThunk(
  SCHEDULING_SURVEY + "/get",
  async (args: loadSchedulingSurveyParams, thunkAPI) => {
    const params = `?service_type=${args.service_type.toString()}`;

    const response =
      await makeBackendGetCallWithJsonResponse<EngineerSchedulingSurvey>(
        SCHEDULING_SURVEY,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const updateSchedulingSurveyAnswers = createAsyncThunk(
  SCHEDULING_SURVEY + "/post",
  async (args: EngineerSchedulingSurvey, thunkAPI) => {
    const response =
      await makeBackendPostCallWithJsonResponse<EngineerSchedulingSurvey>(
        SCHEDULING_SURVEY,
        args,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const engineerServicesSlice = createSlice({
  name: "engineerServices",
  initialState,
  reducers: {
    uploadMockServices: (state: ServicesState) => {
      state.services = ServicesMock;
    },
    loadMockSurveyData: (state: ServicesState) => {
      state.surveyData = MockEngineerSchedulingSurvey;
    },
    clearServicesForRefresh: (state: ServicesState) => {
      state.services = [];
    },
    clearRecordingServices: (state: ServicesState) => {
      state.recordingService = undefined;
      state.studioRecordingServices = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      deleteRecordingService.fulfilled,
      (state: ServicesState) => {
        state.isLoading = false;
        state.recordingService = undefined;
      },
    );
    builder.addCase(
      createRecordingService.fulfilled,
      (state: ServicesState, action: PayloadAction<RecordingService>) => {
        state.isLoading = false;
        state.recordingService = action.payload;
      },
    );
    builder.addCase(engineerServiceCrud.pending, (state: ServicesState) => {
      state.isLoading = true;
    });
    builder.addCase(engineerServiceCrud.rejected, (state: ServicesState) => {
      state.isLoading = false;
    });
    builder.addCase(
      engineerServiceCrud.fulfilled,
      (state: ServicesState, action) => {
        state.isLoading = false;
        const service = transformRawData(action.payload);
        if (!service) return;
        const meta = action.meta as { arg: ServiceCrudParams };
        const operation = meta.arg.operation;
        switch (operation) {
          case ServiceOperations.DELETE:
            state.services = state.services.filter((s) => s.id !== service.id);
            break;
          case ServiceOperations.CREATE:
            state.services.push(service);
            break;
          case ServiceOperations.UPDATE:
            state.services = state.services.map((s) =>
              s.id === service.id ? service : s,
            );
            break;
        }
      },
    );
    builder.addCase(loadEngineerServices.pending, (state: ServicesState) => {
      state.isLoading = true;
      state.isLoadingEngineeringServices = true;
    });
    builder.addCase(loadEngineerServices.rejected, (state: ServicesState) => {
      state.isLoading = false;
      state.isLoadingEngineeringServices = false;
    });
    builder.addCase(loadEngineerServices.fulfilled, (state, action) => {
      const prevServices = state.services;
      const fetchedServices = action.payload.services.map((service) =>
        transformRawData(service),
      );
      if (action.payload.recording_services) {
        const recordingServices = action.payload.recording_services;
        const engineer_recording_service = recordingServices.find(
          (service: RecordingService) => {
            return (
              service?.engineer &&
              service.engineer.id === action.meta.arg.engineer_id
            );
          },
        );
        state.recordingService = engineer_recording_service;
        state.studioRecordingServices = recordingServices.filter(
          (service: RecordingService) => Boolean(service.studio_room),
        );
      } else {
        state.recordingService = undefined;
      }

      if (JSON.stringify(prevServices) !== JSON.stringify(fetchedServices)) {
        state.services = fetchedServices.filter(
          (service: Service) => service.deleted === null,
        );
      }
      state.isLoading = false;
      state.isLoadingEngineeringServices = false;
    });
    builder.addMatcher(
      isAnyOf(loadSchedulingSurveyAnswers.pending),
      (state) => {
        state.loadingAnswers = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        loadSchedulingSurveyAnswers.rejected,
        updateSchedulingSurveyAnswers.rejected,
      ),
      (state) => {
        state.loadingAnswers = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        loadSchedulingSurveyAnswers.fulfilled,
        updateSchedulingSurveyAnswers.fulfilled,
      ),
      (state, action) => {
        state.loadingAnswers = false;
        state.surveyData = action.payload;
      },
    );
  },
});

export const {
  clearServicesForRefresh,
  uploadMockServices,
  loadMockSurveyData,
  clearRecordingServices,
} = engineerServicesSlice.actions;

export default engineerServicesSlice.reducer;
