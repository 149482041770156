import React, { CSSProperties, FC, HTMLAttributes, LegacyRef } from "react";
import "./DashboardCardHeader.css";

interface DashboardCardHeaderProps {
  title?: string;
  actionButton: React.ReactNode;
  actionButtonWrapperProps?: Partial<HTMLAttributes<HTMLDivElement>>;
  ref?: LegacyRef<HTMLDivElement>;
  style?: CSSProperties;
  showEmptyTitle?: boolean;
}

export const DashboardCardHeader: FC<DashboardCardHeaderProps> = ({
  title,
  actionButton,
  ref,
  style,
  showEmptyTitle = false,
  actionButtonWrapperProps = {},
}: DashboardCardHeaderProps) => {
  if (!title && !showEmptyTitle) {
    return null;
  }

  return (
    <div ref={ref} className={"dashboard-card-header"} style={style}>
      <p className={"dashboard-card-header__title"}>{title}</p>
      <div {...actionButtonWrapperProps}>{actionButton}</div>
    </div>
  );
};
