import { useQuery } from "@tanstack/react-query";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { Equipment } from "../../store/models/equipment";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { USER_EQUIPMENT } from "../../store/utils/routes";

interface GetUserInterfaceResponse {
  [key: number]: Equipment[];
}

export const useGetUserEquipment = (userId?: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_USER_EQUIPMENT, userId],
    queryFn: async () => {
      if (!userId) return new Map();
      const result =
        await makeBackendGetCallWithJsonResponse<GetUserInterfaceResponse>(
          USER_EQUIPMENT,
          `${userId}`,
        );
      if (assertEngineEarsErrorType(result)) {
        return Promise.reject(result);
      }

      if (result.success) {
        const userEquipmentMap = new Map<number, Equipment[]>(
          Object.entries(result.resultJson).map(([key, value]) => [
            parseInt(key),
            value,
          ]),
        );

        return userEquipmentMap;
      }
      displayEngineEarsError(result.resultJson);
      return Promise.reject(result.resultJson);
    },
    enabled: Boolean(userId),
  });
};
