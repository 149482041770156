import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useProductCart } from "../../../atoms/products/hooks/useProductCart";
import { useIsVisitingOwnProfile } from "../../../hooks/profileScreenHooks/useIsVisitingOwnProfile";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { ReleaseTrack } from "../../../store/models/release";
import { getMyLibraryRoute } from "../../../store/utils/routeGetters";
import { ButtonVariant } from "../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../core-ui/components/Button/FixedMinWidthButton";

interface ProductModalFooterButtonsProps {
  productPurchasedByCurrentUser: boolean;
  productId: number;
  releaseTracks: ReleaseTrack[];
  productsIds: number[];
  availableQuantity?: number | null;
  purchaseLimit?: number | null;
  productName: string;
}

export const ProductModalFooterButtons = ({
  productPurchasedByCurrentUser,
  productId,
  releaseTracks,
  productsIds,
  availableQuantity,
  purchaseLimit,
  productName,
}: ProductModalFooterButtonsProps) => {
  const history = useHistory();
  const {
    cart,
    handleCheckout,
    isCheckingout,
    handleReplaceBundle,
    handleRemoveBundle,
  } = useProductCart();
  const { isMobile } = useMediaQueryBreakpoint();
  const isVisitingOwnProfile = useIsVisitingOwnProfile();

  if (productPurchasedByCurrentUser) {
    return (
      <FixedMinWidthButton
        variant={ButtonVariant.OUTLINED}
        onClick={() => {
          history.push(getMyLibraryRoute());
        }}
      >
        My purchases
      </FixedMinWidthButton>
    );
  }

  const productInCart = cart.find((item) => item.product_id === productId);
  return (
    <>
      <FixedMinWidthButton
        variant={ButtonVariant.OUTLINED}
        minWidth={isMobile ? "120px" : undefined}
        onClick={() => {
          productInCart
            ? handleRemoveBundle(productId)
            : handleReplaceBundle(productId, releaseTracks, productsIds);
        }}
        disabled={isVisitingOwnProfile}
      >
        {productInCart ? "Remove from Cart" : "Add to cart"}
      </FixedMinWidthButton>
      <FixedMinWidthButton
        minWidth={isMobile ? "120px" : undefined}
        onClick={() => {
          let updatedCart = cart;
          if (!productInCart) {
            if (purchaseLimit && availableQuantity === 0) {
              toast.error(`${productName}  is out of stock`);
              return;
            }
            updatedCart = handleReplaceBundle(
              productId,
              releaseTracks,
              productsIds,
            );
          }

          void handleCheckout(updatedCart);
        }}
        loading={isCheckingout}
        disabled={isVisitingOwnProfile}
      >
        {isMobile ? "Checkout" : "Checkout with bundle"}
      </FixedMinWidthButton>
    </>
  );
};
