import { useAtomValue } from "jotai/react";
import { darkModeAtom } from "../../../../atoms/user/darkModeAtom";
import { Review } from "../../../../store/models/review";
import {
  ReviewsListReviewsContainer,
  ReviewsListV2Container,
  ReviewsListV2Header,
  ReviewsListVerticalBar,
  ReviewListReviewElementContainer,
} from "./ReviewsListV2.styles";
import blackStar from "../../../assets/star_filled.svg";
import whiteStar from "../../../assets/star_filled_white.svg";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { ReviewComponent } from "../../ReviewComponent/ReviewComponent";
import { Box } from "@mui/material";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";

interface ReviewsListV2Props {
  averageRating: number;
  ratingsCount?: number;
  reviews: Review[];
  showMore: () => void;
  disabled: boolean;
  loading: boolean;
  totalPages: number;
}

export const ReviewsListV2 = ({
  averageRating,
  ratingsCount,
  reviews,
  showMore,
  disabled,
  loading,
  totalPages,
}: ReviewsListV2Props) => {
  const isDarkMode = useAtomValue(darkModeAtom);

  return (
    <ReviewsListV2Container>
      <ReviewsListV2Header>
        <img src={isDarkMode ? whiteStar : blackStar} alt="star" />
        <Text variant={TextStyleVariant.S2}>{averageRating}</Text>
        <ReviewsListVerticalBar />
        <Text variant={TextStyleVariant.S2}>
          {ratingsCount === 1
            ? `1 Total review`
            : `${ratingsCount} Total reviews`}
        </Text>
      </ReviewsListV2Header>
      <ReviewsListReviewsContainer>
        {reviews.map((review) => (
          <ReviewListReviewElementContainer key={review.id}>
            <ReviewComponent review={review} />
          </ReviewListReviewElementContainer>
        ))}
      </ReviewsListReviewsContainer>
      <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
        {ratingsCount !== undefined && totalPages > 1 && (
          <Button
            style={{ minWidth: "140px" }}
            disabled={disabled}
            onClick={showMore}
            loading={loading}
            variant={ButtonVariant.OUTLINED}
          >
            Show More Reviews
          </Button>
        )}
      </Box>
    </ReviewsListV2Container>
  );
};
