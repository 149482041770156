import { RESET } from "jotai/utils";
import { atom } from "jotai";
import { GeneratedAssetQueryProps } from "../../../hooks/userHooks/useGeneratedAssetQuery";

export type ModalAssets = Record<string, GeneratedAssetQueryProps>;
export const assetViewerModalOpenAtom = atom(
  false,
  (_get, set, open: boolean | typeof RESET) => {
    set(assetViewerModalOpenAtom, open);
  },
);

export const assetViewerModalAssetsAtom = atom(
  {} as Record<string, ModalAssets>,
  (
    _get,
    set,
    assets:
      | Record<string, ModalAssets>
      | ((
          oldAssets: Record<string, ModalAssets>,
        ) => Record<string, ModalAssets>),
  ) => {
    const results =
      typeof assets === "function"
        ? assets(_get(assetViewerModalAssetsAtom))
        : assets;

    set(assetViewerModalAssetsAtom, results);
    set(assetViewerModalOpenAtom, Boolean(results));
  },
);
