import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { EngineEarsError } from "../../../store/actions/errorStore";
import { ReleaseTrack } from "../../../store/models/release";
import { fetchReleaseTrackById } from "../releaseAPI";
import { useAtomValue } from "jotai";
import { activeUserAtom } from "../../../atoms/user/activeUserAtom";

export const useFetchReleaseTrackQuery = (releaseTrackId = 0) => {
  const slimUser = useAtomValue(activeUserAtom);
  return useQuery({
    enabled: Boolean(releaseTrackId),
    queryKey: [
      QUERY_KEYS.FETCH_RELEASE_TRACK_BY_ID,
      releaseTrackId,
      slimUser?.id,
    ],
    queryFn: async () => {
      const response = await fetchReleaseTrackById(releaseTrackId);
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as ReleaseTrack;
    },
  });
};
