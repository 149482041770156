import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";

export const PlayButtonOverlay = () => (
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      pointerEvents: "none",
    }}
  >
    <Box
      sx={{
        borderRadius: "50%",
        width: "48px",
        height: "48px",
        backgroundColor: "rgba(0,0,0,0.5)",
        position: "absolute",
        top: "-4px",
        left: "-4px",
        zIndex: -1,
      }}
    ></Box>
    <FontAwesomeIcon icon={faCirclePlay} fontSize="40px" color="white" />
  </Box>
);
