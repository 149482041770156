import { useAtomValue, useSetAtom } from "jotai";
import { useHistory } from "react-router-dom";
import { AuthFlows, AuthSteps } from "../../constants/authSteps";
import User from "../../store/models/user";
import {
  getAdminDashboardRoute,
  getDashboardRoute,
} from "../../store/utils/routeGetters";
import {
  authModalOpenAtom,
  currentFlowAtom,
  currentSignUpStepAtom,
  phoneNumberLoginAtom,
} from "../../stories/components/Auth/atoms";
import { useMediaQueryBreakpoint } from "../useMediaQuery";
import { DISCIPLINE_TYPE, getPrimaryDiscipline } from "../user";
import { isAdmin } from "../useIsAdmin";

export const useCheckUserLoginFlow = () => {
  const setFlow = useSetAtom(currentFlowAtom);
  const setStep = useSetAtom(currentSignUpStepAtom);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const { isMobile, isTablet } = useMediaQueryBreakpoint();
  const history = useHistory();
  const isPhoneLogin = useAtomValue(phoneNumberLoginAtom);

  const checkUserAndSetFlow = (user: User, skipFlow?: boolean) => {
    const primaryDiscipline = getPrimaryDiscipline(user);
    const isEmailVerified = user.email && user.email_verified;
    const isPhoneAdded = user.phone_number?.phone_number;
    const isPhoneVerified = user.phone_number?.verified;

    if (skipFlow) {
      setAuthModalOpen(false);
    } else {
      if (primaryDiscipline === DISCIPLINE_TYPE.NO_TYPE) {
        setFlow(AuthFlows.STANDARD_RESUME_LOGIN);
        if (isPhoneLogin) {
          setFlow(AuthFlows.PHONE_RESUME_LOGIN);
        }
      } else if (!isEmailVerified) {
        if (primaryDiscipline === DISCIPLINE_TYPE.STUDIO_MANAGER) {
          setFlow(AuthFlows.STUDIO_OWNER_RESUME_LOGIN);
        } else {
          setFlow(AuthFlows.STANDARD_RESUME_LOGIN);
          if (isPhoneLogin) {
            setFlow(AuthFlows.PHONE_RESUME_LOGIN);
          }
        }
        setStep(AuthSteps.VERIFY_EMAIL);
      } else if (!isPhoneAdded) {
        if (primaryDiscipline === DISCIPLINE_TYPE.STUDIO_MANAGER) {
          setFlow(AuthFlows.STUDIO_OWNER_RESUME_LOGIN);
          setStep(AuthSteps.ADD_PHONE);
        } else if (
          primaryDiscipline === DISCIPLINE_TYPE.A_AND_R ||
          primaryDiscipline === DISCIPLINE_TYPE.ADMIN
        ) {
          setFlow(AuthFlows.AANDR_RESUME_LOGIN);
          setStep(AuthSteps.AANDR_ADD_PHONE);
        } else {
          setFlow(AuthFlows.STANDARD_RESUME_LOGIN);
          setStep(AuthSteps.ADD_PHONE);
        }
      } else if (!isPhoneVerified) {
        if (primaryDiscipline === DISCIPLINE_TYPE.STUDIO_MANAGER) {
          setFlow(AuthFlows.STUDIO_OWNER_RESUME_LOGIN);
          setStep(AuthSteps.VERIFY_PHONE);
        } else if (
          primaryDiscipline === DISCIPLINE_TYPE.A_AND_R ||
          primaryDiscipline === DISCIPLINE_TYPE.ADMIN
        ) {
          setFlow(AuthFlows.AANDR_RESUME_LOGIN);
          setStep(AuthSteps.AANDR_VERIFY_PHONE);
        } else {
          setFlow(AuthFlows.STANDARD_RESUME_LOGIN);
          setStep(AuthSteps.VERIFY_PHONE);
        }
      } else {
        if (isMobile || isTablet) {
          const dashboardRoute = isAdmin(user)
            ? getAdminDashboardRoute()
            : getDashboardRoute();
          history.push(dashboardRoute);
        }
        setAuthModalOpen(false);
      }
    }
  };

  return { checkUserAndSetFlow };
};
