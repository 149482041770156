import List, { ListProps } from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import { styled } from "@mui/material";
import { useMemo } from "react";
import { GeneratedAssetListItem } from "./GeneratedAssetListItem";
import { ModalAssets } from "./atoms";

const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  bgcolor: "background.paper",
  position: "relative",
  overflow: "visible",
  "& ul": { padding: 0 },
}));

interface GeneratedAssetListProps extends Omit<ListProps, "subheader"> {
  images?: Record<string, ModalAssets>;
}

const ListContainer = styled("li")(() => {
  return {
    width: "100%",
  };
});

export const GeneratedAssetList = ({
  images = {},
  ...props
}: GeneratedAssetListProps) => {
  const listItems = useMemo(() => {
    return Object.entries(images).map(([key, values]) => {
      return (
        <ListContainer key={`asset-${key}`}>
          <ul>
            <ListSubheader>{`${key}`}</ListSubheader>
            {Object.entries(values).map(([itemKey, item], index) => (
              <GeneratedAssetListItem
                key={itemKey}
                image={item}
                name={itemKey}
                sx={(theme) => ({
                  backgroundColor:
                    index % 2 === 0
                      ? theme.palette.background.default
                      : theme.palette.background.paper,
                })}
              />
            ))}
          </ul>
        </ListContainer>
      );
    });
  }, [images]);
  return <StyledList {...props}>{listItems}</StyledList>;
};
