import "./TransactionBookingScreen.css";
import GenerateBookingSelectService from "../../components/GenerateBooking/GenerateBookingSelectService";
import TransactionBookingDetailsHeader from "./TransactionBookingDetailsHeader";

import {
  updateTitle,
  updateArtistName,
} from "../../../store/actions/mixMasterCartsStore";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { Textfield } from "../../../stories/elements/Textfield1/Textfield1";
import {
  Text,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { InProgressProjectSelector } from "../../components/GenerateBooking/InProgressProjectSelector";
import { useIsAdmin } from "../../../hooks/useIsAdmin";

interface TransactionBookingMixMasterDetailsProps {
  isLoggedInUserGeneratingBooking: boolean;
  disabled?: boolean;
}

const TransactionBookingMixMasterDetails = ({
  isLoggedInUserGeneratingBooking,
  disabled = false,
}: TransactionBookingMixMasterDetailsProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.accountInfo);
  const {
    cart: { title, artistName },
  } = useAppSelector((state) => state.mixMasterCartsStore);
  const isAdmin = useIsAdmin(user);

  const cssPrefix = "transaction-booking-screen_";
  return (
    <>
      {isLoggedInUserGeneratingBooking && (
        <div className={`${cssPrefix}booking-details-container`}>
          <Text
            className={`${cssPrefix}booking-details-header`}
            size={TEXT_SIZE.LARGE}
            weight={TEXT_WEIGHT.SEMI_BOLD}
          >
            Booking details
          </Text>
          {isLoggedInUserGeneratingBooking && !isAdmin && (
            <div className={`${cssPrefix}booking-details-input`}>
              <InProgressProjectSelector />
            </div>
          )}
          <div className={`${cssPrefix}booking-details-input`}>
            <Textfield
              label="Artist name"
              value={artistName}
              onChange={(e) => dispatch(updateArtistName(e.target.value))}
              disabled={disabled}
            />
          </div>
          <div className={`${cssPrefix}booking-details-input`}>
            <Textfield
              label="Project name"
              value={title}
              onChange={(e) => dispatch(updateTitle(e.target.value))}
              disabled={disabled}
            />
          </div>
        </div>
      )}
      <div className={`${cssPrefix}select-service-container`}>
        <TransactionBookingDetailsHeader recordingService={null} />
        <GenerateBookingSelectService
          index={1}
          isLoggedInUserGeneratingBooking={isLoggedInUserGeneratingBooking}
        />
      </div>
    </>
  );
};

export default TransactionBookingMixMasterDetails;
