import { useParams } from "react-router-dom";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import Countdown from "react-countdown";
import { Typography } from "@mui/material";
import { ProfileTwitchStream } from "./ProfileTwitchStream";
import { ProfileYouTubeStream } from "./ProfileYouTubeStream";
import { useState } from "react";
import { activeUserAtom } from "../../../atoms/user/activeUserAtom";
import { useAtomValue } from "jotai";

export const LivestreamTab = () => {
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const [hasMetCountdown, setHasMetCountdown] = useState(false);
  const activeUser = useAtomValue(activeUserAtom);
  const isOwnUserProfile =
    activeUser?.username === userData?.username || activeUser?.is_superuser;

  // TODO: Remove this code after the smino livestream.
  if (!isOwnUserProfile && userData?.username === "smino" && !hasMetCountdown) {
    return (
      <>
        <Typography variant={"body1"} style={{ fontSize: "22px" }}>
          <strong>Minutes to Nirvana:</strong>{" "}
          <Countdown
            onComplete={() => {
              setHasMetCountdown(true);
            }}
            date={new Date("2024-12-06T03:00:00Z")}
          />
        </Typography>
      </>
    );
  }

  if (userData?.profile?.youtube_livestream_id) {
    return (
      <ProfileYouTubeStream streamId={userData.profile.youtube_livestream_id} />
    );
  }

  if (!userData?.profile?.twitch_username) {
    return null;
  }

  return <ProfileTwitchStream channelName={userData.profile.twitch_username} />;
};
