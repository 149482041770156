/* eslint-disable import/no-unused-modules */
import { Dispatch, SetStateAction, useMemo } from "react";
import { useAccountStatus } from "../../../../hooks/accountHooks";
import { useEngineerCanHostServices } from "../../../../hooks/useEngineerCanHostServices";
import Engineer from "../../../../store/models/engineer";
import { ProjectType } from "../../../../store/models/project";
import { RecordingService } from "../../../../store/models/recording";
import Service from "../../../../store/models/service";
import User from "../../../../store/models/user";
import { getServiceFromServiceType } from "../../../../store/utils/serviceUtils";
import { ContactInfoModal } from "../../../components/ContactInfoModal/ContactInfoModal";
import { BaseModal } from "../../../core-ui/components/BaseModal/BaseModal";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import { FormType } from "../constants";
import { ServicesFormModalContent } from "./ServicesFormModalContent";
import { StyledServicesFormModalBody } from "./ServicesTab.styles";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";

export interface ServicesFormModalProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  engineer: Engineer;
  onChangeService: (selectedProjectType: OptionType) => void;
  selectedServiceType: ProjectType;
  existingServices: (Service | RecordingService)[];
  formType: FormType;
  userData: User;
}

export const ServicesFormModal = ({
  isOpen,
  setOpen,
  engineer,
  onChangeService,
  selectedServiceType,
  existingServices,
  formType,
  userData,
}: ServicesFormModalProps) => {
  const canHostServices = useEngineerCanHostServices(engineer);
  const { emailVerified, phoneVerified } = useAccountStatus();
  const { isMobile } = useMediaQueryBreakpoint();

  const serviceName = useMemo(() => {
    let suffix = "";
    if (selectedServiceType === ProjectType.RECORDING) {
      suffix = " (hourly)";
    }
    return getServiceFromServiceType(selectedServiceType, true) + suffix;
  }, [selectedServiceType]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const modalHeader = useMemo(() => {
    let header = "";
    if (formType === FormType.EDIT) {
      header = "Edit Service";
    } else if (formType === FormType.CREATE) {
      header = "Create Service";
    }

    if (canHostServices) {
      header += `: ${serviceName}`;
    }

    return header;
  }, [canHostServices, formType, serviceName]);

  if (isOpen && (!emailVerified || !phoneVerified)) {
    return (
      <ContactInfoModal
        onClose={() => {
          handleCloseModal();
        }}
      />
    );
  }

  return (
    <BaseModal
      open={isOpen}
      setOpen={setOpen}
      header={modalHeader}
      customModalStyle={{
        boxSizing: "border-box",
        width: "90%",
        maxWidth: "968px", // As per design
      }}
      style={{
        width: "100%",
        maxWidth: "unset",
        height: isMobile ? "100vh" : "100%",
      }}
      customBodyStyle={StyledServicesFormModalBody({ $isMobile: isMobile })}
      fullScreenOnMobile
    >
      <ServicesFormModalContent
        engineer={engineer}
        onChangeService={onChangeService}
        selectedServiceType={selectedServiceType}
        existingServices={existingServices}
        formType={formType}
        userData={userData}
        onCancel={handleCloseModal}
        key={selectedServiceType}
        canHostServices={Boolean(canHostServices)}
      />
    </BaseModal>
  );
};
