import { Box, styled } from "@mui/material";

export const WorkingHourContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  columnGap: "24px",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "stretch",
    rowGap: "8px",
  },

  ".select-time-drop-down-container": {
    flex: 1,
    columnGap: "8px",
  },
}));

export const SelectWorkingHoursContainer = styled(Box)(() => ({
  display: "flex",
  padding: "24px",
  flexDirection: "column",
  alignItems: "center",
  gap: "24px",
  alignSelf: "stretch",
}));

export const SelectWorkingHoursHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "12px",
  alignSelf: "stretch",
}));
