import { useEffect, useMemo } from "react";
import { addStudioRooms, fetchStudioRooms } from "../store/actions/studio";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { ProjectType } from "../store/models/project";
import { BasicStudioRoom, Studio } from "../store/models/studio";
import { selectTeamFields } from "../store/selectors/teamSelectors";

// Returns whether a studio is configured. A studio is configured
// if they have a profile display name and a location. Does NOT
// take into account studio verification status.
const isStudioConfigured = (studio: Studio | undefined) => {
  if (!studio) {
    return false;
  }

  return Boolean(studio.studio_profile?.display_name && studio.location);
};

export const useIsStudioConfigured = (studio: Studio | undefined): boolean => {
  return useMemo(() => {
    return isStudioConfigured(studio);
  }, [studio]);
};

export const getRecordingSessionLocalTimeUsingTimezone = ({
  first_choice_datetime: firstChoiceDateTime,
  studio_room: studioRoom,
}: {
  first_choice_datetime: string;
  studio_room?: BasicStudioRoom;
}): Date => {
  const startTimeDateTime = new Date(firstChoiceDateTime);
  const timeZone = studioRoom?.studio?.timezone;
  const startTime = timeZone
    ? new Date(
        startTimeDateTime.toLocaleString("en-US", {
          timeZone,
        }),
      )
    : startTimeDateTime;
  return startTime;
};

export const useStudioTeam = (studioId: number | undefined) => {
  const { associatedTeams } = useAppSelector(selectTeamFields);
  return useMemo(() => {
    if (!studioId) {
      return undefined;
    }
    return associatedTeams.find((team) => team.studio_id === studioId);
  }, [associatedTeams, studioId]);
};

export const useIsPrimaryStudioManager = (
  studioId: number | undefined,
): boolean => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const studioTeam = useStudioTeam(studioId);

  return useMemo(() => {
    if (!user || !studioTeam) {
      return false;
    }
    if (studioTeam.managers.length === 0) {
      return false;
    }
    return studioTeam.managers[0].id === user.id;
  }, [studioTeam, user]);
};

export const useIsAffiliatedEngineerWithStudio = (
  studio: Studio | undefined,
): boolean => {
  const user = useAppSelector((state) => state.accountInfo.user);

  return useMemo(() => {
    if (!user || !studio) {
      return false;
    }
    if (!user.engineer || user.engineer.deleted) {
      return false;
    }

    // Use the studio.studio_team to get the studio team members, as `useStudioTeam` is only populated
    // for studio managers and admins.
    return studio.studio_team.members.some((member) => member.id === user.id);
  }, [user, studio]);
};

export const useIsAffiliatedManagerWithStudio = (
  studio: Studio | undefined,
): boolean => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const studioTeam = studio?.studio_team;
  return useMemo(() => {
    if (!user || !studioTeam) {
      return false;
    }
    if (!user.studio_manager || user.studio_manager.deleted) {
      return false;
    }
    return studioTeam.managers.some((member) => member.id === user.id);
  }, [user, studioTeam]);
};

export const useGetActiveStudioRoom = (
  activeServiceType: ProjectType | undefined,
  studioRoomId: number | undefined,
  studioId: number | undefined,
) => {
  const dispatch = useAppDispatch();
  const studioRoom = useAppSelector((state) =>
    state.studiosSlice[studioId ?? ""]?.studio_rooms?.find(
      (room) => room.id === (studioRoomId ? +studioRoomId : -1),
    ),
  );

  useEffect(() => {
    if (activeServiceType !== ProjectType.RECORDING) return;
    if (studioRoom) return;
    if (!studioId) return;
    dispatch(fetchStudioRooms({ studio_id: studioId }))
      .unwrap()
      .then((studioRooms) => {
        dispatch(
          addStudioRooms({ studio: { id: studioId }, rooms: studioRooms }),
        );
      });
  }, [studioRoom, activeServiceType, studioId]);

  if (activeServiceType !== ProjectType.RECORDING) return;
  return studioRoom;
};
