import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { uploadFilePromise } from "../../store/utils/fetch";
import { IDLE_PROGRESS } from "../../stories/screens/ProfileScreen/constants";
import { useGetServiceFeaturedVideoSignedURL } from "./useGetServiceFeaturedVideoSignedURL";

export const useOnUploadServiceVideo = () => {
  const [uploadingVideo, setUploadingVideo] = useState<File>();
  const [progress, setProgress] = useState(IDLE_PROGRESS);
  const { mutateAsync: getServiceFeaturedVideoSignedURL } =
    useGetServiceFeaturedVideoSignedURL();
  const xhr = useMemo(() => {
    return new XMLHttpRequest();
  }, []);

  const uploadServiceVideo = async (acceptedFile: File) => {
    try {
      setProgress(0);

      const { signed_url, entity_video } =
        await getServiceFeaturedVideoSignedURL({
          file_name: acceptedFile.name,
          content_type: acceptedFile.type,
        });

      await uploadFilePromise(
        acceptedFile,
        signed_url,
        (progress) => setProgress(progress),
        xhr,
      );

      setProgress(IDLE_PROGRESS);

      return entity_video.id;
    } catch (e) {
      toast.error(
        "Something went wrong with your upload. Reach out for support.",
      );
    }
  };

  const cancelRequest = () => {
    xhr.abort();
  };

  return {
    uploadServiceVideo,
    progress,
    uploadingVideo,
    setUploadingVideo,
    cancelRequest,
  };
};
