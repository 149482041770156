import { Box } from "@mui/material";
import { Release } from "../../../store/models/release";
import { Text, TEXT_LINE_HEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Link } from "react-router-dom";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";

interface HardCodedReleaseCreditsProps {
  release?: Release | null;
}

export const HardCodedReleaseCredits = ({
  release,
}: HardCodedReleaseCreditsProps) => {
  if (!release) return <></>;
  return (
    <>
      {release?.artist_user?.username === "officialthomasaustin" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Text bold variant={TextStyleVariant.S2}>
            Credits
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            <Text
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              Recorded at
            </Text>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              key={"satstudios"}
              to={getProfileScreenRoute("satstudios")}
            >
              <Text
                variant={TextStyleVariant.P1}
                lineHeight={TEXT_LINE_HEIGHT.NORMAL}
                bold
              >
                S.A.T. Studios {`@${"satstudios"}`}
              </Text>
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Text
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              Produced by
            </Text>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              key={"tope"}
              to={getProfileScreenRoute("tope")}
            >
              <Text
                variant={TextStyleVariant.P1}
                lineHeight={TEXT_LINE_HEIGHT.NORMAL}
                bold
              >
                Tope {`@${"tope"}`}
              </Text>
            </Link>
            <a
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              key={"masonmadethis"}
              href={"https://instagram.com/masonmadethis"}
            >
              <Text
                variant={TextStyleVariant.P1}
                lineHeight={TEXT_LINE_HEIGHT.NORMAL}
                bold
              >
                Mason Marino {`@${"masonmadethis"}`}
              </Text>
            </a>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              key={"cristianmadeit"}
              to={getProfileScreenRoute("cristianmadeit")}
            >
              <Text
                variant={TextStyleVariant.P1}
                lineHeight={TEXT_LINE_HEIGHT.NORMAL}
                bold
              >
                Chrisian Hakala {`@${"cristianmadeit"}`}
              </Text>
            </Link>
            <a
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              key={"callmeclaydough"}
              href={"https://instagram.com/callmeclaydough"}
            >
              <Text
                variant={TextStyleVariant.P1}
                lineHeight={TEXT_LINE_HEIGHT.NORMAL}
                bold
              >
                Clay Terry (ClayDough) {`@${"callmeclaydough"}`}
              </Text>
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              key={"joelsalm.i"}
              href={"https://instagram.com/joelsalm.i"}
            >
              <Text
                variant={TextStyleVariant.P1}
                lineHeight={TEXT_LINE_HEIGHT.NORMAL}
                bold
              >
                J0rb {`@${"joelsalm.i"}`}
              </Text>
            </a>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Text
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              Featured artists:
            </Text>
            <a
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              key={"bookofmalachii_"}
              href={"https://instagram.com/bookofmalachii_"}
            >
              <Text
                variant={TextStyleVariant.P1}
                lineHeight={TEXT_LINE_HEIGHT.NORMAL}
                bold
              >
                MALACHI {`@${"bookofmalachii_"}`}
              </Text>
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              key={"iamjalenjackson"}
              href={"https://instagram.com/iamjalenjackson"}
            >
              <Text
                variant={TextStyleVariant.P1}
                lineHeight={TEXT_LINE_HEIGHT.NORMAL}
                bold
              >
                Jalen Jackson {`@${"iamjalenjackson"}`}
              </Text>
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              key={"conormichaelsmith"}
              href={"https://instagram.com/conormichaelsmith"}
            >
              <Text
                variant={TextStyleVariant.P1}
                lineHeight={TEXT_LINE_HEIGHT.NORMAL}
                bold
              >
                Conor Michael Smith {`@${"conormichaelsmith"}`}
              </Text>
            </a>
          </div>
        </Box>
      )}
    </>
  );
};
