import { useFetchProductsByReleaseIdQuery } from "../../../api/products/hooks/usefetchProductsByReleaseIdQuery";
import { DollarFormatter } from "../../../store/utils/formatUtils";
import { ProductCardsList } from "../ProductCardsList/ProductCardsList";

interface FeaturedReleaseProductsProps {
  featuredReleaseId?: number;
}

export const FeaturedReleaseProducts = ({
  featuredReleaseId,
}: FeaturedReleaseProductsProps) => {
  const { data, isPending } =
    useFetchProductsByReleaseIdQuery(featuredReleaseId);
  if (!featuredReleaseId) {
    return null;
  }
  const normalizedProductsData = data
    ? data.map((product) => {
        return {
          name: product.name,
          description: product.description,
          totalPrice: DollarFormatter().format(parseFloat(product.total_price)),
          productId: product.id,
          releaseId: product.release_id,
          coverPhoto: product.cover_photo,
          productPurchasedByCurrentUser:
            product.product_purchased_by_current_user,
          availableQuantity: product.available_quantity,
          purchaseLimit: product.purchase_limit,
        };
      })
    : [];

  return (
    <ProductCardsList loading={isPending} products={normalizedProductsData} />
  );
};
