import { Box, SxProps, Theme, useTheme } from "@mui/material";
import { ReactNode } from "react";
import {
  MODAL_STICKY_FOOTER_ID,
  useBottomTabBarOverlayView,
} from "../../../components/Navigation/BottomNav/useBottomTabBarOverlayView";

interface ModalStickyFooterProps {
  children: ReactNode;
  overrideContainerStyle?: SxProps<Theme>;
}

export const ModalStickyFooter = ({
  children,
  overrideContainerStyle,
}: ModalStickyFooterProps) => {
  const theme = useTheme();
  let containerStyle = {};
  if (overrideContainerStyle) {
    if (typeof overrideContainerStyle === "function") {
      containerStyle = overrideContainerStyle(theme) || {};
    } else {
      containerStyle = overrideContainerStyle;
    }
  }

  return useBottomTabBarOverlayView(
    // Since we want it to appear on all screen, `isMobile` should be true all the time
    true,
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        columnGap: "16px",
        padding: "24px 0",
        ...containerStyle,
      }}
    >
      {children}
    </Box>,
    undefined,
    MODAL_STICKY_FOOTER_ID,
  );
};
