import { Box, Fade, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";
import { WaveForm, WaveSurfer } from "wavesurfer-react";
import { default as WaveSurferRef } from "wavesurfer.js";
import { useAddStyleToShadowRoot } from "../../../hooks/audioPlayerHooks/useAddStyleToShadowRoot";
import { useGetCurrentSeekTime } from "../../../hooks/audioPlayerHooks/useGetCurrentSeekTime";
import { useMoveToSeekTime } from "../../../hooks/audioPlayerHooks/useMoveToSeekTime";
import { useSetupOnClick } from "../../../hooks/audioPlayerHooks/UseSetupOnClick";
import { DEFAULT_TIME } from "../../../hooks/audioPlayerHooks/waveformHooks";
import {
  convertWaveformDurationToReadableTime,
  toggleWaveFormColors,
} from "../../../hooks/audioPlayerHooks/waveformUtils";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { Size } from "../SessionScheduleGuide/SessionScheduleGuide";

interface FeaturedTrackPlayer {
  id: number;
  source: string;
  isPlayingOnFooter: boolean;
  isSameProject: boolean;
  showTime?: boolean;
}

export const FeaturedTrackPlayer = ({
  id,
  source,
  isPlayingOnFooter,
  isSameProject,
  showTime = true,
}: FeaturedTrackPlayer) => {
  const currentTrack = useRef<WaveSurferRef | null>(null);
  const { palette } = useTheme();
  const waveformId = `waveform-${id}`;
  const [width, setWidth] = useState(0); // This holds the container width;
  const containerRef = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState<string>(DEFAULT_TIME);
  const currentSeekTime = useGetCurrentSeekTime(isSameProject);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    toggleWaveFormColors(currentTrack.current, isPlayingOnFooter);
  }, [isPlayingOnFooter]);

  const onResize = useCallback(
    (size: Size) => {
      setTimeout(() => {
        setWidth(size.width || 0);
      }, 0);
    },
    [setWidth],
  );
  useResizeObserver({
    ref: containerRef,
    box: "border-box",
    onResize,
  });

  useAddStyleToShadowRoot(
    waveformId,
    width ? `${width}px` : undefined,
    waveformId,
  );

  useEffect(() => {
    // Cleanup function to be executed when the component unmounts or dependencies change
    return () => {
      if (currentTrack.current) {
        currentTrack.current.unAll();
        currentTrack.current.destroy();
        currentTrack.current = null;
      }
      if (window && id && window.surferidze) {
        delete window.surferidze[id];
      }
    };
  }, [source, id]);

  useSetupOnClick(currentTrack, !isPlayingOnFooter);
  useMoveToSeekTime(currentTrack, isSameProject);

  const handleWSMount = useCallback(
    async (waveSurfer: WaveSurferRef | null) => {
      if (waveSurfer === null) return;
      currentTrack.current = waveSurfer;
      void currentTrack.current?.load(source).catch(() => {});
      currentTrack.current?.once("ready", (duration) => {
        setLoaded(true);
        currentTrack.current?.setVolume(0);
        setDuration(convertWaveformDurationToReadableTime(duration));
      });
      currentTrack.current.on("error", () => {});
      currentTrack.current.on("destroy", () => {
        currentTrack.current?.unAll();
        currentTrack.current?.destroy();
      });
      if (window && id) {
        if (!window.surferidze) {
          window.surferidze = {};
        }
        window.surferidze[id] = currentTrack.current;
      }
    },
    [source, id],
  );

  return (
    <Fade in={loaded}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "8px",
        }}
        ref={containerRef}
      >
        {showTime && (
          <Text color={TextColor.TERTIARY} variant={TextStyleVariant.P1}>
            {currentSeekTime?.current}
          </Text>
        )}
        <Box sx={{ flex: "1" }}>
          <WaveSurfer
            plugins={[]}
            onMount={handleWSMount}
            container={`#${waveformId}`}
            waveColor={
              isPlayingOnFooter
                ? palette.standardColor.Gray[400]
                : palette.standardColor.Gray[200]
            }
            progressColor={
              isPlayingOnFooter
                ? palette.standardColor.BoomyOrange[400]
                : palette.standardColor.BoomyOrange[200]
            }
            height={50}
            cursorColor={palette.text.primary}
          >
            <WaveForm id={waveformId} />
          </WaveSurfer>
        </Box>
        {showTime && (
          <Box sx={{ textAlign: "right" }}>
            <Text color={TextColor.TERTIARY} variant={TextStyleVariant.P1}>
              {duration}
            </Text>
          </Box>
        )}{" "}
      </Box>
    </Fade>
  );
};
