import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, HTMLAttributes, useEffect } from "react";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import "./NavSearchBar.css";
import { NavSearchFilterButton } from "./components/NavSearchFilterButton/NavSearchFilterButton";
import { NavSearchResultsDropDown } from "./components/NavSearchResultsDropDown/NavSearchResultsDropDown";
import { useAtomValue } from "jotai";
import {
  showTopNavAtom,
  topNavContainerHeightAtom,
} from "../../../../atoms/navAtoms";
import { AutocompleteSuggestion } from "../../../../store/models/autocomplete";

interface NavSearchBarProps extends HTMLAttributes<HTMLInputElement> {
  autocompleteSuggestions: AutocompleteSuggestion[];
  handleSearchQuery: (event: FormEvent<HTMLFormElement>) => void;
  isFetchingAutocomplete: boolean;
  onListItemClick: () => void;
  showResultsDropDown: boolean;
  setShowResultsDropDown: (show: boolean) => void;
  searchTerm?: string;
}

/**
 * Search Bar component made for the Navigation Header.
 * Fetching logic lives in SitewideSearchBar component.
 */
export const NavSearchBar = ({
  autocompleteSuggestions,
  handleSearchQuery,
  isFetchingAutocomplete,
  onBlur,
  onChange,
  onFocus,
  onListItemClick,
  showResultsDropDown,
  setShowResultsDropDown,
  searchTerm,
}: NavSearchBarProps) => {
  const showHeader = useAtomValue(showTopNavAtom);
  const topNavContainerHeight = useAtomValue(topNavContainerHeightAtom);
  const isTooNarrowForDropDown = useMediaQuery("(max-width: 920px)");

  useEffect(() => {
    if (showResultsDropDown && !showHeader) {
      setShowResultsDropDown(false);
    }
  }, [showHeader, showResultsDropDown]);

  const showAttachedDropDown = Boolean(
    showResultsDropDown && searchTerm && !isTooNarrowForDropDown,
  );

  return (
    <>
      <div className="nav-search-bar-container">
        <form
          className={`nav-search-bar ${showAttachedDropDown ? "show" : ""}`}
          onSubmit={handleSearchQuery}
        >
          <input
            className="nav-input"
            name="searchInput"
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            placeholder="Search Recording Studios & Audio Engineers"
            value={searchTerm}
          />
          <NavSearchFilterButton />
          <button
            className={`nav-search-button ${showAttachedDropDown ? "show" : ""}`}
            name="searchSubmit"
            type="submit"
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
        {!isTooNarrowForDropDown && (
          <NavSearchResultsDropDown
            className="nav-search-results-container--attached"
            data={autocompleteSuggestions}
            loading={isFetchingAutocomplete}
            onListItemClick={onListItemClick}
            show={showResultsDropDown}
          />
        )}
      </div>
      {isTooNarrowForDropDown && (
        <NavSearchResultsDropDown
          className="nav-search-results-container--full-width"
          data={autocompleteSuggestions}
          loading={isFetchingAutocomplete}
          onListItemClick={onListItemClick}
          show={showResultsDropDown}
          style={{ top: topNavContainerHeight }}
        />
      )}
    </>
  );
};
