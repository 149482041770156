import { Box } from "@mui/material";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";
import { ProjectType } from "../../../../store/models/project";
import { getProfileScreenRoute } from "../../../../store/utils/routeGetters";
import missingFileArt from "../../../assets/BlankAlbumArt.png";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import {
  FooterPlayerTrackTitle,
  TRACK_TITLE_DUPLICATE_GAP,
} from "./FooterPlayerTrackTitle";
import {
  StyledFooterProjectInfoContainer,
  StyledFooterProjectInfoImage,
  StyledFooterProjectInfoProjectDetails,
  StyledFooterProjectInfoSubContainer,
  StyledFooterProjectInfoTrackInfo,
  StyledFooterProjectInfoUserInfo,
} from "./FooterProjectInfo.styles";

const EXPANDED_SIZE = 312;
export const COLLAPSED_SIZE = 54;
const TRACK_TITLE_VELOCITY = 22;

// It probably won't ever recording in the near future, but we should cover all the cases.
const projectTypeReadableRole = new Map<ProjectType, string>([
  [ProjectType.NO_TYPE, "Engineer"],
  [ProjectType.RECORDING, "Recording done by"],
  [ProjectType.MIXING, "mixed by"],
  [ProjectType.MASTERING, "mastered by"],
  [ProjectType.TWO_TRACK_MIXING, "mixed by"],
  [ProjectType.ATMOS_MIXING, "mixed by"],
]);

export interface StyledFooterProjectInfoProps {
  artworkSource?: string;
  artistUser?: string;
  engineerUser?: string;
  artistUsername?: string;
  engineerUsername?: string;
  trackTitle?: string;
  serviceType?: ProjectType;
  onClick?: MouseEventHandler<HTMLDivElement>;
  expandFooter?: boolean;
  currentTrackIndex: number;
  showTrackIndex: boolean;
}

export const FooterProjectInfo = ({
  artworkSource,
  artistUser,
  engineerUser,
  artistUsername,
  engineerUsername,
  trackTitle = "",
  serviceType = ProjectType.NO_TYPE,
  onClick,
  expandFooter,
  currentTrackIndex,
  showTrackIndex,
}: StyledFooterProjectInfoProps) => {
  const { showFooterPlayer } = useAppSelector((state) => state.abPlayerStore);
  const engineerProjectRole =
    projectTypeReadableRole.get(serviceType) || "Engineer";
  const trackTitleContainerRef = useRef<HTMLDivElement>(null);
  const trackTitleRef = useRef<HTMLDivElement>(null);
  const [trackTitleAnimation, setTrackTitleAnimation] = useState<string>();

  const stopBubble: MouseEventHandler<HTMLAnchorElement> = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const artistUserHref = useMemo(() => {
    return artistUsername ? getProfileScreenRoute(artistUsername) : "#";
  }, [artistUsername]);

  const engineerUserHref = useMemo(() => {
    return engineerUsername ? getProfileScreenRoute(engineerUsername) : "#";
  }, [engineerUsername]);

  useEffect(() => {
    if (
      trackTitleContainerRef.current &&
      trackTitleRef.current &&
      showFooterPlayer
    ) {
      if (
        trackTitleContainerRef.current.offsetWidth <
        trackTitleRef.current.offsetWidth
      ) {
        const animationDuration =
          (trackTitleRef.current.offsetWidth + TRACK_TITLE_DUPLICATE_GAP) /
          TRACK_TITLE_VELOCITY;
        setTrackTitleAnimation(`scroll ${animationDuration}s linear infinite`);
      } else {
        setTrackTitleAnimation(undefined);
      }
    }
  }, [expandFooter, showFooterPlayer]);

  return (
    <StyledFooterProjectInfoContainer
      onClick={onClick}
      $expandFooter={expandFooter}
    >
      <StyledFooterProjectInfoImage
        height={expandFooter ? EXPANDED_SIZE : COLLAPSED_SIZE}
        width={expandFooter ? EXPANDED_SIZE : COLLAPSED_SIZE}
        src={artworkSource ?? missingFileArt}
      />
      <StyledFooterProjectInfoTrackInfo
        $expandFooter={expandFooter}
        ref={trackTitleContainerRef}
      >
        <Box sx={{ display: "flex", gap: "100px" }}>
          <FooterPlayerTrackTitle
            trackTitle={trackTitle}
            trackTitleAnimation={trackTitleAnimation}
            expandFooter={expandFooter}
            showTrackIndex={showTrackIndex}
            currentTrackIndex={currentTrackIndex}
            ref={trackTitleRef}
          />
          {trackTitleAnimation && (
            <FooterPlayerTrackTitle
              trackTitle={trackTitle}
              trackTitleAnimation={trackTitleAnimation}
              expandFooter={expandFooter}
              showTrackIndex={showTrackIndex}
              currentTrackIndex={currentTrackIndex}
            />
          )}
        </Box>
        <StyledFooterProjectInfoSubContainer>
          <StyledFooterProjectInfoProjectDetails>
            {!expandFooter && (
              <StyledFooterProjectInfoUserInfo variant={TextStyleVariant.P4}>
                artist
              </StyledFooterProjectInfoUserInfo>
            )}
            <Link to={artistUserHref} onClick={stopBubble}>
              <StyledFooterProjectInfoUserInfo
                $isUserInfo
                $expandFooter={expandFooter}
                variant={
                  expandFooter ? TextStyleVariant.P2 : TextStyleVariant.P4
                }
              >
                {artistUser}
              </StyledFooterProjectInfoUserInfo>
            </Link>
          </StyledFooterProjectInfoProjectDetails>
          {!expandFooter && Boolean(engineerUsername) && (
            <StyledFooterProjectInfoProjectDetails>
              <StyledFooterProjectInfoUserInfo variant={TextStyleVariant.P4}>
                {engineerProjectRole}
              </StyledFooterProjectInfoUserInfo>
              <Link to={engineerUserHref} onClick={stopBubble}>
                <StyledFooterProjectInfoUserInfo
                  $isUserInfo
                  variant={TextStyleVariant.P4}
                >
                  {engineerUser}
                </StyledFooterProjectInfoUserInfo>
              </Link>
            </StyledFooterProjectInfoProjectDetails>
          )}
        </StyledFooterProjectInfoSubContainer>
      </StyledFooterProjectInfoTrackInfo>
    </StyledFooterProjectInfoContainer>
  );
};
