import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, HTMLAttributes, LegacyRef, ReactNode, Ref } from "react";
import { Card } from "../../../elements/Card/Card";
import { DashboardCardHeader } from "../../DashboardCardHeader/DashboardCardHeader";
import "./DashboardCard.css";
import classNames from "classnames";
import { ButtonVariant } from "../../../core-ui/components/Button/Button";
import { DashboardCardActionButton } from "./DashboardCard.styles";
import { faChevronCircleRight } from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@mui/material";

export interface DashboardCardProps {
  cardHeight?: string | number;
  children: ReactNode;
  contentOnly?: boolean;
  handleHeaderClick?: () => void;
  headerIcon?: IconDefinition;
  headerText?: string;
  noHeaderIcon?: boolean;
  flex?: boolean;
  className?: string;
  contentRef?: LegacyRef<HTMLDivElement>;
  headerRef?: Ref<HTMLDivElement>;
  extraActionButtons?: ReactNode;
  actionButtonWrapperProps?: Partial<HTMLAttributes<HTMLDivElement>>;
  id?: string;
}

export const DashboardCard: FC<DashboardCardProps> = ({
  cardHeight,
  children,
  contentOnly = false,
  handleHeaderClick,
  headerIcon = faChevronCircleRight,
  headerText,
  noHeaderIcon = false,
  flex = false,
  className = "",
  contentRef,
  headerRef,
  extraActionButtons,
  actionButtonWrapperProps = {},
  id,
}: DashboardCardProps) => {
  const content = (
    <Box ref={headerRef} width={"100%"}>
      <DashboardCardHeader
        title={headerText}
        actionButton={
          !noHeaderIcon && (
            <>
              {extraActionButtons}
              <DashboardCardActionButton
                variant={ButtonVariant.GHOST}
                onClick={handleHeaderClick}
                labelIcon={<FontAwesomeIcon size={"lg"} icon={headerIcon} />}
              />
            </>
          )
        }
        actionButtonWrapperProps={actionButtonWrapperProps}
      />
      <div
        className={classNames({
          "dashboard-card-content": true,
          "dashboard-flex": flex,
          [className]: Boolean(className),
        })}
      >
        {children}
      </div>
    </Box>
  );

  return contentOnly ? (
    <div
      className={classNames({
        "dashboard-card-content-only": true,
        "dashboard-flex": flex,
        className,
      })}
      ref={contentRef}
      id={id}
    >
      {content}
    </div>
  ) : (
    <Card
      hideOverflow={true}
      height={cardHeight}
      customClassName={classNames(["dashboard-card", className])}
      id={id}
    >
      {content}
    </Card>
  );
};
