import { Box, useTheme } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import Link from "../../elements/Link/Link";
import { getDocUrl } from "../../../constants/googleStorage";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { consentAtom } from "../../../atoms/consentAtom";
import { useSetAtom } from "jotai";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

export const CookieBanner = () => {
  const setHasConsent = useSetAtom(consentAtom);
  const theme = useTheme();
  const { isMobile } = useMediaQueryBreakpoint();
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Text variant={TextStyleVariant.H5}>We value your privacy</Text>
      <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Text color={TextColor.SECONDARY}>
            {
              "This website uses cookies in order to offer you the most relevant information. By clicking accept, you are agreeing to the "
            }
            <Link
              style={{
                color: theme.palette.text.secondary,
                textDecoration: "underline",
              }}
              to={getDocUrl("EngineEarsTermsOfUse.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </Link>
            {" and "}
            <Link
              style={{
                color: theme.palette.text.secondary,
                textDecoration: "underline",
              }}
              to={getDocUrl("EngineEarsPrivacyPolicy.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
            .
          </Text>
        </Box>
        <Box
          display={"flex"}
          gap={2}
          flexDirection={"row"}
          justifyContent={"flex-end"}
        >
          <Button
            fullWidth={isMobile}
            className="consent-reject-button"
            variant={ButtonVariant.BLACK_OUTLINED_BUTTON_BLACK_HOVER}
            analyticsEvent={{ name: "consent_reject" }}
            onClick={() => setHasConsent(false)}
            style={{ minWidth: "140px", maxHeight: "44px" }}
          >
            Reject
          </Button>
          <Button
            fullWidth={isMobile}
            className="studio-screen-book-now-button"
            analyticsEvent={{ name: "consent_agree" }}
            onClick={() => setHasConsent(true)}
            style={{ minWidth: "140px", maxHeight: "44px" }}
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
