import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../helpers";
import { updateRelease, UpdateReleaseArgs } from "../releaseAPI";

export const useUpdateRelease = (releaseId = 0, scheduledProjectId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: (release) => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_ARTIST_RELEASE],
      });
      void queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.FETCH_FEATURED_ARTIST_RELEASE,
          release.artist_user_id,
        ],
      });
      if (scheduledProjectId) {
        void queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.FETCH_SCHEDULED_PROJECT_RELEASE,
            scheduledProjectId,
          ],
        });
      }
    },
    onError: (error) => {
      if (assertEngineEarsErrorType(error)) {
        displayEngineEarsError(error);
      }
      if (scheduledProjectId) {
        void queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.FETCH_SCHEDULED_PROJECT_RELEASE,
            scheduledProjectId,
          ],
        });
      }
    },
    mutationKey: [QUERY_KEYS.UPDATE_RELEASE, releaseId],
    mutationFn: async (args: UpdateReleaseArgs) => {
      const response = await updateRelease(releaseId, args);
      if (!response.success) {
        throw response.resultJson;
      }
      return response.resultJson;
    },
  });
};
