import { useMemo } from "react";
import {
  isRecordingService,
  RecordingService,
} from "../../store/models/recording";
import Service from "../../store/models/service";
import { useGetAffiliatedStudiosForUser } from "./useGetAffiliatedStudiosForUser";

export const useEngineerHasPublicRecordingService = (
  engineerServicesData: (Service | RecordingService)[] | undefined,
  userId?: number,
) => {
  const { data: affiliatedStudios } = useGetAffiliatedStudiosForUser({
    userId: userId!,
  });

  return useMemo(() => {
    const recordingService = engineerServicesData?.find(isRecordingService);
    if (!recordingService) {
      return false;
    }
    if (
      !recordingService.will_come_to_you &&
      (!affiliatedStudios || affiliatedStudios.length === 0)
    ) {
      return false;
    }
    return true;
  }, [engineerServicesData, affiliatedStudios]);
};
