import { Box, styled } from "@mui/material";

export const HighlightedTextContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.standardColor.BoxyYellow[100],
  padding: "12px",
  borderRadius: theme.border.radius.md,
  boxSizing: "border-box",
}));

export const TravelToArtistRateContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "12px 8px",
  borderRadius: theme.border.radius.md,
  backgroundColor: theme.palette.standardColor.DeepBlue[50],
}));
