import { faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import { Anchor } from "@radix-ui/react-popover";
import { useAtom } from "jotai";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { localServiceCoverPhotoAtom } from "../../../../atoms/profileScreenEdit";
import { useOnUploadServicePhoto } from "../../../../hooks/profileScreenHooks/useOnUploadServicePhoto";
import { useOnUploadServiceVideo } from "../../../../hooks/profileScreenHooks/useOnUploadServiceVideo";
import useModal from "../../../../hooks/useModal";
import { createRecordingServiceParams } from "../../../../store/actions/recording";
import { RecordingService } from "../../../../store/models/recording";
import Service, { ServiceFormProps } from "../../../../store/models/service";
import { FileUploaderContainer } from "../../../components/FileUploader/FileUploader.styles";
import { UploadedFileListContainer } from "../../../components/FileUploader/UploadedFileList/UploadedFileList.styles";
import { UploaderContainer } from "../../../components/Uploader/Uploader.styles";
import { BasePopover } from "../../../core-ui/components/BasePopover/BasePopover";
import {
  Text,
  TEXT_COLOR,
  TEXT_WEIGHT,
} from "../../../core-ui/components/Text/Text";
import { TextColor } from "../../../core-ui/components/Text/TextUtils";
import {
  IDLE_PROGRESS,
  MediaTypes,
  mediaUploadAcceptedFileTypes,
  PHOTO_FILE_TYPE_PREFIX,
  UPLOADED_PROGRESS,
  VIDEO_FILE_TYPE_PREFIX,
} from "../constants";
import { FileListItem } from "./FileListItem";

interface ServiceMediaTabProps {
  serviceForm: ServiceFormProps | createRecordingServiceParams;
  existingService?: RecordingService | Service;
  engineerId: number;
  setServiceForm: Dispatch<
    SetStateAction<createRecordingServiceParams | ServiceFormProps>
  >;
}

export const ServiceMediaTab = ({
  serviceForm,
  existingService,
  engineerId,
  setServiceForm,
}: ServiceMediaTabProps) => {
  const theme = useTheme();

  const {
    uploadServicePhoto,
    progress: photoUploadingProgress,
    setUploadingPhoto,
    uploadingPhoto,
    cancelRequest: cancelPhotoUpload,
  } = useOnUploadServicePhoto({ service: existingService, engineerId });
  const {
    uploadingVideo,
    progress: videoUploadingProgress,
    setUploadingVideo,
    uploadServiceVideo,
    cancelRequest: cancelVideoUpload,
  } = useOnUploadServiceVideo();
  const {
    setIsOpen: setIsPopConfirmOpen,
    isOpen: isPopConfirmOpen,
    closeModal: closePopConfirm,
  } = useModal();
  const [localServiceCoverPhoto, setLocalServiceCoverPhoto] = useAtom(
    localServiceCoverPhotoAtom,
  );

  const uploaderDisabled =
    (photoUploadingProgress !== IDLE_PROGRESS &&
      photoUploadingProgress < 100) ||
    (videoUploadingProgress !== IDLE_PROGRESS && videoUploadingProgress < 100);

  const handleOnDrop = useCallback(
    (acceptedFiles: File[]) => {
      const selectedFile = acceptedFiles[0];
      if (!selectedFile) {
        toast.error("File not supported!");
        return;
      }

      if (selectedFile.type.includes(PHOTO_FILE_TYPE_PREFIX)) {
        setUploadingPhoto(selectedFile);
      } else if (selectedFile.type.includes(VIDEO_FILE_TYPE_PREFIX)) {
        setUploadingVideo(selectedFile);
      }
      setIsPopConfirmOpen(true);
    },
    [setIsPopConfirmOpen, setUploadingPhoto, setUploadingVideo],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleOnDrop,
    accept: acceptedFilesTypes,
    disabled: uploaderDisabled,
  });

  const filesList = useMemo(() => {
    const accumulatedList = {} as Record<MediaTypes, number>;

    if (
      (!existingService && localServiceCoverPhoto) ||
      serviceForm.cover_photo_id
    ) {
      accumulatedList[MediaTypes.CoverPhoto] = UPLOADED_PROGRESS;
    }

    if (serviceForm?.featured_video_id) {
      accumulatedList[MediaTypes.FeaturedVideo] = UPLOADED_PROGRESS;
    }

    if (photoUploadingProgress !== IDLE_PROGRESS) {
      accumulatedList[MediaTypes.CoverPhoto] = photoUploadingProgress;
    }

    if (videoUploadingProgress !== IDLE_PROGRESS) {
      accumulatedList[MediaTypes.FeaturedVideo] = videoUploadingProgress;
    }

    return accumulatedList;
  }, [
    existingService,
    localServiceCoverPhoto,
    photoUploadingProgress,
    serviceForm.cover_photo_id,
    serviceForm?.featured_video_id,
    videoUploadingProgress,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "24px" }}>
      <Text>Update your service card&apos;s photo or video</Text>

      <FileUploaderContainer>
        <BasePopover
          side={"top"}
          isOpen={isPopConfirmOpen}
          setIsPopoverOpen={setIsPopConfirmOpen}
          closePopover={closePopConfirm}
          title={"Are you sure"}
          description={`You're about to upload a Media File. Click Confirm to continue.`}
          onConfirm={async () => {
            if (uploadingPhoto) {
              if (!existingService) {
                setLocalServiceCoverPhoto(uploadingPhoto);
                setUploadingPhoto(undefined);
                return;
              }
              const entityPhotoId = await uploadServicePhoto(uploadingPhoto);
              setUploadingPhoto(undefined);
              setServiceForm((currentForm) => ({
                ...currentForm,
                cover_photo_id: entityPhotoId,
              }));
            } else if (uploadingVideo) {
              const entityVideoId = await uploadServiceVideo(uploadingVideo);
              setUploadingVideo(undefined);
              setServiceForm((currentForm) => ({
                ...currentForm,
                featured_video_id: entityVideoId,
              }));
            }
          }}
          onCancel={() => {
            setUploadingPhoto(undefined);
            setUploadingVideo(undefined);
          }}
        >
          <Anchor>
            <UploaderContainer
              style={{ gap: "16px" }}
              {...getRootProps()}
              $disabled={uploaderDisabled}
            >
              <input {...getInputProps()} disabled={uploaderDisabled} />
              <Text
                color={uploaderDisabled ? TEXT_COLOR.TERTIARY : TextColor.BLACK}
                weight={TEXT_WEIGHT.BOLD}
              >
                Drag & drop an image or video here, or click to browse
              </Text>
              <FontAwesomeIcon
                color={theme.palette.standardColor.Gray[200]}
                icon={faCloudArrowUp}
                size="2x"
              />
              <Text
                color={uploaderDisabled ? TEXT_COLOR.TERTIARY : TextColor.BLACK}
              >
                Accepted file types: {acceptedFilesTypes.join(", ")}
              </Text>
            </UploaderContainer>
          </Anchor>
        </BasePopover>
      </FileUploaderContainer>
      <UploadedFileListContainer>
        {Object.entries(filesList).map(([key, progress]) => (
          <FileListItem
            key={key}
            progress={progress}
            onDelete={() => {
              if (key === MediaTypes.CoverPhoto) {
                cancelPhotoUpload();
              } else if (key === MediaTypes.FeaturedVideo) {
                cancelVideoUpload();
              }
            }}
            mediaType={key as MediaTypes}
          />
        ))}
      </UploadedFileListContainer>
    </Box>
  );
};

const acceptedFilesTypes = [
  ...mediaUploadAcceptedFileTypes[MediaTypes.CoverPhoto],
  ...mediaUploadAcceptedFileTypes[MediaTypes.FeaturedVideo],
];
