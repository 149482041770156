import { useMemo } from "react";
import { DEFAULT_SERVICE_COVER_IMAGE_SRC } from "../../../../constants/googleStorage";
import { useGetServiceOriginalRate } from "../../../../hooks/profileScreenHooks/useGetServiceOriginalRate";
import { useAppSelector } from "../../../../store/hooks";
import {
  ProjectType,
  projectTypeReadableName,
} from "../../../../store/models/project";
import { PromoCode } from "../../../../store/models/promoCode";
import { RecordingService } from "../../../../store/models/recording";
import Service from "../../../../store/models/service";
import { getApplicableDiscountBookingLinkData } from "../../../../store/selectors/bookingSelectors";
import { BucketName, getImageProps } from "../../../../store/utils";
import { DollarFormatter } from "../../../../store/utils/formatUtils";
import {
  getIncludedIncentiveByServiceType,
  getServiceDescriptionFromServiceType,
  getServiceFromServiceType,
} from "../../../../store/utils/serviceUtils";
import { identifyAuthenticatedUser } from "../../../../utils/analyticsUtils";
import { ServiceCardV2 } from "./ServiceCardV2";

// eslint-disable-next-line import/no-unused-modules
export interface ServiceCardV2WrapperProps {
  service: RecordingService | Service;
  userOnOwnProfile: boolean;
  engineerUsername?: string;
  editMode: boolean;
  handleClick?: (
    service: Service | RecordingService,
    shouldEdit?: boolean,
  ) => void;
  promoCode?: PromoCode;
  disabled: boolean;
  isLoading: boolean;
}

export const ServiceCardV2Wrapper = ({
  service,
  userOnOwnProfile,
  engineerUsername,
  handleClick,
  editMode = false,
  promoCode,
  disabled,
  isLoading,
}: ServiceCardV2WrapperProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);

  const loggedInUserIsNotServiceEngineer = useMemo(() => {
    return user?.username !== engineerUsername;
  }, [user?.username, engineerUsername]);

  const serviceName = useMemo(() => {
    return getServiceFromServiceType(service.service_type, true);
  }, [service.service_type]);

  const serviceDescription = useMemo(() => {
    return getServiceDescriptionFromServiceType(service.service_type);
  }, [service.service_type]);

  const includedServiceText = getIncludedIncentiveByServiceType(service);

  const discountedBookingLinkData = useAppSelector(
    getApplicableDiscountBookingLinkData(service.id),
  );

  const onClickCTA = () => {
    if (userOnOwnProfile) {
      if (handleClick) handleClick(service);
      return;
    }

    if (handleClick === undefined) {
      return;
    }

    if (user) {
      identifyAuthenticatedUser(user.id, {
        last_selected_engineer_username: engineerUsername,
        last_selected_package_type: projectTypeReadableName
          .get(service.service_type)
          ?.toLowerCase(),
      });
    }

    handleClick(service);
  };

  const { originalRate } = useGetServiceOriginalRate(service, userOnOwnProfile);

  const discountedRate = useMemo(() => {
    if (discountedBookingLinkData?.discounted_price) {
      return DollarFormatter().format(
        discountedBookingLinkData.discounted_price,
      );
    }
    let servicePrice = service?.service_rate?.price ?? 0;
    if (
      service.service_type === ProjectType.RECORDING &&
      loggedInUserIsNotServiceEngineer
    ) {
      const travelToArtistPrice = Number(
        service?.service_rate?.travel_to_artist_price ?? 0,
      );
      if (travelToArtistPrice > servicePrice) {
        servicePrice = travelToArtistPrice;
      }
    }

    if (!servicePrice || !promoCode?.discount_percentage) return undefined;
    let discount = 0;
    if (promoCode.discount_percentage) {
      discount = servicePrice * promoCode.discount_percentage;
    }
    return DollarFormatter().format(servicePrice - discount);
  }, [
    discountedBookingLinkData?.discounted_price,
    loggedInUserIsNotServiceEngineer,
    promoCode?.discount_percentage,
    service?.service_rate?.price,
    service?.service_rate?.travel_to_artist_price,
    service.service_type,
  ]);

  return (
    <ServiceCardV2
      editMode={editMode}
      serviceName={serviceName}
      serviceDescription={serviceDescription}
      userOnOwnProfile={userOnOwnProfile}
      onClick={onClickCTA}
      discountedRate={discountedRate}
      originalRate={DollarFormatter().format(originalRate)}
      includedInServiceText={includedServiceText}
      disabled={disabled}
      imageProps={getImageProps(
        BucketName.SERVICE_COVER_PHOTOS,
        service.cover_photo?.path,
        DEFAULT_SERVICE_COVER_IMAGE_SRC,
      )}
      featuredVideo={service.featured_video}
      isLoading={isLoading}
      service={service}
    />
  );
};
