import { useMutation } from "@tanstack/react-query";
import queryString from "query-string";
import { UploadType } from "../../../store/models/fileVersion";
import { downloadFilesFromUrlsWithDelay } from "../../../store/utils/downloadFilesFromUrls";
import { downloadReleaseTracks } from "../releaseAPI";

export const useDownloadReleaseTrackMutation = () => {
  return useMutation({
    mutationFn: async ({
      releaseId,
      uploadType,
      trackId,
    }: {
      releaseId: number;
      uploadType: UploadType;
      trackId?: number;
    }) => {
      const params = queryString.stringify(
        {
          upload_type: uploadType,
          release_track_id: trackId,
        },
        { skipEmptyString: true, skipNull: true },
      );
      const response = await downloadReleaseTracks(releaseId, `?${params}`);
      if (!response.success) {
        throw response;
      }
      const urls = response.resultJson.download_urls;
      downloadFilesFromUrlsWithDelay(urls);
      return response;
    },
  });
};
