import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useMarkProductTransactionAsPaidMutation } from "../../../api/productTransactions/useMarkProductTransactionAsPaidMutation";
import useModal from "../../../hooks/useModal";
import { clearBookingState } from "../../../store/actions/booking";
import {
  clearTransactionsState,
  markTransactionPaid,
} from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  FinancialMethod,
  PaymentRedirectType,
} from "../../../store/models/transaction";
import {
  getMyBookingsRoute,
  getMyLibraryRoute,
  getProjectOverviewRoute,
  getTransactionBookingScreenRoute,
} from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { AppScreenContent } from "../../components/AppScreenContent/AppScreenContent";
import { FeedbackModal } from "../../components/FeedbackModal/FeedbackModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { DefaultSoundWaveLoader } from "../../elements/DefaultSoundWaveLoader/DefaultSoundWaveLoader";
import { ButtonContainer, Container } from "./StripePaymentRedirectPage.styles";

interface StripePaymentRedirectPageProps {
  paymentRedirectType: PaymentRedirectType;
}

export const StripePaymentRedirectPage = ({
  paymentRedirectType,
}: StripePaymentRedirectPageProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isOpen, openModal, closeModal } = useModal();
  const [
    transactionProcessedSuccessfully,
    setTransactionProcessedSuccessfully,
  ] = useState<boolean | undefined>(undefined);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const scheduledProjectIdForHandoff = query.get("scheduled_project_id");
  const transactionId = query.get("transaction_id");
  const transactionCode = query.get("transaction_code");
  const projectTitle = query.get("project_title");
  const paymentIntentClientSecret = query.get("payment_intent_client_secret");
  const redirectStatus = query.get("redirect_status");
  const purchaseType = query.get("purchase_type") as
    | "release"
    | "projects"
    | "sessions";

  const { mutateAsync: markProductTransactionAsPaid } =
    useMarkProductTransactionAsPaidMutation();

  const financialMethod = useMemo(() => {
    if (paymentRedirectType === PaymentRedirectType.CASH_APP) {
      return FinancialMethod.CASH_APP;
    }

    if (paymentRedirectType === PaymentRedirectType.KLARNA) {
      return FinancialMethod.KLARNA;
    }

    if (paymentRedirectType === PaymentRedirectType.AFFIRM) {
      return FinancialMethod.AFFIRM;
    }

    return undefined;
  }, [paymentRedirectType]);

  const paymentTypeString = useMemo(() => {
    if (paymentRedirectType === PaymentRedirectType.CASH_APP) {
      return "Cash App";
    }

    if (paymentRedirectType === PaymentRedirectType.KLARNA) {
      return "Klarna";
    }

    if (paymentRedirectType === PaymentRedirectType.AFFIRM) {
      return "Affirm";
    }

    return "";
  }, [paymentRedirectType]);

  useEffect(() => {
    if (!transactionId) {
      toast.error("No transaction ID found, Please contact support.");
      return;
    }

    if (!paymentIntentClientSecret) {
      toast.error("Invalid payment redirect URL. Please contact support.");
      return;
    }

    // should never happen, but guard against it
    if (!financialMethod) {
      return;
    }

    if (redirectStatus === "failed") {
      setTransactionProcessedSuccessfully(false);
      return;
    }

    if (purchaseType === "release") {
      if (!transactionCode) {
        toast.error("No transaction code found, Please contact support.");
        return;
      }
      markProductTransactionAsPaid({ transactionCode })
        .then((transaction) => {
          emitAnalyticsTrackingEvent(
            "mark_paid_release",
            {
              transaction_id: `${transaction.id}`,
            },
            user?.id,
          );
          setTransactionProcessedSuccessfully(true);
          setTimeout(() => {
            history.push(getMyLibraryRoute());
          }, 2000);
        })
        .catch((e) => {
          console.error(e);
          setTransactionProcessedSuccessfully(false);
        });
      return;
    }
    dispatch(
      markTransactionPaid({
        title: projectTitle ?? "Untitled",
        transaction_id: +transactionId,
        booked_with_purchase_order: false,
        financial_method: financialMethod,
        payment_intent_client_secret: paymentIntentClientSecret,
      }),
    )
      .unwrap()
      .then((result) => {
        emitAnalyticsTrackingEvent(
          "mark_paid_scheduled_project",
          {
            transaction_id: `${transactionId}`,
            value: result?.total_price ?? 0,
          },
          user?.id,
        );
        setTransactionProcessedSuccessfully(true);
        dispatch(clearBookingState());
        dispatch(clearTransactionsState());

        // Redirect to project page after successful payment processing.
        setTimeout(() => {
          if (scheduledProjectIdForHandoff) {
            history.push(
              getProjectOverviewRoute(+scheduledProjectIdForHandoff),
            );
          } else {
            history.push(getMyBookingsRoute("projects"));
          }
        }, 2000);
      })
      .catch(() => {
        setTransactionProcessedSuccessfully(false);
      });
  }, []);

  if (transactionProcessedSuccessfully === undefined) {
    return (
      <AppScreenContent>
        <Container>
          <DefaultSoundWaveLoader />
          <p className="b2-semi-bold text-center">
            Processing payment... Please do not exit or refresh the page.
          </p>
        </Container>
      </AppScreenContent>
    );
  }

  if (!transactionProcessedSuccessfully) {
    return (
      <AppScreenContent>
        <Container>
          <h3>Payment unsuccessful</h3>
          <p className="b2-semi-bold mt-2">
            {`Payment via ${paymentTypeString} failed, please try again or contact support.`}
          </p>
          <ButtonContainer>
            <Button onClick={openModal} variant={ButtonVariant.OUTLINED}>
              Contact Support
            </Button>
            {transactionCode && (
              <Button
                onClick={() =>
                  history.replace(
                    getTransactionBookingScreenRoute(transactionCode),
                  )
                }
                variant={ButtonVariant.PRIMARY}
              >
                Try Again
              </Button>
            )}
          </ButtonContainer>
          {isOpen && <FeedbackModal onClose={closeModal} />}
        </Container>
      </AppScreenContent>
    );
  }
  return (
    <AppScreenContent className="centered-screen">
      <Container>
        <p className="b2-semi-bold">Payment processed successfully!</p>
      </Container>
    </AppScreenContent>
  );
};
