import { ShoppingCartItem } from "../../../api/productTransactions/models";
import { useProductCart } from "../../../atoms/products/hooks/useProductCart";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";

interface ProductCheckoutButtonProps {
  disabled?: boolean;
  postLogin?: (_: ShoppingCartItem[]) => void;
}

export const ProductCheckoutButton = ({
  disabled,
}: ProductCheckoutButtonProps) => {
  const { cart, handleCheckout, isCheckingout } = useProductCart();
  return (
    <>
      <Button
        disabled={disabled}
        loading={isCheckingout}
        onClick={async () => {
          void handleCheckout(cart);
        }}
        variant={ButtonVariant.PRIMARY}
        analyticsEvent={{
          name: TRACKING_EVENTS_NAME.USER_CLICK_PRODUCT_CHECKOUT,
          data: {
            cart,
          },
        }}
      >
        Checkout
      </Button>
    </>
  );
};
