import { toggleInProgressProject } from "../../../store/actions/mixMasterCartsStore";
import { setPaywallOption } from "../../../store/actions/generateBookingStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  ScheduledProjectPaywallOptions,
  ScheduledProjectPaywallTypes,
} from "../../../store/models/project";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { DropdownSelectorContainer } from "./InProgressProjectSelector.styles";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";

export const InProgressProjectSelector = () => {
  const dispatch = useAppDispatch();
  const { inProgressProject } = useAppSelector(
    (state) => state.mixMasterCartsStore.cart,
  );
  const { paywallOption } = useAppSelector(
    (state) => state.generateBookingStore,
  );

  const selectPaywallOption = (option: OptionType) => {
    emitAnalyticsTrackingEvent(TRACKING_EVENTS_NAME.USER_CLICK_PAYWALL_OPTION, {
      paywallOption: option.value,
    });
    if (
      ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED ===
        option.value &&
      inProgressProject
    ) {
      dispatch(toggleInProgressProject());
    } else if (
      ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED !==
        option.value &&
      !inProgressProject
    ) {
      dispatch(toggleInProgressProject());
    }
    dispatch(setPaywallOption(option.value));
  };

  return (
    <DropdownSelectorContainer>
      <DropdownSelector
        value={ScheduledProjectPaywallOptions.find(
          (option) => option.value === paywallOption,
        )}
        options={ScheduledProjectPaywallOptions}
        onChange={selectPaywallOption}
        placeholder="Select paywall option"
      />
    </DropdownSelectorContainer>
  );
};
