import User, { MockUser, UserLite } from "./user";
import { MasteringProjectSteps, MixingProjectSteps, Project } from "./project";
import { RecordingSessionStep } from "./recordingSession";

export enum NotificationType {
  NO_NOTIFICATION_TYPE,
  MESSAGE,
  PROJECT,
  REMINDER,
  FEATURE_TRACK,
  DIRECT_MESSAGE_REQUEST,
  RECORDING_SESSION,
  STUDIO_AFFILIATION_INVITE,
  NUDGE,
  PROFILE_VIEW = 9,
  PURCHASE_CONFIRMATION = 10,
}

export default interface Notification {
  id: number;
  created: string;
  read?: string | null;
  type?: NotificationType;
  generating_user?: User;
  text?: string | null;
  link?: string | null;
}

export interface Nudge {
  id: number;
  created: string;
  project: Project;
  mixing_step: MixingProjectSteps;
  mastering_step: MasteringProjectSteps;
  recording_step: RecordingSessionStep;
  sender: UserLite;
}

export const MockNotification: Notification = {
  id: 10,
  created: "2021-11-29T17:34:55.929545",
  read: null,
  type: NotificationType.MESSAGE,
  generating_user: MockUser,
  text: "New message from mixedbyali: Hey I think there's an issue...",
  link: "/messages",
};

export const MockFeatureTrackRequestNotification: Notification = {
  id: 10,
  created: "2021-11-29T17:34:55.929545",
  read: null,
  type: NotificationType.FEATURE_TRACK,
  generating_user: MockUser,
  text: "Please review: Derek Ali has requested to use your song on his profile.",
  link: "/featured-track/${featured_track}",
};

export const MockNotifications: Notification[] = [
  MockNotification,
  MockNotification,
  MockNotification,
  MockNotification,
];
