import classNames from "classnames";
import { HTMLAttributes, useMemo } from "react";
import { useGetEngineerServices } from "../../../hooks/useGetEngineerServices";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useAppSelector } from "../../../store/hooks";
import {
  getAddSubscriptionRoute,
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { UniqueBreakpoints } from "../../../utils/breakpoints";
import { GenerateBookingButton } from "../../components/GenerateBookingButton/GenerateBookingButton";
import { UserCard } from "../../components/UserCard/UserCard";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { AddSubscriptionContainer } from "./ModularDashboard.styles";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Box, useTheme } from "@mui/material";
import Link from "../../elements/Link/Link";
import { useGetSubscriptionStatus } from "../../../hooks/useGetSubscriptionStatus";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import { useEngineerHasPublicRecordingService } from "../../../hooks/profileScreenHooks/engineerGenerateBookingHooks";
import {
  GenerateBookingServiceGroups,
  ServiceTypePopConfirm,
} from "../ProfileScreen/components/ServiceTypePopConfirm/ServiceTypePopConfirm";
import { useHandleCreateTransaction } from "../../../hooks/useHandleCreateTransaction";
import { ProjectType } from "../../../store/models/project";
import { useSetAtom } from "jotai";
import { displayGenerateBookingInfoAtom } from "../../../atoms/modals/generatedBookingInfoModal";

type ModularDashboardHeaderProps = HTMLAttributes<HTMLDivElement>;

const ModularDashboardHeader = ({
  className,
  ...props
}: ModularDashboardHeaderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(UniqueBreakpoints.DashboardMobile);
  const { user: userData, localUTMParams } = useAppSelector(
    (state) => state.accountInfo,
  );
  const { data: services, isFetching: isLoadingEngineerServices } =
    useGetEngineerServices(userData?.engineer?.id);

  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const setIsOpenGenerateBookingInfo = useSetAtom(
    displayGenerateBookingInfoAtom,
  );
  const engineerHasPublicRecordingService =
    useEngineerHasPublicRecordingService(services, userData?.id);

  const { isCreatingTransaction, createTransactionAndNavigate } =
    useHandleCreateTransaction(localUTMParams, userData, userData?.engineer);

  const engineerHasOnlyRecordingService =
    services?.length === 1 &&
    services.some((service) => service.service_type === ProjectType.RECORDING);
  const engineerHasOnlyMixMasterService =
    services &&
    services.length >= 1 &&
    services.every((service) => service.service_type !== ProjectType.RECORDING);
  const isStudio = useMemo(
    () => selectedProfile.studio !== undefined,
    [selectedProfile.studio],
  );
  const showGenerateBookingButton = useMemo(
    () => services?.length !== 0 || isStudio,
    [isStudio, services],
  );
  const { data: subscription } = useGetSubscriptionStatus({
    user: userData,
    refetchSubsStatus: true,
    force: true,
  });
  const showUpgradeButton = useMemo(() => {
    return (
      subscription?.stripe_subscription_status &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.ESSENTIALS_PLAN &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY
    );
  }, [subscription]);

  if (!userData) {
    return null;
  }

  const handleGenerateBookingClick = (
    serviceGenerated?: GenerateBookingServiceGroups,
  ) => {
    if (!services || services?.length === 0) {
      return;
    }
    if (
      (engineerHasPublicRecordingService && services.length == 1) ||
      serviceGenerated === GenerateBookingServiceGroups.RECORDING
    ) {
      void createTransactionAndNavigate(true);

      return;
    }
    setIsOpenGenerateBookingInfo(true);
  };

  const generateBookingButton = () => {
    if (isMobile) {
      return null;
    }
    if (
      !isStudio &&
      engineerHasPublicRecordingService &&
      services &&
      services.length > 1
    ) {
      return (
        <ServiceTypePopConfirm
          className="dashboard-start-a-project-button"
          variant={ButtonVariant.PRIMARY}
          loading={isCreatingTransaction || isLoadingEngineerServices}
          onClick={handleGenerateBookingClick}
        />
      );
    }

    if (
      (!isStudio &&
        engineerHasOnlyRecordingService &&
        engineerHasPublicRecordingService) ||
      engineerHasOnlyMixMasterService
    ) {
      return (
        <Button
          className="dashboard-start-a-project-button"
          variant={ButtonVariant.PRIMARY}
          loading={isCreatingTransaction || isLoadingEngineerServices}
          onClick={() => handleGenerateBookingClick()}
        >
          Generate Booking
        </Button>
      );
    }

    if (engineerHasOnlyRecordingService && !engineerHasPublicRecordingService) {
      return null;
    }

    if (showGenerateBookingButton) {
      return (
        <GenerateBookingButton
          className="dashboard-start-a-project-button"
          disabled={services?.length === 0 && !isStudio}
          isLoadingEngineerServices={Boolean(isLoadingEngineerServices)}
          isMobile={isMobile}
          activeStudio={selectedProfile.studio}
        />
      );
    }
    return null;
  };

  return (
    <div className={classNames(["dashboard-header", className])} {...props}>
      <UserCard loggedInUser={userData} selectedProfile={selectedProfile} />
      {showUpgradeButton && (
        <AddSubscriptionContainer>
          <Text
            variant={TextStyleVariant.P1}
            weight={TEXT_WEIGHT.BOLD}
            style={{ color: theme.palette.standardColor.DeepBlue[800] }}
          >
            Upgrade to{" "}
            <Link
              to={getAddSubscriptionRoute()}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <Text
                variant={TextStyleVariant.P1}
                weight={TEXT_WEIGHT.BOLD}
                style={{
                  display: "inline",
                  color: theme.palette.standardColor.DeepBlue[800],
                  textDecoration: "underline",
                }}
              >
                Platinum!
              </Text>
            </Link>
            <Link
              to={getAddSubscriptionRoute()}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <Text
                variant={TextStyleVariant.P2}
                style={{
                  display: "inline",
                  color: theme.palette.standardColor.DeepBlue[800],
                  textDecoration: "underline",
                }}
              >
                learn more
              </Text>
            </Link>
          </Text>
        </AddSubscriptionContainer>
      )}
      <Box sx={{ display: "flex", marginLeft: "auto", gap: "10px" }}>
        <Button
          className="dashboard-view-profile-button"
          href={
            selectedProfile.studio
              ? getStudioScreenRoute(selectedProfile.studio.username)
              : getProfileScreenRoute(selectedProfile.user?.username || "")
          }
          onClick={() => {
            emitAnalyticsTrackingEvent(
              "dashboard_on_click_view_profile",
              {},
              userData?.id,
            );
          }}
          variant={ButtonVariant.OUTLINED}
        >
          View Profile
        </Button>
        {generateBookingButton()}
      </Box>
    </div>
  );
};

export default ModularDashboardHeader;
